import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Space, Input } from "antd";
import "../../../../../../styles/Main.scss";
import "antd/dist/antd.css";
import ButtonField from "../../../../../../common/htmlComponents/ButtonField";
import EditableFormulationType from "./EditableFormulationType";
import { getRequest } from "../../../../../../axios";
import {
  addformulation,
  resetAfterUpdate,
  updateFormulation,
} from "./AddFormulationSlice";
import { SuccessNotificationMsg } from "../../../../../../utils/Notifications";
import { useUnsavedChanges } from "../../../../../UnsavedChangesProvider";
const AddFormulation = ({
  facility_id,
  setNewFormulationAdded,
  newFormulationAdded,

  setCurrentTab,
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const dispatch = useDispatch();
  const { setUnsavedChanges } = useUnsavedChanges();
  const formulationById = useSelector(
    (state) => state.addFormulation.formulation
  );
  const formulation = formulationById && formulationById.results;
  const formulationId = useSelector(
    (state) => state.addFormulation.formulation_id
  );

  const initialState = {
    formulation_id: null,
    formulation_type: [],
    monthly_capacity: null,
  };
  const [state, setState] = useState(initialState);
  const [btnLoading, setBtnloading] = useState(false);
  const [formulationTypeList, setFormulationTypeList] = useState([]);
  const [deleted, setDeleted] = useState(false);

  const getFormulationType = async () => {
    await getRequest("formulation_type/create/").then((res) => {
      setFormulationTypeList(res.data);
    });
  };

  useEffect(() => {
    getFormulationType();

    if (formulationId) {
      formRef.current.setFieldsValue({
        formulation_type: formulation[0].formulation_type_name,
        monthly_capacity: formulation[0].monthly_capacity,
      });
      setState({
        ...state,
        formulation_id: formulationId,
        formulation_type: formulation[0].formulation_type,
        monthly_capacity: formulation[0].monthly_capacity,
      });
    }
  }, [formulation, formulationId, formRef, deleted]);

  const handleFormChange = (changedValues, allValues) => {
    // Check if the changed values include the fields you want to track
    // console.log(allValues);
    if (
      "formulation_type" in changedValues ||
      "monthly_capacity" in changedValues
    ) {
      // setChild1(true);
      // Assuming currentTab state has child1 and child2 properties
      setCurrentTab((prevTab) => ({
        ...prevTab,
        child1: true,
      }));
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        outerClickChild1: true,
        // outerClickChild2: false,
      }));
    }
  };

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: Number(value) });
  };

  const handleSubmit = async () => {
    try {
      setBtnloading(true);
      if (state.formulation_id === null) {
        await addformulation({
          id: facility_id,
          formulation_type: state.formulation_type,
          monthly_capacity: state.monthly_capacity,
        });
        setState({
          ...state,
          formulation_id: null,
          formulation_type: [],
          monthly_capacity: null,
        });
        formRef.current.setFieldsValue(initialState);
        SuccessNotificationMsg(t("msg_addFormulationSuccess"));
        setNewFormulationAdded(!newFormulationAdded);
        setBtnloading(false);
        setCurrentTab((prevTab) => ({
          ...prevTab,
          // child1: false,
          child1: false, // Set child2 to false or adjust based on your requirement
        }));
        setUnsavedChanges((prevTab) => ({
          ...prevTab,
          outerClickChild1: false,
          // outerClickChild2: false,
        }));
      } else {
        await updateFormulation({
          id: formulationId,
          formulation_type: state.formulation_type,
          monthly_capacity: state.monthly_capacity,
        });
        setState({
          ...state,
          formulation_id: null,
          formulation_type: [],
          monthly_capacity: null,
        });
        dispatch(resetAfterUpdate(true));
        formRef.current.setFieldsValue(initialState);
        SuccessNotificationMsg(t("msg_updateFormulationSuccess"));
        setNewFormulationAdded(!newFormulationAdded);
        setBtnloading(false);
        setCurrentTab((prevTab) => ({
          ...prevTab,
          // child1: false,
          child1: false, // Set child2 to false or adjust based on your requirement
        }));
        setUnsavedChanges((prevTab) => ({
          ...prevTab,
          outerClickChild1: false,
          // outerClickChild2: false,
        }));
      }
    } catch (err) {
      setBtnloading(false);
    }
  };

  const resetField = () => {
    dispatch(resetAfterUpdate(true));
    setState({
      ...state,
      formulation_id: null,
      formulation_type: [],
      monthly_capacity: null,
    });
    formRef.current.setFieldsValue(initialState);
  };

  const removeId = (id) => {
    const updatedArray = state.formulation_type.filter((item) => item !== id);
    setFormulationTypeList(updatedArray);
    setState({ ...state, formulation_type: [] });
    formRef.current.setFieldsValue({
      ...state,
      formulation_type: [],
    });
  };

  // const validateTenDigits = (_, value) => {
  //   if (!value || value.toString().length > 10) {
  //     return Promise.reject(
  //       new Error("Please enter a 10-digit numeric value.")
  //     );
  //   }
  //   const numericRegex = /^[0-9]+$/;
  //   if (!numericRegex.test(value)) {
  //     return Promise.reject(new Error("Please enter a valid numeric value."));
  //   }
  //   return Promise.resolve();
  // };

  const validateTenDigits = (_, value) => {
    if (!value || value.toString().length > 10) {
      return Promise.reject(
        new Error("Please enter a value with at most 10 characters.")
      );
    }
  
    // Allow alphanumeric
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    
    if (!alphanumericRegex.test(value)) {
      return Promise.reject(new Error("Please enter a valid alphanumeric value."));
    }
  
    return Promise.resolve();
  };
  return (
    <>
      <Form
        ref={formRef}
        onFinish={handleSubmit}
        autoComplete="off"
        onValuesChange={handleFormChange}
      >
        <Row gutter={[15]}>
          <Col xs={24} sm={12} lg={6}>
            <EditableFormulationType
              data={formulationTypeList}
              setDeleted={setDeleted}
              deleted={deleted}
              removeId={removeId}
              handleSelectChange={handleSelectChange}
              value={state.formulation_type}
            />
          </Col>

          <Col xs={24} sm={12} lg={8}>
            <Space size={[16, 16]}>
              <Form.Item
                name={"monthly_capacity"}
                label={t("pl_monthly_capacity_formulation")}
                rules={[
                  {
                    required: true,
                    message: <Trans>{t("val_monthly_capacity")}</Trans>,
                  },
                  // { validator: validateTenDigits },
                ]}
              >
                <Input
                  value={state.monthly_capacity}
                  placeholder={t("pl_monthly_capacity_formulation")}
                  onChange={(value) =>
                    setState({ ...state, monthly_capacity: value.target.value })
                  }
                />
              </Form.Item>
              <Col xs={24} className="savebtn">
                <Space size={[16, 16]}>
                  <ButtonField
                    type="primary"
                    htmlType="submit"
                    loading={btnLoading}
                    label={t("add")}
                  />
                  {state.formulation_id !== null && (
                    <ButtonField
                      type="primary"
                      loading={btnLoading}
                      onClick={resetField}
                      label={t("cancel")}
                    />
                  )}
                </Space>
              </Col>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};

AddFormulation.propTypes = {
  facility_id: PropTypes.string, // Example: Assuming it's a number
  setNewFormulationAdded: PropTypes.func, // Example: Assuming it's a function
  newFormulationAdded: PropTypes.bool, // Example: Assuming it's a boolean

  setCurrentTab: PropTypes.func,
};

export default AddFormulation;
