import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { Form, Input, Select, Divider, Popconfirm } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  ErrorNotificationMsg,
  ShowErrorMsg,
  SuccessNotificationMsg,
} from "../../../../../../utils/Notifications";
import { deleteRequest, postRequest } from "../../../../../../axios";
import { authUserData } from "../../../../../../utils/Helper";

const EditableDmfCep = (props) => {
  const { t } = useTranslation();
  const [customFieldName, setCustomFieldName] = useState("");

  const addCustomField = async () => {
    if (customFieldName === "") {
      ErrorNotificationMsg(t("err_enterDmfCepField"));
      return false;
    }
    try {
      const extraAddApiResponse = await postRequest(
        "facility/list-create-dmf-cep/",
        {
          dmf_cep: customFieldName,
        }
      );
      if (extraAddApiResponse.data.success) {
        SuccessNotificationMsg(t("msg_addDmfCepSuccess"));
        props.handleSelectChange(props.name, customFieldName);
        props.data.push({ ...extraAddApiResponse.data.data });
        setCustomFieldName("");
      }
    } catch (error) {
      ShowErrorMsg(error);
    }
  };

  const deleteOption = async (id) => {
    const apiResponse = await deleteRequest("facility/delete-dmf-cep/" + id);
    if (apiResponse.data.success) {
      SuccessNotificationMsg(t("msg_dmfCepDeleted"));
      props.setDeleted(!props.deleted);
      props.removeId(props.name, id);
    }
  };

  return (
    <>
      <Form.Item
        name={props.name}
        label={props.label}
        // rules={[
        //   {
        //     required: true,
        //     message: <Trans>{t("val_dmf_cep")}</Trans>,
        //   },
        // ]}
      >
        <Select
          showSearch
          showArrow
          allowClear
          mode={props.mode}
          maxTagCount={props.maxTagCount}
          defaultValue={props.fieldValue}
          value={props.value}
          placeholder={props.placeholder}
          onChange={(value) => props.handleSelectChange(props.name, value)}
          optionFilterProp="children"
          dropdownRender={(menu) => (
            <div>
              {menu}
              <>
                <Divider style={{ margin: "4px 0" }} />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    padding: 8,
                  }}
                >
                  <Input
                    style={{ flex: "auto" }}
                    onChange={(e) => setCustomFieldName(e.target.value)}
                    value={customFieldName}
                    placeholder={t("Enter Here")}
                  />
                  <button
                    style={{ cursor: "pointer" }}
                    className="btn-style"
                    onClick={addCustomField}
                  >
                    <PlusOutlined />
                  </button>
                </div>
              </>
            </div>
          )}
        >
          {props.data.map((field) => {
            return (
              <Select.Option
                key={field.id}
                value={field.id}
                disabled={props.value.length > 0 && props.value.includes(6)}
              >
                <span>{field.dmf_cep}</span>
                &nbsp;&nbsp;
                <span style={{ marginRight: "10px", float: "right" }}>
                  {authUserData()?.group === "Company Admin" &&
                    field.created_by === authUserData()?.user_id && (
                      <Popconfirm
                        className="action"
                        title={t("pop_title")}
                        okText={t("pop_ok")}
                        cancelText={t("pop_cancel")}
                        onConfirm={(e) => {
                          e.stopPropagation();
                          deleteOption(field.id);
                        }}
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    )}
                  {authUserData()?.group === "Super Admin" && (
                    <Popconfirm
                      className="action"
                      title={t("pop_title")}
                      okText={t("pop_ok")}
                      cancelText={t("pop_cancel")}
                      onConfirm={(e) => {
                        e.stopPropagation();
                        deleteOption(field.id);
                      }}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  )}
                </span>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};

EditableDmfCep.propTypes = {
  handleSelectChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  maxTagCount: PropTypes.string.isRequired,
  fieldValue: PropTypes.string,
  value: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  deleted: PropTypes.bool.isRequired,
  setDeleted: PropTypes.func.isRequired,
  removeId: PropTypes.func.isRequired,
};

export default EditableDmfCep;
