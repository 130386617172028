import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import ButtonField from "../../../../../../common/htmlComponents/ButtonField";
const RequiredFieldsModel = ({ handleCancelc4 }) => {
  const { t } = useTranslation();

  const backToList = () => {
    handleCancelc4();
  };

  return (
    <>
      <p>{t("model_description_required")}</p>
      <Row gutter={[15]} className="bottomButtons">
        <Col xs={12} align="center">
          <ButtonField
            type="primary"
            htmlType=""
            // loading={btnLoading}
            label={t("button_back_required")}
            onClick={backToList}
          />
        </Col>
      </Row>
    </>
  );
};
RequiredFieldsModel.propTypes = {
  handleCancelc4: PropTypes.func,
  // handlePreviousFacilityChange: PropTypes.func,
};

export default RequiredFieldsModel;
