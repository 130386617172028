import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Button, Space, Radio, Modal } from "antd";
import { CheckOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import PlantAndLab from "../plant&lab/Plant&Lab";
import Utilities from "../utilities/Utilities";
import PhotoGallery from "../photoGallery/PhotoGallery";
import ManageAddProduct from "../api/addProduct/ManageAddProduct";
import ManageAddFormulation from "../formulation/addFormulation/ManageAddFormulation";
import { getFacilityAPIId } from "../api/redux/AddFacilityAPISlice";
import {
  getAPIPhotogalleryById,
  getFormulationPhotogalleryById,
} from "../photoGallery/PhotoGallerySlice";
import {
  getclassficationAPI,
  getclassficationFormulation,
} from "../plant&lab/Plant&LabSlice";
import { getUtilities } from "../utilities/redux/UtilitiesSlice";
import ConfirmAlertModel from "../api/ConfirmAlertModel";
import ConfirmRadioModel from "../cdmo/ConfirmRadioModel";
import ButtonField from "../../../../../common/htmlComponents/ButtonField";
import ConfirmAlertPrevious from "./ConfirmAlertPrevious";
import ConfirmPreviousFacility from "./ConfirmPreviousFacility";
import RequiredFieldsModel from "../api/addProduct/RequiredFieldsModel";
import RequiredFieldsNextModel from "../api/addProduct/RequiredFieldsNextModal";
import { useUnsavedChanges } from "../../../../UnsavedChangesProvider";
const CDMO = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setUnsavedChanges } = useUnsavedChanges();
  const navigate = useNavigate();

  const { facilityId } = useParams();
  const facility_id = atob(facilityId);

  const productCount = useSelector((state) => state.addProduct.productCount);
  const plantLabAPICount = useSelector(
    (state) => state.plantLab.classificationAPICount
  );
  const utilityCount = useSelector(
    (state) => state.UtilitiesCount.utilityCount
  );
  const photoGalleryAPI_count = useSelector(
    (state) => state.photoGallery.photoGalleryAPI_count
  );

  const formulationCount = useSelector(
    (state) => state.addFormulation.formulationCount
  );
  const plantLabFormulationCount = useSelector(
    (state) => state.plantLab.classificationFormulationCount
  );
  const photoGalleryFormulation_count = useSelector(
    (state) => state.photoGallery.photoGalleryFormulation_count
  );

  const plant_lab_api_id = useSelector(
    (state) => state.facilityAPI.plant_lab_api_id
  );
  const utility_api_id = useSelector(
    (state) => state.facilityAPI.utility_api_id
  );
  const facility_type_id = useSelector(
    (state) => state.facilityAPI.facility_api_id
  );
  const sub_utility_api_id = useSelector(
    (state) => state.facilityAPI.sub_utility_api_id
  );
  const waterTreatment_api_id = useSelector(
    (state) => state.facilityAPI.waterTreatment_api_id
  );

  const formulationMaster = useSelector(
    (state) => state.addFormulation.formulationMaster
  );
  const plantLab_formulation_id = useSelector(
    (state) => state.addFormulation.formulationPlantLabId
  );
  const utility_formulation_id = useSelector(
    (state) => state.addFormulation.formulationUtilityId
  );
  const subutility_formulation_id = useSelector(
    (state) => state.addFormulation.formulationSubUtilityId
  );
  const waterTreatmet_formulation_id = useSelector(
    (state) => state.addFormulation.formulationWaterTreatmetId
  );

  const initialState = {
    currenttab: 0,
    child1: false,
    child2: false,
    child3: false,
    child4: false,
  };
  const [currentTab, setCurrentTab] = useState(initialState);
  const [tabName, setTabName] = useState("API_showCDMOComponent");
  const [tabIndex, setTabIndex] = useState("0");
  const [radioValueModel, setRadioValueModel] = useState("");
  const [showCDMOComponent, setShowCDMOComponent] = useState(
    "API_showCDMOComponent"
  );
  const [showPlantAndLabComponent, setShowPlantAndLabComponent] =
    useState(false);
  const [showUtilitiesComponent, setShowUtilitiesComponent] = useState(false);
  const [showPhotoGalleryComponent, setShowPhotoGalleryComponent] =
    useState(false);
  const [isModalOpenc, setIsModalOpenc] = useState(false);
  const [isModalOpenc1, setIsModalOpenc1] = useState(false);
  const [isModalOpenc2, setIsModalOpenc2] = useState(false);
  const [isModalOpenc3, setIsModalOpenc3] = useState(false);
  const [isModalOpenc4, setIsModalOpenc4] = useState(false);
  const [isModalOpenc5, setIsModalOpenc5] = useState(false);
  const [isModalNextValidationCDMO, setIsModalNextValidationCDMO] =
    useState(false);
  const [isModalOpenValidationCDMO, setIsModalOpenValidationCDMO] =
    useState(false);
  const updateTabInfo = (tabIndex, tabName) => {
    setCurrentTab((prevState) => ({
      ...prevState,
      currenttab: tabIndex,
    }));
    setTabName(tabName);
  };
  const hideComponent = (name, index) => {
    setShowCDMOComponent(name, index);
    setShowPlantAndLabComponent(name, index);
    setShowUtilitiesComponent(name, index);
    setShowPhotoGalleryComponent(name, index);
    setComponentArrIndex(index);
    updateTabInfo(index, name);
  };

  const [showAPIComponent, setShowAPIComponent] = useState("API");
  const [showFormulationComponent, setShowFormulationComponent] =
    useState(false);
  const [firstComponent, setFirstComponent] = useState("API");
  const [componentArrIndex, setComponentArrIndex] = useState(0);
  const [radioValue, setRadioValue] = useState("API");

  // const handleChange = (e) => {
  //   if (e.target.value === "API") {
  //     setShowAPIComponent(true);
  //     setShowFormulationComponent(false);
  //     setFirstComponent("API");
  //     setRadioValue("API");
  //   } else if (e.target.value === "FORMULATION") {
  //     setShowFormulationComponent(true);
  //     setShowAPIComponent(false);
  //     setFirstComponent("FORMULATION");
  //     setRadioValue("FORMULATION");
  //   }
  //   hideComponent(`${e.target.value}_showCDMOComponent`, 0);
  // };

  const handleChange = (e) => {
    if (currentTab.child1 === false && currentTab.child2 === false) {
      setRadioValueModel(e.target.value);
      handleRadioModelChange(e.target.value);
      facility_id && e.target.value === "API"
        ? dispatch(getFacilityAPIId(facility_id))
        : console.log("");
    } else {
      setRadioValueModel(e.target.value);
      setIsModalOpenc2(true);
    }
  };
  const handleRadioModelChange = (radiovalue) => {
    if (radiovalue === "API") {
      setShowAPIComponent(true);
      setShowFormulationComponent(false);
      setFirstComponent("API");
      setRadioValue("API");
    } else if (radiovalue === "FORMULATION") {
      setShowFormulationComponent(true);
      setShowAPIComponent(false);
      setFirstComponent("FORMULATION");
      setRadioValue("FORMULATION");
    }
    hideComponent(`${radiovalue}_showCDMOComponent`, 0);
    facility_id && `${radiovalue}_showCDMOComponent` === "API_showCDMOComponent"
      ? dispatch(getFacilityAPIId(facility_id))
      : console.log("");
    setCurrentTab((prevState) => ({
      ...prevState,
      currenttab: 0,
      child1: false,
      child2: false,
      child3: false,
      child4: false,
    }));
    setUnsavedChanges((prevTab) => ({
      ...prevTab,
      outerClickChild1: false,
      outerClickChild2: false,
    }));
  };

  const formulationTab = () => {
    setRadioValue("FORMULATION");
    setShowFormulationComponent(true);
    setShowAPIComponent(false);
    setFirstComponent("FORMULATION");
    hideComponent(`FORMULATION_showCDMOComponent`, 0);
  };

  const apiTab = () => {
    if (currentTab.child1 === false && currentTab.child2 === false) {
      setRadioValue("API");
      setShowFormulationComponent(false);
      setShowAPIComponent(true);
      setFirstComponent("API");
      hideComponent(`API_showCDMOComponent`, 0);
      // console.log(showTabName);
      // showTabName === "showAddProductComponent"
      facility_id ? dispatch(getFacilityAPIId(facility_id)) : console.log("");
    } else {
      setIsModalOpenc5(true);
    }
  };
  const handleNextAPI = () => {
    setRadioValue("API");
    setShowFormulationComponent(false);
    setShowAPIComponent(true);
    setFirstComponent("API");
    hideComponent(`API_showCDMOComponent`, 0);
    // console.log(name);
    // name === "showAddProductComponent"
    facility_id ? dispatch(getFacilityAPIId(facility_id)) : console.log("");

    setCurrentTab((prevState) => ({
      ...prevState,
      currenttab: 0,
      child1: false,
      child2: false,
      child3: false,
      child4: false,
    }));
    setUnsavedChanges((prevTab) => ({
      ...prevTab,
      outerClickChild1: false,
      outerClickChild2: false,
    }));
    setIsModalOpenc5(false);
  };

  const componentArr = [
    {
      f_type: "API",
      f_componentArr: [
        "API_showCDMOComponent",
        "API_showPlantAndLabComponent",
        "API_showUtilitiesComponent",
        "API_showPhotoGalleryComponent",
      ],
    },
    {
      f_type: "Formulation",
      f_componentArr: [
        "FORMULATION_showCDMOComponent",
        "FORMULATION_showPlantAndLabComponent",
        "FORMULATION_showUtilitiesComponent",
        "FORMULATION_showPhotoGalleryComponent",
      ],
    },
  ];
  const handleCancelc = () => {
    setIsModalOpenc(false);
  };
  const handleCancelc1 = () => {
    setIsModalOpenc1(false);
  };
  const handleCancelc2 = () => {
    setIsModalOpenc2(false);
  };
  const handleCancelc3 = () => {
    setIsModalOpenc3(false);
  };
  const handleCancelc4 = () => {
    setIsModalOpenc4(false);
  };
  const handleCancelc5 = () => {
    setIsModalOpenc5(false);
  };

  const handleCancelNextValidationCDMO = () => {
    setIsModalNextValidationCDMO(false);
  };
  const handleCancelValidationCDMO = () => {
    setIsModalOpenValidationCDMO(false);
  };

  const handleNext = () => {
    if ("API" === showAPIComponent || true === showAPIComponent) {
      if (currentTab.child3 === false && currentTab.child4 === false) {
        if (currentTab.child1 === false && currentTab.child2 === false) {
          let showTabName =
            componentArr[0]["f_componentArr"][componentArrIndex + 1];
          hideComponent(showTabName, componentArrIndex + 1);
        } else {
          setIsModalOpenc(true);
          // console.log(currenttab);
        }
      } else {
        setIsModalNextValidationCDMO(true);
      }
    } else {
      // let showTabName =
      //   componentArr[1]["f_componentArr"][componentArrIndex + 1];
      // hideComponent(showTabName, componentArrIndex + 1);
      if (currentTab.child3 === false && currentTab.child4 === false) {
        if (currentTab.child1 === false && currentTab.child2 === false) {
          let showTabName =
            componentArr[1]["f_componentArr"][componentArrIndex + 1];
          hideComponent(showTabName, componentArrIndex + 1);
        } else {
          setIsModalOpenc(true);
          // console.log(currenttab);
        }
      } else {
        setIsModalNextValidationCDMO(true);
      }
    }
  };

  const handleTabChange = () => {
    if ("API" === showAPIComponent || true === showAPIComponent) {
      // if (currentTab.child1 === false && currentTab.child2 === false) {
      let showTabName =
        componentArr[0]["f_componentArr"][componentArrIndex + 1];
      hideComponent(showTabName, componentArrIndex + 1);
      setCurrentTab((prevState) => ({
        ...prevState,
        child1: false,
        child2: false,
      }));
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        outerClickChild1: false,
        outerClickChild2: false,
      }));
    } else {
      let showTabName =
        componentArr[1]["f_componentArr"][componentArrIndex + 1];
      hideComponent(showTabName, componentArrIndex + 1);
      setCurrentTab((prevState) => ({
        ...prevState,
        child1: false,
        child2: false,
      }));
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        outerClickChild1: false,
        outerClickChild2: false,
      }));
    }
  };

  const handleAboveTabChange = (name, index) => {
    // console.log(name);
    setTabName((tabName) => (tabName = name));
    setTabIndex((tabIndex) => (tabIndex = index));
    // let showTabName = componentArr[componentArrIndex + 1];
    if (currentTab.child3 === false && currentTab.child4 === false) {
      if (currentTab.child1 === false && currentTab.child2 === false) {
        hideComponent(name, index);

        name === "API_showCDMOComponent"
          ? facility_id && dispatch(getFacilityAPIId(facility_id))
          : console.log("");
        setCurrentTab((prevState) => ({
          ...prevState,
          child1: false,
          child2: false,
        }));
      } else {
        setIsModalOpenc1(true);
        // setCurrentTab((prevState) => ({
        //   ...prevState,
        //   child1: false,
        //   child2: false,
        // }));
      }
    } else {
      setIsModalOpenValidationCDMO(true);
    }
  };

  const handleNextPageAbove = () => {
    hideComponent(tabName, tabIndex);

    tabName === "API_showCDMOComponent"
      ? facility_id && dispatch(getFacilityAPIId(facility_id))
      : console.log("");
    setCurrentTab((prevState) => ({
      ...prevState,
      child1: false,
      child2: false,
    }));
    setUnsavedChanges((prevTab) => ({
      ...prevTab,
      outerClickChild1: false,
      outerClickChild2: false,
    }));
    handleCancelc1();
  };
  const handlePrev = () => {
    setCurrentTab((prevState) => ({
      ...prevState,
      child3: false,
      child4: false,
    }));
    if ("API" === showAPIComponent || true === showAPIComponent) {
      if (currentTab.child1 === false && currentTab.child2 === false) {
        let showTabName =
          componentArr[0]["f_componentArr"][componentArrIndex - 1];
        hideComponent(showTabName, componentArrIndex - 1);

        showTabName === "API_showCDMOComponent"
          ? facility_id && dispatch(getFacilityAPIId(facility_id))
          : console.log("");

        //Here adding this line to set state of child to false
        // setCurrentTab((prevState) => ({
        //   ...prevState,
        //   child1: false,
        //   child2: false,
        // }));
      } else {
        setIsModalOpenc3(true);
      }
    } else {
      if (currentTab.child1 === false && currentTab.child2 === false) {
        let showTabName =
          componentArr[1]["f_componentArr"][componentArrIndex - 1];
        hideComponent(showTabName, componentArrIndex - 1);
        //Here adding this line to set state of child to false
        // setCurrentTab((prevState) => ({
        //   ...prevState,
        //   child1: false,
        //   child2: false,
        // }));
      } else {
        setIsModalOpenc3(true);
      }
    }
  };

  const handlePreviousTabChange = () => {
    if ("API" === showAPIComponent || true === showAPIComponent) {
      // if (currentTab.child1 === false && currentTab.child2 === false) {
      let showTabName =
        componentArr[0]["f_componentArr"][componentArrIndex - 1];
      hideComponent(showTabName, componentArrIndex - 1);

      showTabName === "API_showCDMOComponent"
        ? facility_id && dispatch(getFacilityAPIId(facility_id))
        : console.log("");
      setCurrentTab((prevState) => ({
        ...prevState,
        child1: false,
        child2: false,
      }));
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        outerClickChild1: false,
        outerClickChild2: false,
      }));
    } else {
      let showTabName =
        componentArr[1]["f_componentArr"][componentArrIndex - 1];
      hideComponent(showTabName, componentArrIndex - 1);
      setCurrentTab((prevState) => ({
        ...prevState,
        child1: false,
        child2: false,
      }));
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        outerClickChild1: false,
        outerClickChild2: false,
      }));
    }
  };

  // const handleFacility = () => {
  //   navigate("/manage-facility/edit-facility/" + btoa(facility_id));
  // };
  const handleFacility = () => {
    if (currentTab.child1 === false && currentTab.child2 === false) {
      navigate("/manage-facility/edit-facility/" + btoa(facility_id));
    } else {
      setIsModalOpenc4(true);
    }
  };
  const handlePreviousFacilityChange = () => {
    navigate("/manage-facility/edit-facility/" + btoa(facility_id));
    setCurrentTab((prevState) => ({
      ...prevState,
      child1: false,
      child2: false,
    }));
    setUnsavedChanges((prevTab) => ({
      ...prevTab,
      outerClickChild1: false,
      outerClickChild2: false,
    }));
  };

  useEffect(() => {
    if (facility_id) {
      dispatch(getFacilityAPIId(facility_id));
    }
    if (facility_type_id) {
      dispatch(getAPIPhotogalleryById(facility_type_id));
    }
    if (plant_lab_api_id) {
      dispatch(getclassficationAPI(plant_lab_api_id));
    }
    if (utility_api_id) {
      dispatch(getUtilities(utility_api_id));
    }
    if (formulationMaster) {
      dispatch(getFormulationPhotogalleryById(formulationMaster));
    }
    if (plantLab_formulation_id) {
      dispatch(getclassficationFormulation(plantLab_formulation_id));
    }
    if (utility_formulation_id) {
      dispatch(getUtilities(utility_formulation_id));
    }
  }, [
    dispatch,
    facility_id,
    facility_type_id,
    plant_lab_api_id,
    formulationMaster,
    plantLab_formulation_id,
    utility_api_id,
    utility_formulation_id,
  ]);

  return (
    <>
      <Row gutter={[15]}>
        <Col xs={24}>
          <Row gutter={[15]}>
            <Col>
              <Radio.Group
                onChange={handleChange}
                className="subradiowrap cdmo"
                value={radioValue}
              >
                <Radio value={"API"}>API</Radio>
                <Radio value={"FORMULATION"}>FORMULATION</Radio>
              </Radio.Group>
            </Col>
          </Row>

          {showAPIComponent && (
            <Row gutter={[15]}>
              <Col xs={24}>
                <Space size={[16, 16]} className="tabbutton">
                  <Button
                    type="link"
                    className={
                      firstComponent === "API" &&
                      showCDMOComponent === "API_showCDMOComponent"
                        ? "success"
                        : ""
                    }
                    // onClick={() => hideComponent("API_showCDMOComponent", 0)}
                    onClick={() =>
                      handleAboveTabChange("API_showCDMOComponent", 0)
                    }
                  >
                    <div>
                      {productCount > 0 ? (
                        <CheckOutlined />
                      ) : (
                        <Loading3QuartersOutlined />
                      )}
                    </div>{" "}
                    {t("addProduct")}
                  </Button>
                  <Button
                    type="link"
                    className={
                      firstComponent === "API" &&
                      showPlantAndLabComponent ===
                        "API_showPlantAndLabComponent"
                        ? "success"
                        : ""
                    }
                    // onClick={() =>
                    //   hideComponent("API_showPlantAndLabComponent", 1)
                    // }
                    onClick={() =>
                      handleAboveTabChange("API_showPlantAndLabComponent", 1)
                    }
                  >
                    <div>
                      {plantLabAPICount > 0 ? (
                        <CheckOutlined />
                      ) : (
                        <Loading3QuartersOutlined />
                      )}
                    </div>
                    {t("plantLab")}
                  </Button>
                  <Button
                    type="link"
                    className={
                      firstComponent === "API" &&
                      showUtilitiesComponent === "API_showUtilitiesComponent"
                        ? "success"
                        : ""
                    }
                    // onClick={() =>
                    //   hideComponent("API_showUtilitiesComponent", 2)
                    // }
                    onClick={() =>
                      handleAboveTabChange("API_showUtilitiesComponent", 2)
                    }
                  >
                    <div>
                      {utilityCount === 1 ? (
                        <CheckOutlined />
                      ) : (
                        <Loading3QuartersOutlined />
                      )}
                    </div>{" "}
                    {t("utilities")}
                  </Button>
                  <Button
                    type="link"
                    className={
                      firstComponent === "API" &&
                      showPhotoGalleryComponent ===
                        "API_showPhotoGalleryComponent"
                        ? "success"
                        : ""
                    }
                    // onClick={() =>
                    //   hideComponent("API_showPhotoGalleryComponent", 3)
                    // }
                    onClick={() =>
                      handleAboveTabChange("API_showPhotoGalleryComponent", 3)
                    }
                  >
                    <div>
                      {photoGalleryAPI_count === 5 ? (
                        <CheckOutlined />
                      ) : (
                        <Loading3QuartersOutlined />
                      )}
                    </div>
                    {t("photoGallery")}
                  </Button>
                </Space>
                {firstComponent === "API" &&
                  showCDMOComponent === "API_showCDMOComponent" && (
                    <ManageAddProduct
                      facility_id={facility_id}
                      setCurrentTab={setCurrentTab}
                    />
                  )}
                {firstComponent === "API" &&
                  showPlantAndLabComponent ===
                    "API_showPlantAndLabComponent" && (
                    <PlantAndLab
                      facility_type_id={facility_type_id}
                      plant_lab_api_id={plant_lab_api_id}
                      setCurrentTab={setCurrentTab}
                    />
                  )}
                {firstComponent === "API" &&
                  showUtilitiesComponent === "API_showUtilitiesComponent" && (
                    <Utilities
                      facility_type_id={facility_type_id}
                      utility_api_id={utility_api_id}
                      subUtility_id={sub_utility_api_id}
                      waterTreatment_id={waterTreatment_api_id}
                      setCurrentTab={setCurrentTab}
                    />
                  )}
                {firstComponent === "API" &&
                  showPhotoGalleryComponent ===
                    "API_showPhotoGalleryComponent" && (
                    <PhotoGallery facility_type_id={facility_type_id} />
                  )}
              </Col>
            </Row>
          )}

          {showFormulationComponent && (
            <Row gutter={[15]}>
              <Col xs={24}>
                <Space size={[16, 16]} className="tabbutton">
                  <Button
                    type="link"
                    className={
                      firstComponent === "FORMULATION" &&
                      showCDMOComponent === "FORMULATION_showCDMOComponent"
                        ? "success"
                        : ""
                    }
                    // onClick={() =>
                    //   hideComponent("FORMULATION_showCDMOComponent", 0)
                    // }
                    onClick={() =>
                      handleAboveTabChange("FORMULATION_showCDMOComponent", 0)
                    }
                  >
                    <div>
                      {formulationCount > 0 ? (
                        <CheckOutlined />
                      ) : (
                        <Loading3QuartersOutlined />
                      )}
                    </div>
                    {t("addFormulation")}
                  </Button>
                  <Button
                    type="link"
                    className={
                      firstComponent === "FORMULATION" &&
                      showPlantAndLabComponent ===
                        "FORMULATION_showPlantAndLabComponent"
                        ? "success"
                        : ""
                    }
                    // onClick={() =>
                    //   hideComponent("FORMULATION_showPlantAndLabComponent", 1)
                    // }
                    onClick={() =>
                      handleAboveTabChange(
                        "FORMULATION_showPlantAndLabComponent",
                        1
                      )
                    }
                  >
                    <div>
                      {plantLabFormulationCount > 0 ? (
                        <CheckOutlined />
                      ) : (
                        <Loading3QuartersOutlined />
                      )}
                    </div>
                    {t("plantLab")}
                  </Button>
                  <Button
                    type="link"
                    className={
                      firstComponent === "FORMULATION" &&
                      showUtilitiesComponent ===
                        "FORMULATION_showUtilitiesComponent"
                        ? "success"
                        : ""
                    }
                    // onClick={() =>
                    //   hideComponent("FORMULATION_showUtilitiesComponent", 2)
                    // }
                    onClick={() =>
                      handleAboveTabChange(
                        "FORMULATION_showUtilitiesComponent",
                        2
                      )
                    }
                  >
                    <div>
                      {utilityCount === 1 ? (
                        <CheckOutlined />
                      ) : (
                        <Loading3QuartersOutlined />
                      )}
                    </div>{" "}
                    {t("utilities")}
                  </Button>
                  <Button
                    type="link"
                    className={
                      firstComponent === "FORMULATION" &&
                      showPhotoGalleryComponent ===
                        "FORMULATION_showPhotoGalleryComponent"
                        ? "success"
                        : ""
                    }
                    // onClick={() =>
                    //   hideComponent("FORMULATION_showPhotoGalleryComponent", 3)
                    // }
                    onClick={() =>
                      handleAboveTabChange(
                        "FORMULATION_showPhotoGalleryComponent",
                        3
                      )
                    }
                  >
                    <div>
                      {photoGalleryFormulation_count === 5 ? (
                        <CheckOutlined />
                      ) : (
                        <Loading3QuartersOutlined />
                      )}
                    </div>
                    {t("photoGallery")}
                  </Button>
                </Space>
                {firstComponent === "FORMULATION" &&
                  showCDMOComponent === "FORMULATION_showCDMOComponent" && (
                    <ManageAddFormulation
                      facility_id={facility_id}
                      formulationMaster={formulationMaster}
                      setCurrentTab={setCurrentTab}
                    />
                  )}
                {firstComponent === "FORMULATION" &&
                  showPlantAndLabComponent ===
                    "FORMULATION_showPlantAndLabComponent" && (
                    <PlantAndLab
                      plantLab_formulation_id={plantLab_formulation_id}
                      formulationMaster={formulationMaster}
                      setCurrentTab={setCurrentTab}
                    />
                  )}
                {firstComponent === "FORMULATION" &&
                  showUtilitiesComponent ===
                    "FORMULATION_showUtilitiesComponent" && (
                    <Utilities
                      utility_formulation_id={utility_formulation_id}
                      formulationMaster={formulationMaster}
                      subUtility_id={subutility_formulation_id}
                      waterTreatment_id={waterTreatmet_formulation_id}
                      setCurrentTab={setCurrentTab}
                    />
                  )}
                {firstComponent === "FORMULATION" &&
                  showPhotoGalleryComponent ===
                    "FORMULATION_showPhotoGalleryComponent" && (
                    <PhotoGallery formulationMaster={formulationMaster} />
                  )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <br />
      {(facility_type_id || formulationMaster) && (
        <Row>
          <Col xs={24} align="end" className="text-right">
            <Space size={[16, 16]}>
              {componentArrIndex >= 1 && (
                <Button type="primary" onClick={handlePrev}>
                  {t("previous")}
                </Button>
              )}
              {showAPIComponent && componentArrIndex === 0 && (
                <Button type="primary" onClick={handleFacility}>
                  {t("previous")}
                </Button>
              )}
              {showFormulationComponent && componentArrIndex === 0 && (
                <Button type="primary" onClick={apiTab}>
                  {t("api")}
                </Button>
              )}
              {componentArrIndex <= 2 && (
                <Button type="primary" onClick={handleNext}>
                  {t("next")}
                </Button>
              )}
              {showAPIComponent && componentArrIndex === 3 && (
                <Button type="primary" onClick={formulationTab}>
                  {t("formulation")}
                </Button>
              )}
              {showFormulationComponent && componentArrIndex === 3 && (
                <Button
                  type="primary"
                  onClick={() => navigate("/manage-facility")}
                >
                  {t("finish")}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      )}

      <Modal
        title={t("model_title")}
        open={isModalOpenc}
        footer={null}
        onCancel={handleCancelc}
        width={580}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <ConfirmAlertModel
          handleCancelc={handleCancelc}
          handleTableChange={handleTabChange}
        />
      </Modal>
      <Modal
        title={t("model_title")}
        open={isModalOpenc2}
        footer={null}
        onCancel={handleCancelc2}
        width={580}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <ConfirmRadioModel
          handleCancelc2={handleCancelc2}
          handleRadioModelChange={handleRadioModelChange}
          radioValueModel={radioValueModel}
        />
      </Modal>
      <Modal
        title={t("model_title")}
        open={isModalOpenc3}
        footer={null}
        onCancel={handleCancelc3}
        width={580}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <ConfirmAlertPrevious
          handleCancelc3={handleCancelc3}
          handlePreviousTabChange={handlePreviousTabChange}
        />
      </Modal>
      <Modal
        title={t("model_title")}
        open={isModalOpenc1}
        footer={null}
        onCancel={handleCancelc1}
        width={580}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <>
          <p>{t("model_description_message")}</p>
          <Row gutter={[15]} className="bottomButtons">
            <Col xs={12} align="center">
              <ButtonField
                type="primary"
                htmlType=""
                // loading={btnLoading}
                label={t("button_back")}
                onClick={handleNextPageAbove}
              />
            </Col>
            <Col xs={12} align="center">
              <ButtonField
                type="primary"
                htmlType=""
                // loading={btnLoading}
                label={t("button_save")}
                onClick={handleCancelc1}
              />
            </Col>
          </Row>
        </>
      </Modal>

      <Modal
        title={t("model_title")}
        open={isModalOpenc4}
        footer={null}
        onCancel={handleCancelc4}
        width={580}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <ConfirmPreviousFacility
          handleCancelc4={handleCancelc4}
          handlePreviousFacilityChange={handlePreviousFacilityChange}
        />
      </Modal>

      <Modal
        title={t("model_title")}
        open={isModalOpenc5}
        footer={null}
        onCancel={handleCancelc5}
        width={580}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <>
          <p>{t("model_description_message")}</p>
          <Row gutter={[15]} className="bottomButtons">
            <Col xs={12} align="center">
              <ButtonField
                type="primary"
                htmlType=""
                // loading={btnLoading}
                label={t("button_back")}
                onClick={handleNextAPI}
              />
            </Col>
            <Col xs={12} align="center">
              <ButtonField
                type="primary"
                htmlType=""
                // loading={btnLoading}
                label={t("button_save")}
                onClick={handleCancelc5}
              />
            </Col>
          </Row>
        </>
      </Modal>
      <Modal
        title={t("model_title_required")}
        open={isModalOpenValidationCDMO}
        footer={null}
        onCancel={handleCancelValidationCDMO}
        width={300}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <RequiredFieldsModel handleCancelc4={handleCancelValidationCDMO} />
      </Modal>
      <Modal
        title={t("model_title_required")}
        open={isModalNextValidationCDMO}
        footer={null}
        onCancel={handleCancelNextValidationCDMO}
        width={300}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <RequiredFieldsNextModel
          handleCancelc4={handleCancelNextValidationCDMO}
        />
      </Modal>
    </>
  );
};

export default CDMO;
