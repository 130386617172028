import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_GJ } from "./gj/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_HD } from "./hd/translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    detection: {
      checkWhitelist: true, // options for language detection
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    useSuspense: true,
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      hd: {
        translation: TRANSLATIONS_HD,
      },
      gj: {
        translation: TRANSLATIONS_GJ,
      },
    },
    react: {
      defaultTransParent: "div",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    },
  });

export default i18n;
