import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Radio,
  Select,
  Form,
  Space,
  Modal,
  Input,
  Popconfirm,
  Tooltip,
} from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import AddProduct from "./AddProduct";
import BulkProductUpload from "./BulkProductUpload";
import ProductList from "./ProductList";
import ButtonField from "../../../../../../common/htmlComponents/ButtonField";
import ReactionCapability from "../ReactionCapability";
import {
  addFacilityAPI,
  deleteReactionCapability,
  getAllReactionCapability,
  updateFacilityAPI,
} from "../redux/AddFacilityAPISlice";
import { getProgressPrec } from "../../../../../profile/progressSlice";
import { authUserData } from "../../../../../../utils/Helper";
import { SuccessNotificationMsg } from "../../../../../../utils/Notifications";
import { useUnsavedChanges } from "../../../../../UnsavedChangesProvider";
const ManageAddProduct = (props) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const dispatch = useDispatch();
  const group = authUserData()?.group;
  // const { currentTab, setCurrentTab } = useCurrentTab();
  const { setCurrentTab } = props;
  const { setUnsavedChanges } = useUnsavedChanges();
  const reaction_capabilityList = useSelector(
    (state) => state.facilityAPI.reaction_capabilityList
  );
  const facility_type_id = useSelector(
    (state) => state.facilityAPI.facility_api_id
  );
  const list = useSelector((state) => state.facilityAPI.facilityAPI);
  const facilityAPI = list && list[0];

  const initialState = {
    id: props.facility_id,
    manufacturing_capacity: null,
    total_qty: null,
    facility_reaction_capability: [],
  };
  const [state, setState] = useState(initialState);
  const [addProductComponent, setAddProductComponent] = useState("addProduct");
  const [bulkProductUploadComponent, setBulkProductUploadComponent] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProductAdded, setNewProductAdded] = useState(false);
  const [btnLoading, setBtnloading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [radioValue, setRadioValue] = useState("addProduct");

  const handleCancel = () => {
    setIsModalOpen(false);
    formRef.current.setFieldsValue({ reaction_capability: [] });
  };

  useEffect(() => {
    dispatch(getAllReactionCapability());
    if (group === "Company Admin") {
      dispatch(getProgressPrec());
    }
  }, [dispatch, group, deleted]);

  useEffect(() => {
    if (facilityAPI) {
      formRef.current.setFieldsValue({
        manufacturing_capacity: facilityAPI.manufacturing_capacity,
        total_qty: facilityAPI.total_qty,
        facility_reaction_capability: facilityAPI.reaction_capability.map(
          function (el) {
            return el.id;
          }
        ),
      });
      setState({
        ...facilityAPI,
        manufacturing_capacity: facilityAPI.manufacturing_capacity,
        total_qty: facilityAPI.total_qty,
        facility_reaction_capability: facilityAPI.reaction_capability.map(
          function (el) {
            return el.id;
          }
        ),
      });
    }
  }, [facilityAPI, formRef]);

  useEffect(() => {
    // Check if any of the required properties are null
    // console.log(state.manufacturing_capacity);

    // if (
    //   state.manufacturing_capacity === null ||
    //   state.total_qty === null ||
    //   state.facility_reaction_capability.length === 0
    // ) {
    //   // console.log(state.manufacturing_capacity);
    //   console.log("Inside Product List UseEffect Child3: True");
    //   // If any of them are null, set child3State to true
    //   setCurrentTab((prevTab) => ({
    //     ...prevTab,

    //     child3: true,
    //     // child2: false, // Set child2 to false or adjust based on your requirement
    //   }));
    const isNull =
      state?.manufacturing_capacity === null ||
      state?.total_qty === null ||
      state?.facility_reaction_capability.length === 0;

    if (isNull) {
      // If any of them are null, set child3State to true
      setCurrentTab((prevTab) => ({
        ...prevTab,

        child3: true,
        // child2: false, // Set child2 to false or adjust based on your requirement
      }));
    } else {
      // Otherwise, set child3State to false
      setCurrentTab((prevTab) => ({
        ...prevTab,
        child3: false,
        // child2: false, // Set child2 to false or adjust based on your requirement
      }));
    }
  }, [
    state?.facility_reaction_capability,
    state.manufacturing_capacity,
    state.total_qty,
  ]);

  const handleFormChange = (changedValues, allValues) => {
    // Check if the changed values include the fields you want to track
    if (
      "manufacturing_capacity" in changedValues ||
      "total_qty" in changedValues ||
      "facility_reaction_capability" in changedValues
    ) {
      // setChild1(true);
      // Assuming currentTab state has child1 and child2 properties
      setCurrentTab((prevTab) => ({
        ...prevTab,
        child1: true,
        // child2: false, // Set child2 to false or adjust based on your requirement
      }));
    }
    // setUnsavedChanges(true);
    setUnsavedChanges((prevTab) => ({
      ...prevTab,
      outerClickChild1: true,
      // outerClickChild2: false,
    }));
  };

  const handleChange = (e) => {
    if (e.target.value === "addProduct") {
      setAddProductComponent(true);
      setBulkProductUploadComponent(false);
      setRadioValue("addProduct");
    } else if (e.target.value === "bulkProductUpload") {
      setBulkProductUploadComponent(true);
      setAddProductComponent(false);
      setRadioValue("bulkProductUpload");
      setCurrentTab((prevState) => ({
        ...prevState,
        // child1: false,
        child2: false,
      }));
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        // outerClickChild1: false,
        outerClickChild2: false,
      }));
    }
  };

  const removeId = (id) => {
    const updatedArray = state.facility_reaction_capability.filter(
      (item) => item !== id
    );
    setState({ ...state, facility_reaction_capability: updatedArray });
    formRef.current.setFieldsValue({
      ...state,
      facility_reaction_capability: updatedArray,
    });
  };

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const deleteOption = (id) => {
    dispatch(deleteReactionCapability(id));
    SuccessNotificationMsg(t("msg_delete_reaction_capability"));
    setDeleted(!deleted);
    removeId(id);
  };

  // Function to remove ID from array

  const handleSubmit = async () => {
    try {
      setBtnloading(true);
      if (facilityAPI) {
        await updateFacilityAPI({
          id: facility_type_id,
          manufacturing_capacity: state.manufacturing_capacity,
          total_qty: state.total_qty,
          facility_reaction_capability: state.facility_reaction_capability,
        });
        SuccessNotificationMsg(t("update_facility_api_successfull"));
        setBtnloading(false);
        setCurrentTab((prevTab) => ({
          ...prevTab,
          child1: false,
          //child3
          child3: false,
          // child2: false, // Set child2 to false or adjust based on your requirement
        }));
        // setUnsavedChanges(false);
        setUnsavedChanges((prevTab) => ({
          ...prevTab,
          outerClickChild1: false,
          // outerClickChild2: false,
        }));
      } else {
        dispatch(addFacilityAPI({ ...state }));
        SuccessNotificationMsg(t("add_facility_api_successfull"));
        setBtnloading(false);
        setCurrentTab((prevTab) => ({
          ...prevTab,
          child1: false,
          //child3 make false
          child3: false,
          // child2: false, // Set child2 to false or adjust based on your requirement
        }));
        // setUnsavedChanges(false);
        setUnsavedChanges((prevTab) => ({
          ...prevTab,
          outerClickChild1: false,
          // outerClickChild2: false,
        }));
      }
    } catch (err) {
      setBtnloading(false);
    }
  };

  return (
    <>
      <div className="graybox">
        <Form
          ref={formRef}
          onFinish={handleSubmit}
          autoComplete="off"
          onValuesChange={handleFormChange}
        >
          <Row gutter={[15]}>
            <Col xs={24} sm={12} lg={6}>
              <Form.Item
                name="manufacturing_capacity"
                label={t("pl_manufacturing_capacity_kl")}
                rules={[
                  {
                    required: true,
                    message: <Trans>{t("val_manufacturing_capacity")}</Trans>,
                  },
                  {
                    // pattern: /^[0-9]+$/,
                    pattern: /^[a-zA-Z0-9]+$/,
                    message: <Trans>{t("val_manufacturing_capacity")}</Trans>,
                  },
                ]}
              >
                <Input
                  placeholder={t("pl_manufacturing_capacity_kl")}
                  onChange={(value) =>
                    setState({
                      ...state,
                      manufacturing_capacity: value.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <Form.Item
                name="total_qty"
                label={t("total_quantity")}
                rules={[
                  {
                    required: true,
                    message: <Trans>{t("val_total_quantity")}</Trans>,
                  },
                  {
                    // pattern: /^[0-9]+$/,
                    pattern: /^[a-zA-Z0-9]+$/,
                    message: <Trans>{t("val_total_quantity")}</Trans>,
                  },
                ]}
              >
                <Input
                  placeholder={t("total_quantity")}
                  onChange={(value) =>
                    setState({
                      ...state,
                      total_qty: value.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} lg={8}>
              <Space size={[16, 16]} className="w-100">
                <div style={{ display: "inline-block" }} className="w-100">
                  <Form.Item
                    name="facility_reaction_capability"
                    label={t("pl_facility_reaction_capability")}
                    rules={[
                      {
                        required: true,
                        message: (
                          <Trans>{t("val_facility_reaction_capability")}</Trans>
                        ),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      showArrow
                      allowClear
                      mode="multiple"
                      maxTagCount={"responsive"}
                      className="container-dropdown"
                      popupClassName="dropdownCoin"
                      optionFilterProp="children"
                      placeholder={t("pl_facility_reaction_capability")}
                      onChange={(value) =>
                        handleSelectChange(
                          "facility_reaction_capability",
                          value
                        )
                      }
                    >
                      {reaction_capabilityList.map((field, key) => {
                        return (
                          <React.Fragment key={key}>
                            <Select.Option
                              key={key}
                              value={field.id}
                              label={field.reaction_capability}
                            >
                              <span>{field.reaction_capability}</span>
                              &nbsp;&nbsp;
                              <span
                                style={{ marginRight: "10px", float: "right" }}
                              >
                                {authUserData()?.group === "Company Admin" &&
                                  field.created_by ===
                                    authUserData()?.user_id && (
                                    <Popconfirm
                                      className="action"
                                      title={t("pop_title")}
                                      okText={t("pop_ok")}
                                      cancelText={t("pop_cancel")}
                                      onConfirm={(e) => {
                                        e.stopPropagation(); /* Add e.stopPropagation() */
                                        deleteOption(field.id);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </Popconfirm>
                                  )}
                                {authUserData()?.group === "Super Admin" && (
                                  <Popconfirm
                                    className="action"
                                    title={t("pop_title")}
                                    okText={t("pop_ok")}
                                    cancelText={t("pop_cancel")}
                                    onConfirm={(e) => {
                                      e.stopPropagation(); /* Add e.stopPropagation() */
                                      deleteOption(field.id);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Popconfirm>
                                )}
                              </span>
                            </Select.Option>
                          </React.Fragment>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <Tooltip title="If the reaction is not listed, add manually.">
                  <PlusCircleOutlined
                    className="plusfield"
                    onClick={() => setIsModalOpen(true)}
                  />
                </Tooltip>
              </Space>
            </Col>
            <Col xs={24} sm={12} lg={4} className="savebtn">
              <ButtonField
                type="primary"
                htmlType="submit"
                loading={btnLoading}
                label={t("save")}
              />
            </Col>
          </Row>
        </Form>

        <Modal
          title={t("pl_facility_reaction_capability")}
          open={isModalOpen}
          footer={null}
          onCancel={handleCancel}
          width={600}
          className="APImodel"
        >
          <ReactionCapability handleCancel={handleCancel} deleted={deleted} />
        </Modal>
      </div>
      {facility_type_id && (
        <>
          <div className="graybox">
            <Radio.Group
              onChange={handleChange}
              className="subradiowrap"
              defaultValue={"addProduct"}
              value={radioValue}
            >
              <Radio value={"addProduct"}>{t("addProduct1")}</Radio>
              {/* <Radio value={"bulkProductUpload"}>
                {t("bulkProductUpload")}
              </Radio> */}
            </Radio.Group>
            <p>{t("addProductDescription")}</p>
            {(addProductComponent || radioValue === "addProduct") && (
              <Row gutter={[15]}>
                <Col xs={24}>
                  <AddProduct
                    facility_type_id={facility_type_id}
                    setNewProductAdded={setNewProductAdded}
                    newProductAdded={newProductAdded}
                    setRadioValue={setRadioValue}
                    setCurrentTab={setCurrentTab}
                  />
                </Col>
              </Row>
            )}
            {bulkProductUploadComponent && (
              <Row gutter={[15]}>
                <Col xs={24}>
                  <BulkProductUpload
                    facility_id={props.facility_id}
                    facility_type_id={facility_type_id}
                    setNewProductAdded={setNewProductAdded}
                    newProductAdded={newProductAdded}
                    setCurrentTab={setCurrentTab}
                  />
                </Col>
              </Row>
            )}
          </div>

          <ProductList
            facility_id={props.facility_id}
            newProductAdded={newProductAdded}
            setRadioValue={setRadioValue}
            setBulkProductUploadComponent={setBulkProductUploadComponent}
            setCurrentTab={setCurrentTab}
          />
        </>
      )}
    </>
  );
};

ManageAddProduct.propTypes = {
  facility_id: PropTypes.any, // Adjust the type as per your needs
  setCurrentTab: PropTypes.func,
};

export default ManageAddProduct;
