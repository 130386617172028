import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Table, Popconfirm, Space, Form } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { getRequest } from "../../../../../axios";
import {
  deleteClassificationAPI,
  deleteClassificationFormulation,
  getClassificationAPIById,
  getClassificationAPICount,
  getClassificationFormulationById,
  getClassificationFormulationCount,
} from "./Plant&LabSlice";
import { SuccessNotificationMsg } from "../../../../../utils/Notifications";

const ClassificationList = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formRef = useRef();
  const { setCurrentTab } = props;

  const initialState = {
    page: 1,
    ordering: "id",
    pagesize: 5,
    search: "",
    total: 0,
  };
  const [state, setState] = useState(initialState);
  const [ClasssificationAPIList, setClassificationAPIList] = useState([]);
  const [ClasssificationFormulationList, setClassificationFormulationList] =
    useState([]);

  const [count, setCount] = useState();
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(false);

  let id, list;
  if (props.plant_lab_api_id) {
    id = props.plant_lab_api_id;
    list = ClasssificationAPIList;
  }
  if (props.plantLab_formulation_id) {
    id = props.plantLab_formulation_id;
    list = ClasssificationFormulationList;
  }

  useEffect(() => {
    try {
      setLoading(true);
      const getAllClassificationAsync = async (id, data) => {
        let apiResponse;
        if (props.plant_lab_api_id) {
          apiResponse = await getRequest(
            "facility/list-api-plant-lab-classification/" + id,
            data
          );
        }
        if (props.plantLab_formulation_id) {
          apiResponse = await getRequest(
            "facility/list-create-formulation-plant-lab-classification/" + id,
            data
          );
        }

        if (props.plant_lab_api_id) {
          setClassificationAPIList(apiResponse?.data?.results);
          setCount(apiResponse.data.count);
          dispatch(getClassificationAPICount(apiResponse?.data?.count));
        }
        if (props.plantLab_formulation_id) {
          setClassificationFormulationList(apiResponse?.data?.results);
          setCount(apiResponse.data.count);
          dispatch(getClassificationFormulationCount(apiResponse?.data?.count));
        }

        setLoading(false);

        if (apiResponse?.data?.results?.length === 0) {
          setCurrentTab((prevTab) => ({
            ...prevTab,
            child4: true,
          }));
        } else {
          setCurrentTab((prevTab) => ({
            ...prevTab,
            child4: false,
          }));
        }
      };
      getAllClassificationAsync(id, state);
    } catch (err) {
      setLoading(false);
    }
  }, [
    props.plant_lab_api_id,
    props.plantLab_formulation_id,
    props.newClassificationAdded,
    deleted,
    state,
    dispatch,
    id,
  ]);

  const handleTableChange = async (pagination, filters, sorter, ordering) => {
    const orderBy = GetSortingEnumValue(sorter.columnKey, sorter.order);
    setState({
      ...state,
      page: pagination.current,
      pagesize: pagination.pageSize,
      ordering: orderBy,
    });
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = "id";

    if (sortField === "no_of_classified_area" && sortOrder === "ascend") {
      sortingEnumKey = "no_of_classified_area";
    }
    if (sortField === "no_of_classified_area" && sortOrder === "descend") {
      sortingEnumKey = "-no_of_classified_area";
    }

    if (sortField === "classification" && sortOrder === "ascend") {
      sortingEnumKey = "classification";
    }
    if (sortField === "classification" && sortOrder === "descend") {
      sortingEnumKey = "-classification";
    }

    return sortingEnumKey;
  };

  const confirmDelete = async (id) => {
    if (props.plantLab_formulation_id) {
      await deleteClassificationFormulation(id);
    }
    if (props.plant_lab_api_id) {
      await deleteClassificationAPI(id);
    }
    SuccessNotificationMsg(t("msg_classificationDeleted"));
    setDeleted(!deleted);
  };

  const columns = [
    {
      title: "No of Classified Areas",
      dataIndex: "no_of_classified_area",
      key: "no_of_classified_area",
      sorter: true,
    },
    {
      title: "Classification",
      dataIndex: "classification",
      key: "classification",
      sorter: true,
    },
    {
      title: "Action",
      className: props.hideAction && "hide",
      width: "100",
      render: (e, record) => (
        <Space size={[12, 16]}>
          <Space size="middle">
            {props.plant_lab_api_id && (
              <EditOutlined
                onClick={() => dispatch(getClassificationAPIById(record.id))}
              />
            )}
            {props.plantLab_formulation_id && (
              <EditOutlined
                onClick={() =>
                  dispatch(getClassificationFormulationById(record.id))
                }
              />
            )}

            <Popconfirm
              className="action"
              title={t("pop_title")}
              okText={t("pop_ok")}
              cancelText={t("pop_cancel")}
              onConfirm={() => confirmDelete(record.id)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Form ref={formRef} autoComplete="off">
        <Table
          className="table_grid"
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={list}
          scroll={{ x: 500 }}
          loading={loading}
          pagination={{
            defaultCurrent: state.page,
            defaultPageSize: state.pagesize,
            total: count,
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
        />
      </Form>
    </>
  );
};

ClassificationList.propTypes = {
  plant_lab_api_id: PropTypes.number, // Assuming it's a number, adjust accordingly
  plantLab_formulation_id: PropTypes.number, // Assuming it's a number, adjust accordingly
  newClassificationAdded: PropTypes.bool,
  hideAction: PropTypes.bool,
  setCurrentTab: PropTypes.func,
};

export default ClassificationList;
