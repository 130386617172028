import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import InputField from "../../common/htmlComponents/InputField";
import TextAreaField from "../../common/htmlComponents/TextAreaField";
import ButtonField from "../../common/htmlComponents/ButtonField";
import { authUserData } from "../../utils/Helper";

const SendInquiry = ({
  to_email,
  sendInquiry,
  to_company,
  to_subject,
  to_details,
}) => {
  const { t } = useTranslation();
  const formRef = useRef();

  const initialState = {
    from_email: authUserData()?.email,
    subject: to_subject,
    details: to_details,
    to_email,
    to_company,
  };
  const [state, setState] = useState(initialState);
  const [btnLoading, setBtnloading] = useState(false);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      to_email,
      to_company,
      to_subject,
      to_details,
    }));
  }, [to_email, to_company, to_subject, to_details]);

  useEffect(() => {
    formRef.current.setFieldsValue({
      from_email: authUserData()?.email,
      subject: to_subject,
      details: to_details,
      to_email,
      to_company,
    });
  }, [to_email, to_company, to_subject, to_details, formRef]);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = async () => {
    setBtnloading(true);
    await sendInquiry(state);
    formRef.current.setFieldsValue(initialState);
    setBtnloading(false);
  };

  return (
    <>
      <Form
        onFinish={handleSubmit}
        autoComplete="off"
        ref={formRef}
        initialValues={initialState}
      >
        <Row gutter={[15]} className="sendinquiry">
          <Col xs={24}>
            <InputField
              label={t("sendinquiry_to_company")}
              placeholder={t("ph_sendinquiry_to_company")}
              name="to_company"
              value={initialState.to_company}
              readOnly
            />
          </Col>
          <Col xs={24}>
            <InputField
              label={t("sendinquiry_from_email")}
              placeholder={t("ph_sendinquiry_from_email")}
              name="from_email"
              value={initialState.from_email}
              readOnly
            />
          </Col>
          <Col xs={24}>
            <InputField
              label={t("sendinquiry_to_email")}
              placeholder={t("ph_sendinquiry_to_email")}
              name="to_email"
              value={initialState.to_email}
              readOnly
            />
          </Col>
          <Col xs={24}>
            <TextAreaField
              label={t("sendinquiry_subject")}
              placeholder={t("ph_sendinquiry_subject")}
              name="subject"
              rules={t("val_msg_sendinquiry_subject")}
              pattern={"^[a-zA-Z0-9s, '-/]*$"}
              handleInputChange={handleInputChange}
              value={initialState.subject}
              max={100}
            />
          </Col>
          <Col xs={24}>
            <TextAreaField
              label={t("sendinquiry_details")}
              placeholder={t("ph_sendinquiry_details")}
              name="details"
              rules={t("val_msg_sendinquiry_details")}
              pattern={"^[a-zA-Z0-9s, '-/]*$"}
              handleInputChange={handleInputChange}
              value={initialState.details}
              max={1000}
              rows={4}
            />
          </Col>
          <Col xs={24}>
            <ButtonField
              type="primary"
              htmlType="submit"
              loading={btnLoading}
              label={t("submit")}
              icon={<SendOutlined />}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

SendInquiry.propTypes = {
  to_email: PropTypes.string.isRequired,
  sendInquiry: PropTypes.func.isRequired,
  to_company: PropTypes.string,
  to_subject: PropTypes.string,
  to_details: PropTypes.string,
};

export default SendInquiry;
