import React, { useState } from "react";
import { Row, Col, Radio } from "antd"; //Flex is there inside antd for description bulk upload
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AddFormulation from "./AddFormulation";
import FormulationBulkUpload from "./FormulationBulkUpload";
import FormulationList from "./FormulationList";
// import DescriptionList from "./DescriptionList";
// import AddFormulationDescription from "./AddFormulationDescription";
// import ButtonField from "../../../../../../common/htmlComponents/ButtonField";
// import DescriptionBulkUpload from "./DescriptionBulkUpload";

const ManageAddFormulation = (props) => {
  const { t } = useTranslation();
  const { setCurrentTab } = props;
  const [newFormulationAdded, setNewFormulationAdded] = useState(false);
  // const [newFormulationDescriptionAdded, setNewFormulationDescriptionAdded] =
  //   useState(false);

  // const [deleted, setDeleted] = useState(false);

  const [addFormulationComponent, setAddFormulationComponent] =
    useState("addFormulation");
  const [formulationBulkUploadComponent, setFormulationBulkUploadComponent] =
    useState(false);
  const [radioValue, setRadioValue] = useState("addFormulation");

  const handleChange = (e) => {
    if (e.target.value === "addFormulation") {
      setAddFormulationComponent(true);
      setFormulationBulkUploadComponent(false);
      setRadioValue("addFormulation");
    } else if (e.target.value === "formulationBulkUpload") {
      setFormulationBulkUploadComponent(true);
      setAddFormulationComponent(false);
      setRadioValue("formulationBulkUpload");
      setCurrentTab((prevState) => ({
        ...prevState,
        child1: false,
        child2: false,
      }));
    }
  };

  // const [
  //   addFormulationDescriptionComponent,
  //   setAddFormulationDescriptionComponent,
  // ] = useState(false);
  // const [
  //   formulationDescriptionBulkUploadComponent,
  //   setFormulationDescriptionBulkUploadComponent,
  // ] = useState(true);

  // const handleDescriptionChange = () => {
  //   setAddFormulationDescriptionComponent(true);
  // };

  return (
    <>
      <div className="graybox">
        <Radio.Group
          onChange={handleChange}
          className="subradiowrap"
          defaultValue={"addFormulation"}
          value={radioValue}
        >
          <Radio value={"addFormulation"}>{t("addFormulation")}</Radio>
          <Radio value={"formulationBulkUpload"}>
            {" "}
            {t("bulkFormulationUpload")}
          </Radio>
        </Radio.Group>

        {(addFormulationComponent || radioValue === "addFormulation") && (
          <Row gutter={[15]}>
            <Col xs={24}>
              <AddFormulation
                facility_id={props.facility_id}
                setNewFormulationAdded={setNewFormulationAdded}
                newFormulationAdded={newFormulationAdded}
                setCurrentTab={setCurrentTab}
              />
            </Col>
          </Row>
        )}
        {formulationBulkUploadComponent && (
          <Row gutter={[15]}>
            <Col xs={24}>
              <FormulationBulkUpload
                facility_id={props.facility_id}
                setNewFormulationAdded={setNewFormulationAdded}
                newFormulationAdded={newFormulationAdded}
                setCurrentTab={setCurrentTab}
              />
            </Col>
          </Row>
        )}
        <FormulationList
          facility_id={props.facility_id}
          newFormulationAdded={newFormulationAdded}
          setFormulationBulkUploadComponent={setFormulationBulkUploadComponent}
          setRadioValue={setRadioValue}
          setCurrentTab={setCurrentTab}
        />
      </div>

      {/* {props.formulationMaster && (
        <div>
          <div className="graybox">
            <Row gutter={[15]}>
              {formulationDescriptionBulkUploadComponent && (
                <>
                  <Col xs={24} sm={12} lg={12}>
                    <DescriptionBulkUpload
                      facility_id={props.facility_id}
                      formulationMaster={props.formulationMaster}
                      setNewFormulationDescriptionAdded={
                        setNewFormulationDescriptionAdded
                      }
                      newFormulationDescriptionAdded={
                        newFormulationDescriptionAdded
                      }
                    />
                  </Col> */}

      {/* This code is older <Col xs={24} sm={12} lg={12}>
                    <div className="btnFormulationDescription">
                      <ButtonField
                        type="primary"
                        onClick={handleDescriptionChange}
                        label={t("addFormulationDescription")}
                      />
                    </div>
                  </Col> */}
      {/* </>
              )}
            </Row>
          </div> */}
      {/* <div className="graybox"> */}
      {/*This code is older <div className="btnFormulationDescription"> */}

      {/* <ButtonField
                type="primary"
              className="mb-5"
                onClick={handleDescriptionChange}
                label={t("addFormulationDescription")}
              /> */}

      {/* </div> */}
      {/* {addFormulationDescriptionComponent && (

              <AddFormulationDescription
                formulationMaster={props.formulationMaster}
                setNewFormulationDescriptionAdded={
                  setNewFormulationDescriptionAdded
                }
                newFormulationDescriptionAdded={
                  newFormulationDescriptionAdded
                }
                setAddFormulationDescriptionComponent={
                  setAddFormulationDescriptionComponent
                }
                setFormulationDescriptionBulkUploadComponent={
                  setFormulationDescriptionBulkUploadComponent
                }
              />

            )} */}

      {/* <DescriptionList
              formulationMaster={props.formulationMaster}
              newFormulationDescriptionAdded={newFormulationDescriptionAdded}
              setDeleted={setDeleted}
              deleted={deleted}
              setAddFormulationDescriptionComponent={
                setAddFormulationDescriptionComponent
              }
              setFormulationDescriptionBulkUploadComponent={
                setFormulationDescriptionBulkUploadComponent
              }
            />
          </div>
        </div>
      )} */}
    </>
  );
};

ManageAddFormulation.propTypes = {
  facility_id: PropTypes.string.isRequired,
  formulationMaster: PropTypes.number,
  setCurrentTab: PropTypes.func,
};

export default ManageAddFormulation;
