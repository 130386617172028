export const TRANSLATIONS_HD = {
  home: "Home",
  search: "खोज",
  ok: "ठीक",
  add: "जोड़ें",
  back: "पीछे",
  cancel: "रद्द करें",
  save: "सेव करें",
  edit: "संपादित करें",
  submit: "प्रस्तुत करें",
  print: "छापना",
  delete: "हटाना",
  login: "लॉगिन",
  keep_me_login: "मुझे इस साधन पर लॉग इन रखें",
  dont_have_acc: "अब तक कोई खाता नहीं है?",
  dont_verify_acc: "अभी तक खाते की पुष्टि नहीं कि?",
  go_to_app: "आवेदन पर जाएं",
  sign_up: "साइन अप करें",
  verify: "पुष्टि करें",
  reset: "फिर से स्थापित करें",
  register: "रजिस्टर करें",
  email: "ईमेल",
  forgot_password: "पासवर्ड भूल गए",
  registration: "पंजीकरण",
  i_accept_the: "मैं स्वीकारता हूँ",
  terms_and_conditions: "नियम और शर्तें",
  dashboard: "डैशबोर्ड",
  forgot_password_msg:
    "अपना ई-मेल पता दर्ज करें और हम आपको अपना पासवर्ड रीसेट करने के लिए एक लिंक भेजेंगे",
  create_new_password: "नया पासवर्ड बनाएं",
  verify_account: "खाते की जाँच",
  verify_account_msg:
    "कृपया सत्यापन कोड का उपयोग करके अपना ईमेल सत्यापित करें।",
  registration_successfull: "पंजीकरण सफल!",
  registration_successfull_msg:
    "ईमेल सत्यापन के लिए कृपया अपना पंजीकृत ईमेल देखें।",
  passsword_conditions:
    "पासवर्ड कम से कम छह अक्षर, कम से कम एक अक्षर और एक अंक और एक विशेष वर्ण का होना चाहिए।",
  password_notconfirm_msg: "आपके द्वारा दर्ज किए गए दो पासवर्ड मेल नहीं खाते!",


  pl_firstName: "पहला नाम",
  pl_middleName: "मध्य नाम",
  pl_lastName: "अंतिम नाम",
  pl_phone: "फ़ोन",
  pl_designation: "पद",
  pl_aadharcardNo: "आधार कार्ड नंबर",
  pl_password: "पासवर्ड",
  pl_confirm_password: "पासवर्ड की पुष्टि कीजिये",
  pl_email: "ई-मेल पता",
  pl_landline: "लैंडलाइन",
  pl_registrationNo: "पंजीकरण क्रमांक",
  pl_faxNumber: "फैक्स नंबर",
  pl_degree: "डिग्री",
  pl_speciality: "विशेषता",


  // Pop-ups

  pop_title: "क्या वाकई हटाना है?",
  pop_ok: "हां",
  pop_cancel: "नहीं",
};
