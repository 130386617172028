import { configureStore } from '@reduxjs/toolkit'
import authSlice from '../authentication/redux/authSlice'
import countrySlice from '../common/htmlComponents/CountryWithFlag/countrySlice'
import messageReducer from '../authentication/redux/msgSlice'
import ticketSlice from '../feature/ticket/ticketSlice'
import facilitySlice from '../feature/manufacturingFacility/facilities/facilitySlice'
import ManageFacilitySlice from '../feature/manufacturingFacility/ManageFacilitySlice'
import AddFacilityAPISlice from '../feature/manufacturingFacility/facilities/facilityType/api/redux/AddFacilityAPISlice'
import AddProductSlice from '../feature/manufacturingFacility/facilities/facilityType/api/redux/AddProductSlice'
import manageUserSlice from '../feature/manageUsers/manageUserSlice'
import PlantLabSlice from '../feature/manufacturingFacility/facilities/facilityType/plant&lab/Plant&LabSlice'
import UtilitiesSlice from '../feature/manufacturingFacility/facilities/facilityType/utilities/redux/UtilitiesSlice'
import PhotoGallerySlice from '../feature/manufacturingFacility/facilities/facilityType/photoGallery/PhotoGallerySlice'
import AddFormulationSlice from '../feature/manufacturingFacility/facilities/facilityType/formulation/addFormulation/AddFormulationSlice'
import progressSlice from '../feature/profile/progressSlice'
import BLPage from '../feature/businessLounge/BuisenessloungeSlice'

const store = configureStore({
    reducer: {
        users: authSlice,
        progressBar: progressSlice,
        message: messageReducer,
        country: countrySlice,
        tickets: ticketSlice,
        manageUser: manageUserSlice,
        facility: facilitySlice,
        allFacility: ManageFacilitySlice,
        facilityAPI: AddFacilityAPISlice,
        addProduct: AddProductSlice,
        plantLab: PlantLabSlice,
        UtilitiesCount: UtilitiesSlice,
        photoGallery: PhotoGallerySlice,
        addFormulation: AddFormulationSlice,
        BLPage: BLPage
    }
})
export default store