import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../../../../../axios";

export const getFormulationType = createAsyncThunk(
  "get_formulation_type",
  async () => {
    const response = await getRequest("formulation_type/create/");
    return response.data;
  }
);

export const addformulation = async (data) => {
  const response = await postRequest(
    `facility/list-create-formulations-product/${data.id}`,
    data
  );
  return response.data;
};

export const getFormulationById = createAsyncThunk(
  "get_formulation_by_id",
  async (id) => {
    const response = await getRequest(
      "facility/list-formulations-product/" + id
    );
    return response.data;
  }
);

export const getFormulationDescriptionById = createAsyncThunk(
  "get_formulationDescription_by_id",
  async (id) => {
    const response = await getRequest(
      "formulation_type/get-by-id-formulation-type-description/" + id
    );
    return response.data;
  }
);

export const getFormulationCount = createAsyncThunk(
  "get_formulation_count",
  async (count) => {
    return count;
  }
);

export const getFormulationMaster = createAsyncThunk(
  "get_formulation_masterId",
  async (id) => {
    return id;
  }
);

export const getFormulationPlantLabId = createAsyncThunk(
  "get_formulation_plantLabId",
  async (id) => {
    return id;
  }
);

export const getFormulationUtilityId = createAsyncThunk(
  "get_formulation_utilityId",
  async (id) => {
    return id;
  }
);

export const getFormulationWaterTreatmentId = createAsyncThunk(
  "get_formulation_WwaterTreatmentId",
  async (id) => {
    return id;
  }
);

export const getFormulationSubUtilityId = createAsyncThunk(
  "get_formulation_subutilityId",
  async (id) => {
    return id;
  }
);

export const updateFormulation = async (data) => {
  const response = await patchRequest(
    `facility/update-delete-formulations-product/${data.id}`,
    { ...data }
  );
  return response.data;
};

export const resetAfterUpdate = createAsyncThunk(
  "reset_afterUpdate",
  async (afterUpdate) => {
    return afterUpdate;
  }
);

export const resetAfterFacilityFormulation = createAsyncThunk(
  "reset_facility_formulation",
  async (reset) => {
    return reset;
  }
);

export const deleteFormulation = async (id) => {
  const response = await deleteRequest(
    "facility/update-delete-formulations-product/" + id
  );
  return response.data;
};

const initialState = {
  formulation: [],
  formulation_id: null,
  formulationDescription: [],
  formulationDescription_id: null,
  formulationCount: null,
  formulationTypeList: [],
  formulationMaster: null,
  formulationPlantLabId: null,
  formulationUtilityId: null,
  formulationWaterTreatmentId: null,
  formulationSubUtilityId: null,
};

const addFormulation = createSlice({
  name: "addFormulation",
  initialState,
  reducers: {},
  extraReducers: {
    [getFormulationCount.fulfilled]: (state, action) => {
      return { ...state, formulationCount: action.payload };
    },
    [getFormulationMaster.fulfilled]: (state, action) => {
      return { ...state, formulationMaster: action.payload };
    },
    [getFormulationPlantLabId.fulfilled]: (state, action) => {
      return { ...state, formulationPlantLabId: action.payload };
    },
    [getFormulationUtilityId.fulfilled]: (state, action) => {
      return { ...state, formulationUtilityId: action.payload };
    },
    [getFormulationWaterTreatmentId.fulfilled]: (state, action) => {
      return { ...state, formulationWaterTreatmentId: action.payload };
    },
    [getFormulationSubUtilityId.fulfilled]: (state, action) => {
      return { ...state, formulationSubUtilityId: action.payload };
    },

    [getFormulationType.fulfilled]: (state, action) => {
      return { ...state, formulationTypeList: action.payload };
    },

    // get formulation by id

    [getFormulationById.fulfilled]: (state, action) => {
      return {
        ...state,
        formulation: action.payload,
        formulation_id: action.payload.results[0].id,
      };
    },

    [getFormulationDescriptionById.fulfilled]: (state, action) => {
      return {
        ...state,
        formulationDescription: action.payload,
        formulationDescription_id: action.payload.data.id,
      };
    },

    // resetAfterUpdate

    [resetAfterUpdate.fulfilled]: (state, action) => {
      return {
        ...state,
        formulation: null,
        formulation_id: null,
        formulationDescription: null,
        formulationDescription_id: null,
      };
    },

    // resetAfterFacilityFormulation

    [resetAfterFacilityFormulation.fulfilled]: (state, action) => {
      state.formulation_id = null;
      state.formulationDescription_id = null;
      state.formulationCount = null;
      state.formulationTypeList = [];
      state.formulationMaster = null;
      state.formulationPlantLabId = null;
      state.formulationUtilityId = null;
      state.formulationWaterTreatmentId = null;
      state.formulationSubUtilityId = null;
    },
  },
});

export default addFormulation.reducer;
