import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Table, Row, Col } from "antd";
import { getManufacturingCapacity } from "./ManageFacilitySlice";

const APIcapacity = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const list = useSelector((state) => state.allFacility.mfgCapacityList);
  const apiLoading = useSelector((state) => state.allFacility.apiLoading);
  const mfgCapacityTotal = useSelector(
    (state) => state.allFacility.mfgCapacityTotal
  );
  const mfgCapacityList = list && list.results;

  const [state, setState] = useState({
    page: 1,
    ordering: "id",
    pagesize: 10,
    search: "",
    total: 0,
  });

  const handleTableChange = async (pagination, filters, sorter, ordering) => {
    const orderBy = GetSortingEnumValue(sorter.columnKey, sorter.order);

    setState((prevState) => ({
      ...prevState,
      page: pagination.current,
      pagesize: pagination.pageSize,
      ordering: orderBy,
    }));
    dispatch(
      getManufacturingCapacity({
        id: props.capacityId,
        page: pagination.current,
        ordering: orderBy,
        search: state.search,
        pagesize: pagination.pageSize,
      })
    );
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = "id";

    if (sortField === "product_name" && sortOrder === "ascend") {
      sortingEnumKey = "product_name";
    }
    if (sortField === "product_name" && sortOrder === "descend") {
      sortingEnumKey = "-product_name";
    }

    if (sortField === "cas_no" && sortOrder === "ascend") {
      sortingEnumKey = "cas_no";
    }
    if (sortField === "cas_no" && sortOrder === "descend") {
      sortingEnumKey = "-cas_no";
    }

    return sortingEnumKey;
  };
  const columns = [
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      sorter: true,
    },
    {
      title: "CAS No",
      dataIndex: "cas_no",
      key: "cas_no",
      sorter: true,
    },
    {
      title: "Therapeutic Segment",
      dataIndex: "therapeutic_list",
      key: "therapeutic_list",
      render: (therapeutic_list) =>
        therapeutic_list &&
        therapeutic_list.map((value) => value.therapeutic_segment).join(", "),
    },
    {
      title: "Information on Regulatory Approvals",
      dataIndex: "dmf_cep_list",
      key: "dmf_cep_list",
      render: (dmf_cep_list) =>
        dmf_cep_list && dmf_cep_list.map((value) => value.dmf_cep).join(", "),
    },
  ];

  return (
    <>
      <Row gutter={[15]} className="modelhead">
        <Col xs={24}>
          <label>{t("pl_manufacturing_capacity_kl")}: </label>
          {mfgCapacityTotal.map(function (cValue) {
            return cValue.manufacturing_capacity;
          })}
        </Col>
        <Col xs={24}>
          <label>{t("total_quantity")}: </label>
          {mfgCapacityTotal.map(function (cValue) {
            return cValue.total_qty;
          })}
        </Col>
      </Row>
      <Table
        className="table_grid"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={mfgCapacityList}
        scroll={{ x: 500 }}
        loading={apiLoading}
        pagination={{
          defaultCurrent: state.page,
          defaultPageSize: state.pagesize,
          total: list.count,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

APIcapacity.propTypes = {
  capacityId: PropTypes.string, // Add this line
};

export default APIcapacity;
