import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../../../../../axios";

export const getUtilities = createAsyncThunk("get_Utilitiesvalue", async (id) => {
    const response = await getRequest("facility/create-facility-utility/" + id);
    return response.data;
});

export const getUtilityCount = createAsyncThunk("get_utility_count", async (count) => {
    return count;
});

const initialState = {
    utilityCount: null,
    utilityFormulationCount: null,
    utilitiesSystem: null
}

const UtilitiesCount = createSlice({
    name: "UtilitiesCount",
    initialState,
    reducers: {},
    extraReducers: {

        [getUtilityCount.fulfilled]: (state, action) => {
            return { ...state, utilityCount: action.payload };
        },

        [getUtilities.fulfilled]: (state, action) => {
            return { ...state, utilityCount: action.payload.data.utility_count };
        }
    }
});

export default UtilitiesCount.reducer
