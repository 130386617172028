import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Breadcrumb, Tabs, Tooltip } from "antd";
import { Link } from "react-router-dom";
import WebsiteUserNewsletter from "./WebsiteUserNewsletter";
import RegisteredUserNewsletter from "./RegisteredUserNewsletter";

const UnsubscribedNewsletter = () => {
  const { t } = useTranslation();

  const items = [
    {
      key: "1",
      label: <Trans> {t("registered_user_newsletter")}</Trans>,
      children: <RegisteredUserNewsletter />,
    },
    {
      key: "2",
      label: <Trans> {t("website_user_newsletter")}</Trans>,
      children: <WebsiteUserNewsletter />,
    },
  ];
  const colors = [
    '#D9E0E2'
  ];
  return (
    <>
      <div className="contentpart">
        <div className="right_content_col">
          <div className="content_wraper ">
            <div className="myorder">
              <div className="pagename">
                <Tooltip
                  color={colors}
                  title={<span style={{ color: '#000000' }}>Opt out of receiving newsletters to manage your email preferences.</span>}
                >
                  {t("unsubscribed_newsletter")}
                </Tooltip>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/dashboard">{t("home")}</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {t("unsubscribed_newsletter")}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <br />

              <Tabs defaultActiveKey="1" items={items} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnsubscribedNewsletter;
