import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteRequest, getRequest } from "../../axios";

export const getManufacturingCapacityTotal = createAsyncThunk("get_manufacturing_capacity_total", async (id) => {
    const response = await getRequest("facility/manage-facility-api/" + id);
    return response.data;
});

export const getManufacturingCapacity = createAsyncThunk("get_manufacturing_capacity", async (data) => {
    const response = await getRequest(`facility/list-create-api-product/${data.id}`, {
        ordering: data.ordering, search: data.search,
        page: data.page,
        pagesize: data.pagesize
    }
    );
    return response.data;
});

export const getManufacturingFormulation = createAsyncThunk("get_manufacturing_formulation", async (data) => {
    const response = await getRequest(`facility/list-create-formulations-product/${data.id}`, {
        ordering: data.ordering, search: data.search,
        page: data.page,
        pagesize: data.pagesize
    });
    return response.data;
});

export const deleteFacility = async (id) => {
    const response = await deleteRequest("facility/update-delete/" + id);
    return response.data;
}


const initialState = {
    apiLoading: false,
    error: "",
    type: "",
    mfgCapacityTotal: [],
    mfgCapacityList: [],
    mfgFormulationList: []
}

const allFacility = createSlice({
    name: "allFacility",
    initialState,
    reducers: {},
    extraReducers: {


        // get Manufacturing Capacity Total

        [getManufacturingCapacityTotal.pending]: (state, action) => {
            return { ...state, apiLoading: true };
        },
        [getManufacturingCapacityTotal.fulfilled]: (state, action) => {
            return { ...state, apiLoading: false, mfgCapacityTotal: action.payload };
        },
        [getManufacturingCapacityTotal.rejected]: (state, action) => {
            return { ...state, apiLoading: false };
        },

        // get Manufacturing Capacity

        [getManufacturingCapacity.pending]: (state, action) => {
            return { ...state, apiLoading: true };
        },
        [getManufacturingCapacity.fulfilled]: (state, action) => {
            return { ...state, apiLoading: false, mfgCapacityList: action.payload };
        },
        [getManufacturingCapacity.rejected]: (state, action) => {
            return { ...state, apiLoading: false };
        },

        // get Manufacturing Formulation

        [getManufacturingFormulation.pending]: (state, action) => {
            return { ...state, apiLoading: true };
        },
        [getManufacturingFormulation.fulfilled]: (state, action) => {
            return { ...state, apiLoading: false, mfgFormulationList: action.payload };
        },
        [getManufacturingFormulation.rejected]: (state, action) => {
            return { ...state, apiLoading: false };
        }
    }
});

export default allFacility.reducer
