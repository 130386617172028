import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, patchRequest, postRequest } from "../../axios";

export const getManageUsers = createAsyncThunk("all_users", async (state) => {
    const response = await getRequest("user/get", state);
    return response.data
});

export const addUser = createAsyncThunk("add_user", async (state) => {
    const response = await postRequest("user/create-user/", state);
    return response.data
});

export const updateActiveStatus = createAsyncThunk("update_active_status", async (state) => {
    const response = await patchRequest("company/activate-user-send-credentials/", state);
    return response.data
});

export const updateInActiveStatus = createAsyncThunk("update_inactive_status", async (id) => {
    const response = await deleteRequest("user/inactive-user/" + id);
    return response.data
});


const initialState = {
    loading: false,
    btnLoading: false,
    error: "",
    userList: []
}
const manageUser = createSlice({
    name: "manageUser",
    initialState,
    reducers: {},

    extraReducers: {
        [getManageUsers.pending]: (state) => {
            state.loading = true;
        },
        [getManageUsers.fulfilled]: (state, action) => {
            return { ...state, loading: false, userList: action.payload };
        },
        [getManageUsers.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        // add user

        [addUser.pending]: (state) => {
            state.btnLoading = true;
        },
        [addUser.fulfilled]: (state, action) => {
            state.btnLoading = false;
            state.type = action.type;
        },
        [addUser.rejected]: (state, action) => {
            state.btnLoading = false;
            state.error = action.payload;
        },

        // update active status

        [updateActiveStatus.pending]: (state) => {
            state.btnLoading = true;
        },
        [updateActiveStatus.fulfilled]: (state, action) => {
            state.btnLoading = false;
            state.type = action.type;
        },
        [updateActiveStatus.rejected]: (state, action) => {
            state.btnLoading = false;
            state.error = action.payload;
        },

        // update inactive status

        [updateInActiveStatus.pending]: (state) => {
            state.btnLoading = true;
        },
        [updateInActiveStatus.fulfilled]: (state, action) => {
            state.btnLoading = false;
            state.type = action.type;
        },
        [updateInActiveStatus.rejected]: (state, action) => {
            state.btnLoading = false;
            state.error = action.payload;
        },

    },
});

export default manageUser.reducer