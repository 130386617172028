import React, { useEffect, useState } from "react";
import { Row, Col, Space, Table, Input, Popconfirm, Button } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { CSVLink } from "react-csv";
import "../../../styles/Main.scss";
import "antd/dist/antd.css";
import { deleteRequest, getRequest } from "../../../axios";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../../utils/Notifications";

const { Search } = Input;

const WebsiteUserNewsletter = () => {
  const { t } = useTranslation();

  const [websiteuserList, setWebsiteuserList] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [apiLoading, setapiLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const initialState = {
    page: 1,
    ordering: "-id",
    pagesize: 10,
    search: "",
    total: 0,
  };
  const [state, setState] = useState(initialState);

  const headers = [{ label: "Email", key: "email" }];
  const csvReport = {
    data: websiteuserList,
    headers: headers,
    filename: "Unsubscribed_Website_User_Report.csv",
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = "-id";

    if (sortField === "email" && sortOrder === "ascend") {
      sortingEnumKey = "email";
    }
    if (sortField === "email" && sortOrder === "descend") {
      sortingEnumKey = "-email";
    }
    if (sortField === "unsubscribe_reason" && sortOrder === "ascend") {
      sortingEnumKey = "unsubscribe_reason";
    }
    if (sortField === "unsubscribe_reason" && sortOrder === "descend") {
      sortingEnumKey = "-unsubscribe_reason";
    }

    return sortingEnumKey;
  };

  const handleTableChange = async (pagination, filters, sorter, ordering) => {
    const orderBy = GetSortingEnumValue(sorter.columnKey, sorter.order);
    setState({
      ...state,
      page: pagination.current,
      pagesize: pagination.pageSize,
      ordering: orderBy,
    });
  };

  useEffect(() => {
    const getAllWebsiteUserNewsletterList = async (data) => {
      await getRequest(
        "websitenewsletter/get-website-newsletter-user-unsubscribe/",
        data
      )
        .then((response) => {
          setWebsiteuserList(response.data.results);
          settotalCount(response.data.count);
        })
        .catch((error) => {
          ErrorNotificationMsg(error);
        });
    };
    try {
      setapiLoading(true);
      getAllWebsiteUserNewsletterList(state);
      setapiLoading(false);
    } catch (error) {
      setapiLoading(false);
      ErrorNotificationMsg(error);
    }
  }, [state, reload]);

  const searchCallbackDelayed = (event) => {
    const { value } = event.target;
    debounceLoadData(value, onSearch);
  };

  const onSearch = (value) => {
    setState({
      ...state,
      search: value,
    });
  };

  const debounceLoadData = debounce((value, onSearch) => {
    onSearch(value);
  }, 1000);
  const confirmDelete = async (id) => {
    await deleteRequest(
      "websitenewsletter/unsubscribe-website-newsletter-user/" + id
    ).then((response) => {
      SuccessNotificationMsg(t("Deleted Successfully"));
      setReload(!reload);
    });
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Reason For Unsubscribe ",
      dataIndex: "unsubscribe_reason",
      key: "unsubscribe_reason",
      sorter: true,
    },

    {
      title: "Action",
      render: (e, record) => (
        <Space size={[12, 16]}>
          <Space size="middle">
            <Popconfirm
              className="action"
              title={t("pop_title")}
              okText={t("pop_ok")}
              cancelText={t("pop_cancel")}
              onConfirm={() => confirmDelete(record.id)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="heading">
        <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }} align="middle">
          <Col flex="auto" align="center">
            <Space>
              <Button
                type="primary"
                htmlType="button"
                size="small"
                icon={<DownloadOutlined />}
              >
                <CSVLink {...csvReport} style={{ color: "#fff" }}>
                  {" "}
                  &nbsp; {t("download")} &nbsp;
                </CSVLink>
              </Button>
              <div className="searchwrp">
                <Search
                  placeholder={"search"}
                  onChange={searchCallbackDelayed}
                />
              </div>
            </Space>
          </Col>
        </Row>
      </div>
      <Table
        className="table_grid"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={websiteuserList}
        scroll={{ x: 970 }}
        loading={apiLoading}
        pagination={{
          defaultCurrent: state.page,
          defaultPageSize: state.pagesize,
          total: totalCount,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default WebsiteUserNewsletter;
