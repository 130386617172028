import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { useEffect } from "react";

export const authUserData = () => {
  let authData = localStorage.getItem("auth_data");
  return JSON.parse(authData);
};
export const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const convertNulltoString = (text) => {
  if (text === null || text === undefined) {
    return "";
  }
  return text;
};

export const redirectIfLoggedIn = () => {
  let token = localStorage.getItem("auth_token");
  let authData = authUserData();

  if (
    token !== null &&
    token !== undefined &&
    authData !== null &&
    authData !== undefined
  ) {
    window.location.href = "/business-lounge";
  }
};

export const redirectIfNotLoggedIn = () => {
  let token = localStorage.getItem("auth_token");
  let authData = authUserData();

  if (authData === null || token === "") {
    window.location.href = "/login";
  }
};

export const SuperAdmin = authUserData()?.group === "Super Admin";
export const SubAdmin = authUserData()?.group === "Sub Admin";
export const CompanyAdmin = authUserData()?.group === "Company Admin";

export const SuperAdminOrSubAdmin =
  authUserData()?.group === "Super Admin" ||
  authUserData()?.group === "Sub Admin";
export const CompanyAdminOrSubAdmin =
  authUserData()?.group === "Company Admin" ||
  authUserData()?.group === "Sub Admin";
export const SuperAdminOrCompanyAdmin =
  authUserData()?.group === "Super Admin" ||
  authUserData()?.group === "Company Admin";
export const AllAdmin =
  authUserData()?.group === "Super Admin" ||
  authUserData()?.group === "Sub Admin" ||
  authUserData()?.group === "Company Admin";

export const LogoutHandler = () => {
  useEffect(() => {
    const cleanUp = () => {
      // sessionStorage.setItem("closedTab", "true");
      localStorage.clear();
    };
    window.addEventListener("beforeunload", cleanUp);
    return () => {
      window.removeEventListener("beforeunload", cleanUp);
    };
  }, []);
  return null; // This component doesn't render anything to the UI
};

export const SpinLoading = () => {
  return (
    <div className="custom-loading">
      <Spin spinning={true} />
    </div>
  );
};
