import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Row, Col, Input, Space, Table, Select } from "antd";
import { debounce } from "lodash";
import { SendOutlined } from "@ant-design/icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../styles/Main.scss";
import "antd/dist/antd.css";
import { getRequest, patchRequest } from "../../axios";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/Notifications";

const { Search } = Input;
const { Option } = Select;

const WebsiteRequestedConcierge = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [websiteUserList, setWebsiteuserList] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [reloaded, setReloaded] = useState(false);

  const [state, setState] = useState({
    page: 1,
    ordering: "id",
    pagesize: 10,
    search: "",
    total: 0,
  });

  useEffect(() => {
    const getAllWebsiteConcierge = async (data) => {
      try {
        setLoading(true);
        const apiResponse = await getRequest(
          "concierge/request-for-price-website/",
          data
        );
        if (apiResponse.data) {
          setWebsiteuserList(apiResponse.data.results);
          settotalCount(apiResponse.data.count);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        ErrorNotificationMsg(error);
      }
    };

    getAllWebsiteConcierge(state);
  }, [state, reloaded]);

  const handleTableChange = async (pagination, filters, sorter, ordering) => {
    const orderBy = GetSortingEnumValue(sorter.columnKey, sorter.order);
    setState({
      ...state,
      page: pagination.current,
      pagesize: pagination.pageSize,
      ordering: orderBy,
    });
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = "id";

    if (sortField === "id" && sortOrder === "ascend") {
      sortingEnumKey = "id";
    }
    if (sortField === "id" && sortOrder === "descend") {
      sortingEnumKey = "-id";
    }
    if (sortField === "concierge" && sortOrder === "ascend") {
      sortingEnumKey = "concierge";
    }
    if (sortField === "concierge" && sortOrder === "descend") {
      sortingEnumKey = "-concierge";
    }
    if (sortField === "company" && sortOrder === "ascend") {
      sortingEnumKey = "company__company_name";
    }
    if (sortField === "company" && sortOrder === "descend") {
      sortingEnumKey = "-company__company_name";
    }

    if (sortField === "name" && sortOrder === "ascend") {
      sortingEnumKey = "name";
    }
    if (sortField === "name" && sortOrder === "descend") {
      sortingEnumKey = "-name";
    }

    if (sortField === "concierge_name" && sortOrder === "ascend") {
      sortingEnumKey = "concierge_name";
    }
    if (sortField === "concierge_name" && sortOrder === "descend") {
      sortingEnumKey = "-concierge_name";
    }

    if (sortField === "email" && sortOrder === "ascend") {
      sortingEnumKey = "email";
    }
    if (sortField === "email" && sortOrder === "descend") {
      sortingEnumKey = "-email";
    }

    if (sortField === "contact_no" && sortOrder === "ascend") {
      sortingEnumKey = "contact_no";
    }
    if (sortField === "contact_no" && sortOrder === "descend") {
      sortingEnumKey = "-contact_no";
    }

    if (sortField === "status" && sortOrder === "ascend") {
      sortingEnumKey = "status";
    }
    if (sortField === "status" && sortOrder === "descend") {
      sortingEnumKey = "-status";
    }

    if (sortField === "date_of_request" && sortOrder === "ascend") {
      sortingEnumKey = "date_of_request";
    }
    if (sortField === "date_of_request" && sortOrder === "descend") {
      sortingEnumKey = "-date_of_request";
    }
    return sortingEnumKey;
  };

  const searchCallbackDelayed = (event) => {
    const { value } = event.target;
    debounceLoadData(value, onSearch);
  };

  const onSearch = (value) => {
    setState({
      ...state,
      search: value,
    });
  };

  const debounceLoadData = debounce((value, onSearch) => {
    onSearch(value);
  }, 1000);
  const func1 = () => {
    navigate("/requested-concierge-service");
  };
  const func2 = () => {
    setReloaded(!reloaded);
  };

  const handleStatus = async (request_id, status) => {
    confirmAlert({
      title: t("confirmAlert_title"),
      message: t("confirmAlert_message"),
      buttons: [
        {
          label: t("confirmAlert_ok"),
          onClick: () => updateStatus(request_id, status),
        },
        {
          label: t("confirmAlert_cancel"),
          onClick: () => {
            func1();
            func2();
          },
        },
      ],
    });
  };

  const updateStatus = async (id, value) => {
    await patchRequest(`concierge/request-for-price-website-update/${id}`, {
      status: value,
    })
      .then((response) => {
        setReloaded(!reloaded);
        SuccessNotificationMsg(t("msg_requestUpdated"));
      })
      .catch((error) => {
        ErrorNotificationMsg(error);
      });
  };

  const columns = [
    {
      title: "Concierge Type",
      dataIndex: "concierge",
      key: "concierge",
      sorter: true,
    },
    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
      sorter: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Contact No",
      dataIndex: "contact_no",
      key: "contact_no",
      sorter: true,
    },
    {
      title: "Date Of Request",
      dataIndex: "date_of_request",
      key: "date_of_request",
      sorter: true,
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (e, res) => (
        <>
          <Select
            className="gridselect"
            onChange={(e) => handleStatus(res.id, e)}
            value={e ? e : res.status}
            defaultValue={e}
          >
            <Option value="responded">
              <div className="active">Responded</div>
            </Option>
            <Option value="pending">
              <div className="pending">Pending</div>
            </Option>
          </Select>
        </>
      ),
      sorter: true,
    },
    {
      title: "Action",
      render: (res) => (
        <Space size={[12, 16]}>
          <Space size="middle">
            <a href={`mailto:${res.email}`}>
              <SendOutlined style={{ color: "black" }} />
            </a>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="heading">
        <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }} align="middle">
          <Col flex="auto" align="center">
            <Space>
              <div className="searchwrp">
                <Search
                  placeholder={"search"}
                  onChange={searchCallbackDelayed}
                />
              </div>
            </Space>
          </Col>
        </Row>
      </div>
      <Table
        className="table_grid"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={websiteUserList}
        scroll={{ x: 970 }}
        loading={loading}
        pagination={{
          defaultCurrent: state.page,
          defaultPageSize: state.pagesize,
          total: totalCount,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default WebsiteRequestedConcierge;
