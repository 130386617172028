import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../../axios";
import { ErrorNotificationMsg } from "../../../utils/Notifications";
// import { useNavigate } from "react-router-dom";
// const navigate = useNavigate();

// Get Country

export const getAllCountry = createAsyncThunk("get_all_country", async () => {
  const countryListResponse = await getRequest("country/list/");
  return countryListResponse.data;
});

//Subscription Active validation
// export const getAllCountry = createAsyncThunk(
//   "get_all_country",
//   async (_, { rejectWithValue }) => {
//     try {
//       const countryListResponse = await getRequest("country/list/");

//       // Check if response indicates no active subscription
//       if (
//         countryListResponse.data.success === false &&
//         countryListResponse.data.message === "No active subscription found."
//       ) {
//         // Redirect to "concierge/" page
//         // localStorage.clear();

//         window.location.href = window.location.origin + "/concierge";

//         // window.location.href = window.location.origin + "/concierge-service";
//         // navigate("/concierge");
//         return rejectWithValue("No active subscription found.");
//         // This message can be used for error handling in reducers
//       }

//       // Return data if no redirection is needed
//       return countryListResponse.data;
//     } catch (error) {
//       return rejectWithValue(error.message); // Handle any other errors
//     }
//   }
// );

const initialState = {
  loading: false,
  error: "",
  countryList: [],
};
const country = createSlice({
  name: "country",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllCountry.pending]: (state) => {
      state.loading = true;
    },
    [getAllCountry.fulfilled]: (state, action) => {
      return { ...state, countryList: action.payload };
    },
    [getAllCountry.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default country.reducer;
