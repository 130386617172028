import React from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import { Form, Select } from "antd";
import { useSelector } from "react-redux";

const CountryWithFlag = (props) => {
  let fieldRequired = false;

  if (props.rules) {
    fieldRequired = true;
  }
  const countryList = useSelector((state) => state.country.countryList);

  return (
    <>
      <Form.Item
        name={props.name}
        label={props.label}
        rules={[
          {
            required: fieldRequired,
            message: <Trans>{props.rules}</Trans>,
          },
        ]}
      >
        <Select
          showSearch
          showArrow
          allowClear={props.allowClear ?? true}
          mode={props.mode}
          placeholder={props.placeholder}
          maxTagCount={"responsive"}
          className="container-dropdown country"
          popupClassName="dropdownCoin"
          onChange={(value) => props.handleSelectChange(props.name, value)}
          optionFilterProp="children"
          value={props.value}
        >
          {countryList.map((field, key) => {
            return (
              <React.Fragment key={key}>
                <Select.Option key={key} value={field.id} label={field.name}>
                  <div className="selectitem" style={{ width: "80%" }}>
                    <div className="flag">
                      <img src={field.country_flag} alt="" />
                    </div>
                  </div>

                  {field.name}
                </Select.Option>
              </React.Fragment>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};

CountryWithFlag.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.string,
  allowClear: PropTypes.bool,
  mode: PropTypes.string,
  placeholder: PropTypes.string,
  maxTagCount: PropTypes.number,
  handleSelectChange: PropTypes.func.isRequired,
  value: PropTypes.any,
};

export default CountryWithFlag;
