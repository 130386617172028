import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Modal,
  Button,
  Space,
  Pagination,
  Spin,
  Tooltip,
} from "antd";
import check from "../../images/check.png";
import SendInquiry from "../businessLounge/SendInquiry";
import "../../styles/Main.scss";
import "antd/dist/antd.css";
import "react-tooltip/dist/react-tooltip.css";
import dummyCert from "../../images/checked.png";
import { getRequest, postRequest } from "../../axios";
import FormulationList from "../manufacturingFacility/facilities/facilityType/formulation/addFormulation/FormulationList";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/Notifications";
import config from "../../Config";

const ShortlistedCompanyList = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const resetPage = useSelector((state) => state.BLPage.resetPage);

  const [isModalOpenc, setIsModalOpenc] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isModalOpenb, setIsModalOpenb] = useState(false);
  const [isModalOpenCountry, setisModalOpenCountry] = useState(false);
  const [isModalApproval, setisModalApproval] = useState(false);
  const [Countries, setCountries] = useState([]);
  const [Approvals, setApprovals] = useState([]);
  const [facilityId, setFacilityId] = useState(undefined);

  const initialState = {
    page: 1,
    ordering: "id",
    pagesize: 10,
    search: "",
    total: 0,
  };
  const [state, setState] = useState(initialState);
  const [facilityList, setFacilityList] = useState([]);
  const [totalFacilityCount, settotalFacilityCount] = useState(0);
  const [to_email, setto_email] = useState("");
  const [count, setCount] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [loading, setloading] = useState(false);

  // useEffect(() => {
  //   setloading(true);
  //   const getAllAPIFacilities = async (data) => {
  //     await getRequest(`facility/get-business-lounge/?shortlisted=true`, {
  //       ...data,
  //       page: resetPage ? 1 : data.page,
  //     })
  //       .then((response) => {
  //         if (response.status === 200 && data) {
  //           setFacilityList(response.data.results);
  //           settotalFacilityCount(response.data.count);
  //           setCount(response.data.results.length);
  //           setloading(false);
  //         }
  //       })
  //       .catch((error) => {
  //         ErrorNotificationMsg(error);
  //         setloading(false);
  //       });
  //   };
  //   try {
  //     if (resetPage) {
  //       setcurrentpage(1);
  //     }
  //     getAllAPIFacilities(state);
  //   } catch (error) {
  //     ErrorNotificationMsg(error);
  //   }
  // }, [state, checked, resetPage]);
  useEffect(() => {
    setloading(true);

    const getAllAPIFacilities = async (data) => {
      try {
        const response = await getRequest(
          `facility/get-business-lounge/?shortlisted=true`,
          {
            ...data,
            page: resetPage ? 1 : data.page,
          }
        );

        // Check if response indicates no active subscription
        if (
          response?.data?.success === false &&
          response?.data?.message === "No active subscription found."
        ) {
          // Redirect to "concierge/" page

          navigate("/concierge"); // You need to implement this function
          return; // Exit the function to prevent further processing
        }
        if (response?.status === 200 && data) {
          setFacilityList(response.data.results);
          settotalFacilityCount(response.data.count);
          setCount(response.data.results.length);
          setloading(false);
        }
      } catch (error) {
        ErrorNotificationMsg(error);
        setloading(false);
      }
    };

    try {
      if (resetPage) {
        setcurrentpage(1);
      }

      getAllAPIFacilities(state);
    } catch (error) {
      ErrorNotificationMsg(error);
    }
  }, [state, checked, resetPage]);

  const handleTableChange = async (page, pageSize) => {
    setcurrentpage(page);
    setState({
      ...state,
      page: page,
      pagesize: pageSize,
    });
  };

  const showModalc = async (to_email) => {
    setto_email(to_email);
    setIsModalOpenc(true);
  };

  const SendInquiy = async (data) => {
    await postRequest("inquiry/send-inquiry/", data)
      .then((response) => {
        if (response.status) {
          SuccessNotificationMsg(t("msg_sent_inquiry"));
        }
      })
      .catch((error) => {});
    handleCancelc();
  };

  const toggleChecked = async (id, value) => {
    await postRequest("facility/list-create-favorite/" + id, {
      shortlisted: value ? "True" : "False",
    })
      .then((response) => {
        // debugger;
        if (response.status) {
          setCount(count - 1);
          if (response.data.data.shortlisted === false) {
            SuccessNotificationMsg(t("msg_facility_unlike"), "top");
          }
          if (count - 1 === 0 && state.page > 1) {
            setcurrentpage(state.page - 1);
            setState({
              ...state,
              page: state.page - 1,
            });
          }
        }
      })
      .catch((error) => {});
    setChecked(!checked);
  };

  const handleCancelc = () => {
    setIsModalOpenc(false);
  };

  const handleCancelb = () => {
    setIsModalOpenb(false);
  };

  const openFormulationCapcity = (id) => {
    setFacilityId(id);
    setIsModalOpenb(true);
  };

  const openCountries = (countries) => {
    setCountries(countries);
    setisModalOpenCountry(true);
  };

  const handleCancelCountries = () => {
    setisModalOpenCountry(false);
  };

  const openApprovals = (approvals) => {
    setApprovals(approvals);
    setisModalApproval(true);
  };

  const handleCancelApprovals = () => {
    setisModalApproval(false);
  };

  const colors = ["#D9E0E2"];

  return (
    <>
      <Spin spinning={loading}>
        <Row gutter={[15]} className="loungerow">
          {facilityList &&
            facilityList.map((facility) => (
              <Col xs={24} sm={12} md={12} lg={8} xxl={6} key={facility.id}>
                <div className="loungebox">
                  <div className="loungeshadow"></div>
                  <div className="loungecard">
                    <div className="loungetitle">
                      <h3>{facility.company}</h3>
                      <h6>{facility.facility_location}</h6>
                      <div className="countrywrp">
                        <div className="countrytag">
                          <div className="imgwrp">
                            <img
                              src={facility.facility_country_flag}
                              alt={facility.facility_country}
                            />
                          </div>
                          {facility.facility_country}
                        </div>
                        <Button
                          size="small"
                          onClick={(e) =>
                            toggleChecked(facility.id, !facility.liked)
                          }
                        >
                          <Tooltip
                            color={colors}
                            title={
                              <span style={{ color: "#000000" }}>
                                {"Shortlisted"}
                              </span>
                            }
                          >
                            <img src={check} alt="check" />
                          </Tooltip>
                        </Button>
                      </div>
                    </div>
                    <div className="loungecont">
                      <ul>
                        <li>
                          <label>{t("facilityType")}</label>
                          {facility.facility_type}
                        </li>
                        {(facility.facility_type === "API" ||
                          facility.facility_type === "CDMO") && (
                          <li>
                            <label>{t("manufacturing_capacity")} in KL</label>
                            {facility.facility_api?.manufacturing_capacity} KL
                          </li>
                        )}
                        {(facility.facility_type === "API" ||
                          facility.facility_type === "CDMO") && (
                          <li>
                            <label>{t("pl_total_quantity_mt")}</label>
                            {facility.facility_api?.total_qty} MT / month
                          </li>
                        )}
                        {/* {(facility.facility_type === "Formulation" ||
                                  facility.facility_type === "CDMO") && (
                                  <li>
                                    <label>Formulation Capacity</label>
                                    TBD units/day
                                  </li>
                                )} */}
                        {(facility.facility_type === "Formulation" ||
                          facility.facility_type === "CDMO") && (
                          <li>
                            <Row gutter={[15]} className="cardrowhead">
                              <Col xs={12}>{t("pl_type_of_formulation")}</Col>
                              <Col xs={12}>{t("pl_monthly_capacity")}</Col>
                            </Row>
                            {facility.formulation_data?.formulation
                              ?.formulation_list?.length > 0 &&
                              facility.formulation_data.formulation.formulation_list.map(
                                (formulationCapacity) => (
                                  <Row
                                    gutter={[15]}
                                    className="cardrowvalue"
                                    key={formulationCapacity.id}
                                  >
                                    <Col xs={12}>
                                      {formulationCapacity.formulation_type}
                                    </Col>
                                    <Col xs={12}>
                                      {formulationCapacity.monthly_capacity}
                                    </Col>
                                  </Row>
                                )
                              )}
                          </li>
                        )}
                        {(facility.facility_type === "Formulation" ||
                          facility.facility_type === "CDMO") &&
                          facility.formulation_data?.formulation_list_count >
                            5 && (
                            <li>
                              <div className="morelink">
                                <button
                                  onClick={(e) =>
                                    openFormulationCapcity(facility.id)
                                  }
                                  style={{
                                    background: "none",
                                    border: "none",
                                    padding: 0,
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    color: "#022f4",
                                  }}
                                >
                                  {t("more")}
                                </button>
                              </div>
                            </li>
                          )}
                        <li>
                          <label>{t("supplyingto")}</label>
                          <div className="countrywrper">
                            {facility.supplying_country &&
                              facility.supplying_country
                                .slice(0, 6)
                                .map((country) => (
                                  <div className="countrynm" key={country.id}>
                                    <Tooltip
                                      color={colors}
                                      title={
                                        <span style={{ color: "#000000" }}>
                                          {country.name}
                                        </span>
                                      }
                                    >
                                      <img
                                        width="90"
                                        height="33"
                                        src={country.icon}
                                        alt=""
                                      />
                                    </Tooltip>
                                  </div>
                                ))}
                            {facility.supplying_country &&
                              facility.supplying_country.length > 6 && (
                                <div className="morelink">
                                  <button
                                    onClick={(e) =>
                                      openCountries(facility.supplying_country)
                                    }
                                    style={{
                                      background: "none",
                                      border: "none",
                                      padding: 0,
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                      color: "#022f4",
                                    }}
                                  >
                                    {t("more")}
                                  </button>
                                </div>
                              )}
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="regulatory">
                      <h2>{t("pl_regulatory_approvals")}</h2>

                      <Space className="certifycol">
                        {facility.facility_regulator &&
                          facility.facility_regulator
                            .slice(0, 5)
                            .map((approval) => (
                              <Tooltip
                                color={colors}
                                title={
                                  <span style={{ color: "#000000" }}>
                                    {" "}
                                    {approval.certification}
                                  </span>
                                }
                                key={approval.id}
                              >
                                <img
                                  width="68"
                                  height="69"
                                  src={
                                    approval.certification_icon
                                      ? `${config.BUCKET_URL}/certificate-files/${approval.certification_icon}`
                                      : dummyCert
                                  }
                                  alt={approval.certification}
                                />
                              </Tooltip>
                            ))}

                        {facility.facility_regulator &&
                          facility.facility_regulator.length > 5 && (
                            <div className="morelink">
                              <button
                                onClick={(e) =>
                                  openApprovals(facility.facility_regulator)
                                }
                                style={{
                                  background: "none",
                                  border: "none",
                                  padding: 0,
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  color: "#022f4",
                                }}
                              >
                                {t("more")}
                              </button>
                            </div>
                          )}
                      </Space>
                    </div>
                    <div className="btnwrp">
                      <Space>
                        <Button type="primary">
                          <Link
                            type="primary"
                            to={{
                              pathname:
                                "/manage-facility/view-facility/" +
                                btoa(facility.id)+"/shortlisted-company",
                            }}
                            target="_blank"
                          >
                            {t("moreInfo")}
                          </Link>
                        </Button>
                        <Button
                          type="primary"
                          onClick={(e) => showModalc(facility.facility_email)}
                        >
                          {t("sendInquiry")}
                        </Button>
                      </Space>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          {/* {facilityList && facilityList.length <= 0 && <> No Data</> } */}
        </Row>
      </Spin>
      <div className="paginatewrp">
        {totalFacilityCount && (
          <Pagination
            current={currentpage}
            defaultCurrent={1}
            total={totalFacilityCount}
            onChange={handleTableChange}
          />
        )}
      </div>

      <Modal
        title="Send Inquiry"
        open={isModalOpenc}
        footer={null}
        onCancel={handleCancelc}
        width={480}
        className="APImodel"
      >
        <SendInquiry to_email={to_email} sendInquiry={SendInquiy} />
      </Modal>

      <Modal
        title="Send Inquiry"
        open={isModalOpenc}
        footer={null}
        onCancel={handleCancelc}
        width={480}
        className="APImodel"
      >
        <SendInquiry to_email={to_email} sendInquiry={SendInquiy} />
      </Modal>
      <Modal
        title="Formulation"
        open={isModalOpenb}
        footer={null}
        onCancel={handleCancelb}
        width={600}
        className="APImodel"
      >
        <FormulationList facility_id={facilityId} hideAction={true} />
      </Modal>

      <Modal
        title="Supplying Countries"
        open={isModalOpenCountry}
        footer={null}
        onCancel={handleCancelCountries}
        width={600}
        className="APImodel"
      >
        <div className="countrynm">
          {Countries.map((country) => (
            <React.Fragment key={country.id}>
              <Tooltip
                color={colors}
                title={<span style={{ color: "#000000" }}>{country.name}</span>}
              >
                <img
                  width="90"
                  height="33"
                  src={country.icon}
                  alt=""
                  data-tooltip-id="supp-country"
                  data-tooltip-content={country.name}
                />
              </Tooltip>
            </React.Fragment>
          ))}
        </div>
      </Modal>
      <Modal
        title="Regulatory Approvals"
        open={isModalApproval}
        footer={null}
        onCancel={handleCancelApprovals}
        width={600}
        className="APImodel"
      >
        {Approvals.map((approval) => (
          <Space className="certifycol" key={approval.id}>
            <Tooltip title={approval.certification} key={approval.id}>
              <img
                width="68"
                height="69"
                src={
                  approval.certification_icon
                    ? `${config.BUCKET_URL}/certificate-files/${approval.certification_icon}`
                    : dummyCert
                }
                alt={approval.certification}
              />
            </Tooltip>
          </Space>
        ))}
      </Modal>
    </>
  );
};

export default ShortlistedCompanyList;
