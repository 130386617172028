import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { Form, Input, Select, Divider, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { deleteRequest, postRequest } from "../../../../../../axios";
import { authUserData } from "../../../../../../utils/Helper";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../../../../../utils/Notifications";

const EditableFormulationType = (props) => {
  const { t } = useTranslation();
  const [customFieldName, setCustomFieldName] = useState("");

  const addCustomField = async () => {
    if (customFieldName === "") {
      ErrorNotificationMsg(t("err_enterFormulation_typeField"));
      return false;
    }
    try {
      const extraAddApiResponse = await postRequest(
        "formulation_type/create/",
        {
          formulation_type: customFieldName,
        }
      );
      if (extraAddApiResponse.data.status) {
        SuccessNotificationMsg(t("msg_addformulationTypeSuccess"));
        props.handleSelectChange("formulation_type", customFieldName);
        props.data.push({ ...extraAddApiResponse.data.data });
        setCustomFieldName("");
      }
    } catch (error) {
      ErrorNotificationMsg("Formulation type already exists.");
    }
  };

  const deleteOption = async (id) => {
    const apiResponse = await deleteRequest(
      "formulation_type/update-delete/" + id
    );
    if (apiResponse.data.status) {
      SuccessNotificationMsg(t("msg_formulationTypeDeleted"));
      props.setDeleted(!props.deleted);
      props.removeId(id);
    }
  };

  return (
    <>
      <Form.Item
        name="formulation_type"
        label={t("pl_type_of_formulation")}
        rules={[
          {
            required: true,
            message: <Trans>{t("val_type_of_formulation")}</Trans>,
          },
        ]}
      >
        <Select
          showSearch
          showArrow
          allowClear
          value={props.value}
          placeholder={
            <div className="select_placeholder">
              {t("pl_type_of_formulation")}
            </div>
          }
          onChange={(value) =>
            props.handleSelectChange("formulation_type", value)
          }
          optionFilterProp="children"
          dropdownRender={(menu) => (
            <div>
              {menu}
              <>
                <Divider style={{ margin: "4px 0" }} />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    padding: 8,
                  }}
                >
                  <Input
                    style={{ flex: "auto" }}
                    onChange={(e) => setCustomFieldName(e.target.value)}
                    value={customFieldName}
                    placeholder={t("Enter Here")}
                  />
                  <button
                    style={{ cursor: "pointer" }}
                    className="btn-style"
                    onClick={addCustomField}
                  >
                    <Tooltip title="If not listed, add manually.">
                      <PlusOutlined />
                    </Tooltip>
                  </button>
                </div>
              </>
            </div>
          )}
        >
          {props.data.map((field) => {
            return (
              <Select.Option key={field.id} value={field.id}>
                <span>{field.formulation_type}</span>
                &nbsp;&nbsp;
                <span style={{ marginRight: "10px", float: "right" }}>
                  {authUserData()?.group === "Company Admin" &&
                    field.created_by === authUserData()?.user_id && (
                      <Popconfirm
                        className="action"
                        title={t("pop_title")}
                        okText={t("pop_ok")}
                        cancelText={t("pop_cancel")}
                        onConfirm={(e) => {
                          e.stopPropagation();
                          deleteOption(field.id);
                        }}
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    )}
                  {authUserData()?.group === "Super Admin" && (
                    <Popconfirm
                      className="action"
                      title={t("pop_title")}
                      okText={t("pop_ok")}
                      cancelText={t("pop_cancel")}
                      onConfirm={(e) => {
                        e.stopPropagation();
                        deleteOption(field.id);
                      }}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  )}
                </span>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};

EditableFormulationType.propTypes = {
  handleSelectChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  setDeleted: PropTypes.func.isRequired,
  deleted: PropTypes.bool.isRequired,
  removeId: PropTypes.func.isRequired,
  value: PropTypes.any, // Adjust the type according to your use case
};

export default EditableFormulationType;
