import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Row, Col, Input, Space, Table, Breadcrumb, Tag, Tooltip } from "antd";
import { debounce } from "lodash";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../styles/Main.scss";
import "antd/dist/antd.css";
import { DownloadOutlined } from "@ant-design/icons";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import copy from "copy-to-clipboard";
import { getRequest } from "../../axios";
import config from "../../Config";
import { CompanyAdmin, SuperAdmin, authUserData } from "../../utils/Helper";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/Notifications";

const { Search } = Input;

const InActivePurchasedConciergeUsd = () => {
  const { t } = useTranslation();
  const CountryName = authUserData()?.country_name;
  const [loading, setLoading] = useState(false);
  const [purchasedConcierge, setPurchasedConcierge] = useState([]);
  const [totalCount, settotalCount] = useState(0);

  const [state, setState] = useState({
    page: 1,
    ordering: "id",
    pagesize: 10,
    search: "false",
    total: 0,
  });

  useEffect(() => {
    const getAllPurchasedConcierge = async (data) => {
      setLoading(true);
      let apiResponse;
      if (authUserData()?.group === "Company Admin") {
        apiResponse = await getRequest(
          "subscription/list-subscription/" + authUserData()?.company_id,
          data
        );
      } else if (authUserData()?.group === "Super Admin") {
        apiResponse = await getRequest("subscription/list-subscription/"+"?currency=USD", data);
      }
      if (apiResponse.data) {
        setPurchasedConcierge(apiResponse.data.results);
        settotalCount(apiResponse.data.count);
        setLoading(false);
      }
    };
    try {
      getAllPurchasedConcierge(state);
    } catch (error) {
      ErrorNotificationMsg(error);
    }
  }, [state]);

  const handleTableChange = async (pagination, filters, sorter, ordering) => {
    const orderBy = GetSortingEnumValue(sorter.columnKey, sorter.order);
    setState({
      ...state,
      page: pagination.current,
      pagesize: pagination.pageSize,
      ordering: orderBy,
    });
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = "id";

    if (sortField === "id" && sortOrder === "ascend") {
      sortingEnumKey = "id";
    }
    if (sortField === "id" && sortOrder === "descend") {
      sortingEnumKey = "-id";
    }

    if (sortField === "concierge_name" && sortOrder === "ascend") {
      sortingEnumKey = "concierge_name";
    }
    if (sortField === "concierge_name" && sortOrder === "descend") {
      sortingEnumKey = "-concierge_name";
    }

    if (sortField === "start_date" && sortOrder === "ascend") {
      sortingEnumKey = "start_date";
    }
    if (sortField === "start_date" && sortOrder === "descend") {
      sortingEnumKey = "-start_date";
    }

    if (sortField === "end_date" && sortOrder === "ascend") {
      sortingEnumKey = "end_date";
    }
    if (sortField === "end_date" && sortOrder === "descend") {
      sortingEnumKey = "-end_date";
    }

    if (sortField === "active" && sortOrder === "ascend") {
      sortingEnumKey = "active";
    }
    if (sortField === "active" && sortOrder === "descend") {
      sortingEnumKey = "-active";
    }

    if (sortField === "sell_price" && sortOrder === "ascend") {
      sortingEnumKey = "sell_price";
    }
    if (sortField === "sell_price" && sortOrder === "descend") {
      sortingEnumKey = "-sell_price";
    }
    if (sortField === "amount" && sortOrder === "ascend") {
      sortingEnumKey = "amount";
    }
    if (sortField === "amount" && sortOrder === "descend") {
      sortingEnumKey = "-amount";
    }
    return sortingEnumKey;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "active":
        return "green";
      case "inactive":
        return "red";
      default:
        return "gray";
    }
  };

  const columns = [
    {
      title: "Payment Id",
      dataIndex: "payment_id",
      key: "payment_id",
      ellipsis: true,
      sorter: true,
      render: (text) => (
        <div
          style={{ userSelect: "all", cursor: "pointer" }}
          onClick={() => {
            copy(text);
            SuccessNotificationMsg("Copied to clipboard");
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "company name",
      dataIndex: "company_name",
      key: "company_name",
      sorter: true,
    },
    {
      title: "Plan name",
      dataIndex: "concierge_name",
      key: "concierge_name",
      sorter: true,
    },
    {
      title: "Purchase Date",
      dataIndex: "start_date",
      key: "start_date",
      sorter: true,
    },
    {
      title: "Expire Date",
      dataIndex: "end_date",
      key: "end_date",
      sorter: true,
    },
    {
      title: "Status",
      key: "active",
      render: (res) => (
        <Tag color={getStatusColor(res.active)} key={res.status}>
          {res.active}
        </Tag>
      ),
      sorter: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (e, record) => (
        // <>
        //   <FontAwesomeIcon icon={faIndianRupeeSign} /> {sell_price}{" "}
        // </>
        <>
          {record?.currency === "INR" ? (
            <>
              <FontAwesomeIcon icon={faIndianRupeeSign} /> {record?.amount}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faDollarSign} /> {record?.amount}
            </>
          )}
        </>
      ),
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "invoice_no",
      key: "invoice_no",
      render: (e, record) =>
        record?.id && (
          <a
            className="link-color"
            href={`${config.API_URL}/subscription/download-invoice/${record?.id}`}
            download
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            {t("invoice")} <DownloadOutlined />{" "}
          </a>
        ),
    },
  ];

  // const searchCallbackDelayed = (event) => {
  //   const { value } = event.target;
  //   console.log(value,"value")
    
  //   debounceLoadData(value, onSearch);
  // };
  const searchCallbackDelayed = (event) => {
    const { value } = event.target;
    console.log(value, "value");
  
    let searchValue;
  
    // Check if the value is exactly "active" or "inactive"
    if (value.toLowerCase() === 'active') {
      searchValue = true;
    } else if (value.toLowerCase() === 'inactive') {
      searchValue = false;
    } else {
      searchValue = value; // Use the original value for proper searching
    }
  
    debounceLoadData(searchValue, onSearch);
  };

  const onSearch = (value) => {
    setState({
      ...state,
      search: value,
    });
  };
  const colors = ["#D9E0E2"];
  const debounceLoadData = debounce((value, onSearch) => {
    onSearch(value);
  }, 1000);
  return (
    <div className="contentpart">
      <div className="right_content_col">
        <div className="content_wraper ">
          <div className="myorder">
            <div className="pagename">
              <Tooltip
                color={colors}
                title={
                  <span style={{ color: "#000000" }}>
                    View your Subscription details.
                  </span>
                }
              >
                {t("purchasedConcierge")}
              </Tooltip>
              <Breadcrumb>
                <Breadcrumb.Item>
                  {SuperAdmin && <Link to="/dashboard">{t("home")}</Link>}
                  {CompanyAdmin && (
                    <Link to="/business-lounge">{t("home")}</Link>
                  )}
                </Breadcrumb.Item>
                <Breadcrumb.Item>{t("purchasedConcierge")}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="heading">
              <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }} align="middle">
                <Col>
                  <Space>
                    {/* <DatePicker placeholder="Start date" />
                    <DatePicker placeholder="End date" /> */}

                    {/* <Button
                      type="primary"
                      htmlType="button"
                      size="small"
                    >
                      Search
                    </Button> */}

                    {/* <Button
                      type="primary"
                      htmlType="button"
                      size="small"
                    >
                      Export Exel
                    </Button> */}
                  </Space>
                </Col>
                <Col flex="auto" align="center">
                  <Space>
                    <div className="searchwrp">
                      <Search
                        placeholder={"search"}
                        onChange={searchCallbackDelayed}
                      />
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>
            <Table
              className="table_grid"
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={purchasedConcierge}
              scroll={{ x: 970 }}
              loading={loading}
              pagination={{
                defaultCurrent: state.page,
                defaultPageSize: state.pagesize,
                total: totalCount,
                showSizeChanger: true,
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InActivePurchasedConciergeUsd;
