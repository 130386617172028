import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Breadcrumb, Tooltip } from "antd";
import { TableOutlined, AppstoreOutlined } from "@ant-design/icons";
import ShortlistedCompanyList from "./ShortlistedCompanyList";
import ShortlistedCompanyGrid from "./ShortlistedCompanyGrid";
import { CompanyAdmin, SuperAdmin } from "../../utils/Helper";

const ShortListedCompany = () => {
  const { t } = useTranslation();
  const [shortListedCompanyList, setShortListedCompanyList] = useState(true);
  const [shortListedCompanyGrid, setShortListedCompanyGrid] = useState(false);

  const hideComponent = (name) => {
    switch (name) {
      case "shortListedCompanyList":
        setShortListedCompanyList(true);
        setShortListedCompanyGrid(false);
        break;
      case "shortListedCompanyGrid":
        setShortListedCompanyList(false);
        setShortListedCompanyGrid(true);
        break;
      default:
    }
  };

  const colors = [
    '#D9E0E2'
  ];
  return (
    <div className="contentpart">
      <div className="right_content_col">
        <div className="content_wraper ">
          <div className="businesslounge">
            <div className="loungetype shortlistcompany">
              <div className="pagename">

                <Tooltip color={colors} title={<span style={{ color: '#000000' }}> Shortlisted companies that align with your expectations and enable you to connect with them.</span>}>
                {t("shortlistedCompanyFacilities")}
                </Tooltip>
               
                <Breadcrumb>
                  <Breadcrumb.Item>
                    {SuperAdmin && <Link to="/dashboard">{t("home")}</Link>}
                    {CompanyAdmin && (
                      <Link to="/business-lounge">{t("home")}</Link>
                    )}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{t("companyList")}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="heading"></div>

              <div className="viewiconwrap">
                <Button
                  className={shortListedCompanyList ? "card active" : ""}
                  onClick={() => hideComponent("shortListedCompanyList")}
                >
                  <AppstoreOutlined />
                </Button>
                <Button
                  className={shortListedCompanyGrid ? "grid active" : ""}
                  onClick={() => hideComponent("shortListedCompanyGrid")}
                >
                  <TableOutlined />
                </Button>
              </div>
              {shortListedCompanyList && <ShortlistedCompanyList />}
              {shortListedCompanyGrid && <ShortlistedCompanyGrid />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortListedCompany;
