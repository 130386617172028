import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import ButtonField from "../../../../../../common/htmlComponents/ButtonField";
const RequiredFieldsNextModel = ({
  handleCancelc4,
  // handlePreviousFacilityChange,
}) => {
  const { t } = useTranslation();

  const backToList = () => {
    handleCancelc4();
  };
  // const handlePreviousPage = () => {
  //   // const { currenttab } = currentTab;
  //   // let showTabName = componentArr[currenttab + 1];
  //   // hideComponent(showTabName);
  //   handlePreviousFacilityChange();
  //   handleCancelc4();
  // };
  return (
    <>
      <p>{t("model_description_required")}</p>
      <Row gutter={[15]} className="bottomButtons">
        {/* <Col xs={12} align="center">
          <ButtonField
            type="primary"
            htmlType=""
            // loading={btnLoading}
            label={t("button_back")}
            onClick={handlePreviousPage}
          />
        </Col> */}
        <Col xs={12} align="center">
          <ButtonField
            type="primary"
            htmlType=""
            // loading={btnLoading}
            label={t("button_back_required")}
            onClick={backToList}
          />
        </Col>
      </Row>
    </>
  );
};
RequiredFieldsNextModel.propTypes = {
  handleCancelc4: PropTypes.func,
  // handlePreviousFacilityChange: PropTypes.func,
};

export default RequiredFieldsNextModel;
