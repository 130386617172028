import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import ButtonField from "../../../../../common/htmlComponents/ButtonField";

const ConfirmRadioModel = ({
  handleCancelc2,
  handleRadioModelChange,
  radioValueModel,
}) => {
  const { t } = useTranslation();

  const backToList = () => {
    handleCancelc2();
  };
  const handleNextPage = () => {
    // const { currenttab } = currentTab;
    // let showTabName = componentArr[currenttab + 1];
    // hideComponent(showTabName);
    // handleTableChange();
    handleRadioModelChange(radioValueModel);
    handleCancelc2();
  };
  return (
    <>
      <p>{t("model_description_message")}</p>
      <Row gutter={[15]} className="bottomButtons">
        <Col xs={12} align="center">
          <ButtonField
            type="primary"
            htmlType=""
            // loading={btnLoading}
            label={t("button_back")}
            onClick={handleNextPage}
          />
        </Col>
        <Col xs={12} align="center">
          <ButtonField
            type="primary"
            htmlType=""
            // loading={btnLoading}
            label={t("button_save")}
            onClick={backToList}
          />
        </Col>
      </Row>
    </>
  );
};
ConfirmRadioModel.propTypes = {
  handleCancelc2: PropTypes.func,
  handleRadioModelChange: PropTypes.func,
  radioValueModel: PropTypes.any,
};

export default ConfirmRadioModel;
