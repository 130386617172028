import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Row,
  Col,
  Radio,
  Form,
  Space,
  Spin,
  Upload,
  Button,
  Input,
} from "antd";
import {
  DeleteOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { deleteRequest, getRequest, patchRequest } from "../../../../../axios";
import ButtonField from "../../../../../common/htmlComponents/ButtonField";
import { getUtilityCount } from "./redux/UtilitiesSlice";
import { loadingIcon } from "../../../../../utils/Helper";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../../../../utils/Notifications";
import { useUnsavedChanges } from "../../../../UnsavedChangesProvider";

const { TextArea } = Input;

const Utilities = (props) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const dispatch = useDispatch();
  const { setCurrentTab } = props;
  const { setUnsavedChanges } = useUnsavedChanges();
  const initialState = {
    nitrogen_plant: false,
    nitrogen_plant_capacity: null,
    nitrogen_purity: null,
    water_treatment: [
      {
        water_treatment_system_id: props.waterTreatment_id,
        water_treatment_system: null,
        water_treatment_capacity: null,
      },
    ],
    other_utilities: [
      {
        sub_utility_id: props.subUtility_id && props.subUtility_id[0],
        utility_type: "",
        capacity: "",
        remarks: "",
      },
      {
        sub_utility_id: props.subUtility_id && props.subUtility_id[1],
        utility_type: "",
        capacity: "",
        remarks: "",
      },
    ],
    utility_file: "",
  };

  const [state, setState] = useState(initialState);
  const [btnLoading, setBtnLoading] = useState(false);
  const [hide, setHide] = useState(false);

  const [size, setSize] = useState(null);
  const [uploadFile, setUploadFile] = useState("");
  const [fileName, setFileName] = useState(null);
  const [fileLink, setFileLink] = useState(null);
  const [loading, setLoading] = useState(false);

  let id;
  if (props.utility_api_id) {
    id = props.utility_api_id;
  }
  if (props.utility_formulation_id) {
    id = props.utility_formulation_id;
  }

  useEffect(() => {
    const getUtilities = async () => {
      try {
        setLoading(true);
        const apiResponse = await getRequest(
          "facility/create-facility-utility/" + id
        );
        if (apiResponse.data.success) {
          setFileLink(apiResponse.data.data.utility_file);
          setState({ ...apiResponse.data.data });
          if (apiResponse.data.data.nitrogen_plant === false) {
            setHide(false);
          }
          if (apiResponse.data.data.nitrogen_plant === true) {
            setHide(true);
          }
          formRef.current.setFieldsValue({
            ...apiResponse.data.data,
            water_treatment_system: apiResponse.data.data.water_treatment[0]
              .water_treatment_system
              ? apiResponse.data.data.water_treatment[0].water_treatment_system
              : null,
            water_treatment_capacity: apiResponse.data.data.water_treatment[0]
              .water_treatment_capacity
              ? apiResponse.data.data.water_treatment[0]
                  .water_treatment_capacity
              : null,
            nitrogen_plant_capacity:
              apiResponse.data.data.nitrogen_plant_capacity === 0
                ? ""
                : apiResponse.data.data.nitrogen_plant_capacity,
            nitrogen_purity:
              apiResponse.data.data.nitrogen_purity === 0
                ? ""
                : apiResponse.data.data.nitrogen_purity,
          });
          setOtherUtilitiesFieldValue(apiResponse.data.data.other_utilities);
          // if (apiResponse.data.data.water_treatment[0].water_treatment_system !== "") {
          //     dispatch(getUtilityCount(1))
          // }
        } else {
          ErrorNotificationMsg(t("err_utilities"));
        }
        setLoading(false);
      } catch (error) {
        ErrorNotificationMsg(error);
        setLoading(false);
      }
    };
    id && getUtilities();
  }, [id, t, dispatch]);
  useEffect(() => {
    // Check if any of the required properties in water_treatment are null
    const isNull = state?.water_treatment?.some(
      (treatment) =>
        treatment.water_treatment_system === null ||
        treatment.water_treatment_capacity === null
    );

    if (isNull) {
      // If any of them are null, set child3State to true
      setCurrentTab((prevTab) => ({
        ...prevTab,
        child3: true,
        // child2: false, // Set child2 to false or adjust based on your requirement
      }));
    } else {
      // Otherwise, set child3State to false
      setCurrentTab((prevTab) => ({
        ...prevTab,
        child3: false,
        // child2: false, // Set child2 to false or adjust based on your requirement
      }));
    }
  }, [state?.water_treatment]); // Only water_treatment is added to the dependency array

  const setOtherUtilitiesFieldValue = (data) => {
    let stateArr = [];
    let fieldObject = {};
    data.map((objectValue, objectKey) => {
      stateArr.push(objectValue);
      fieldObject["utility_type" + objectKey] = objectValue.utility_type;
      fieldObject["capacity" + objectKey] = objectValue.capacity;
      fieldObject["remarks" + objectKey] = objectValue.remarks;
      return null;
    });
    formRef.current.setFieldsValue(fieldObject);
  };

  const url = state.utility_file;

  let filename = "";
  try {
    if (fileLink) {
      filename = url.split("/").pop();
    }
  } catch (e) {
    console.error(e);
  }

  const handleFileDelete = async () => {
    try {
      setLoading(true);
      const apiResponse = await deleteRequest(
        "facility/delete-facility-utility-file/" + id
      );
      if (apiResponse.data.success) {
        SuccessNotificationMsg(t("msg_deleteFileSuccess"));
        setUploadFile("");
        setFileName(null);
        setFileLink(null);
        setSize(null);
        setLoading(false);
        setCurrentTab((prevTab) => ({
          ...prevTab,
          child1: false,
          // child2: false, // Set child2 to false or adjust based on your requirement
        }));
        setUnsavedChanges((prevTab) => ({
          ...prevTab,
          outerClickChild1: false,
          // outerClickChild2: true,
        }));
      }
    } catch (error) {
      setLoading(false);
      ErrorNotificationMsg(t("err_uploadSuccess"));
    }
  };

  const uploadProps = {
    accept: ".pdf,.pptx,.ppt,doc,.docx",

    showUploadList: false,
    beforeUpload: (file) => {
      // if (file.type !== "application/pdf") {
      //   ErrorNotificationMsg(t("upload_fileTypeErr"));
      // }
      setSize(file.size);
      if (file.size <= 62914560) {
        setUploadFile(file);
        setFileName(file.name);
      }
      return false;
    },
  };

  const handleWaterInputChange = (field, e, index) => {
    let WaterSystemArr = state.water_treatment;
    WaterSystemArr[index][field] = e.target.value ? e.target.value : null;
    setState({ ...state, water_treatment: WaterSystemArr });
  };

  const handleRadioChange = async (e) => {
    if (e.target.value === true) {
      setState({ ...state, nitrogen_plant: true });
      setCurrentTab((prevTab) => ({
        ...prevTab,
        child2: true,
        // child1: false, // Set child2 to false or adjust based on your requirement
      }));
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        // outerClickChild1: false,
        outerClickChild2: true,
      }));
      setHide(true);
    } else if (e.target.value === false) {
      setState({
        ...state,
        nitrogen_plant: false,
        nitrogen_plant_capacity: null,
        nitrogen_purity: null,
      });
      formRef.current.setFieldsValue({
        ...state,
        nitrogen_plant_capacity: null,
        nitrogen_purity: null,
      });
      setCurrentTab((prevTab) => ({
        ...prevTab,
        child2: true,
        // child1: false, // Set child2 to false or adjust based on your requirement
      }));
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        // outerClickChild1: false,
        outerClickChild2: true,
      }));
      setHide(false);
    }
  };

  const handleCloneInputChange = (field, e, index) => {
    let newUtilitiesArr = state.other_utilities;
    newUtilitiesArr[index][field] = e.target.value ? e.target.value : null;
    setState({ ...state, other_utilities: newUtilitiesArr });
  };

  // const resetIndex = async (index, otherUtilitiesId) => {
  //   let newUtilitiesArr = state.other_utilities;
  //   newUtilitiesArr[index] = {
  //     sub_utility_id: otherUtilitiesId,
  //     utility_type: "",
  //     capacity: "",
  //     remarks: "",
  //   };
  //   setState({ ...state, other_utilities: newUtilitiesArr });
  //   setOtherUtilitiesFieldValue(newUtilitiesArr);
  // };
  const handleFormChange = (changedValues, allValues) => {
    // Check if the changed values include the fields you want to track
    // console.log(changedValues);
    // console.log(allValues.nitrogen_plant);

    if (
      "remarks0" in changedValues ||
      "capacity0" in changedValues ||
      "utility_type0" in changedValues ||
      "utility_type1" in changedValues ||
      "capacity1" in changedValues ||
      "remarks1" in changedValues ||
      "water_treatment_system" in changedValues ||
      "water_treatment_capacity" in changedValues ||
      "utilitiesFileUpload" in changedValues ||
      "nitrogen_plant" in changedValues ||
      "nitrogen_plant_capacity" in changedValues ||
      "nitrogen_purity" in changedValues
    ) {
      setCurrentTab((prevTab) => ({
        ...prevTab,
        child1: true,
        // child2: false, // Set child2 to false or adjust based on your requirement
      }));
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        outerClickChild1: true,
        // outerClickChild2: false,
      }));
    }
  };
  const handleSubmit = async () => {
    const payload = {
      nitrogen_plant: state.nitrogen_plant,
      nitrogen_plant_capacity: state.nitrogen_plant_capacity,
      nitrogen_purity: state.nitrogen_purity,
      water_treatment: state.water_treatment,
      other_utilities: state.other_utilities,
    };
    try {
      setBtnLoading(true);
      const formData = new FormData();
      formData.append("utility_file", uploadFile);
      formData.append("form_data", JSON.stringify(payload));

      const apiResponse = await patchRequest(
        "facility/create-facility-utility/" + id,
        formData
      );
      if (apiResponse.data.status) {
        SuccessNotificationMsg(t("msg_updateUtilitiesSuccess"));
        setState(apiResponse.data.data);
        dispatch(getUtilityCount(1));
        setBtnLoading(false);
        setCurrentTab((prevTab) => ({
          ...prevTab,
          child1: false,
          child2: false,
          child3: false, // Set child2 to false or adjust based on your requirement
        }));
        setUnsavedChanges((prevTab) => ({
          ...prevTab,
          outerClickChild1: false,
          outerClickChild2: false,
        }));
      }
    } catch (error) {
      setBtnLoading(false);
      ErrorNotificationMsg(t("err_utilities"));
    }
  };

  // const resetWaterSystem = () => {
  //   setState({
  //     ...state,
  //     water_treatment_system: null,
  //     water_treatment_capacity: null,
  //   });
  //   formRef.current.setFieldsValue({
  //     ...state,
  //     water_treatment_system: null,
  //     water_treatment_capacity: null,
  //   });
  // };

  return (
    <>
      <div className="utilitywrp">
        <Form
          ref={formRef}
          onFinish={handleSubmit}
          autoComplete="off"
          onValuesChange={handleFormChange}
        >
          {state.water_treatment.map((x, index) => {
            return (
              <>
                <div className="graybox">
                  <div className="watersystem">
                    <Row gutter={[15]} key={index}>
                      <Col xs={24} sm={12} lg={16}>
                        <Form.Item
                          name="water_treatment_system"
                          label={t("pl_Water_treatment_system")}
                          rules={[
                            {
                              required: true,
                              message: (
                                <Trans>{t("val_Water_treatment_system")}</Trans>
                              ),
                            },
                            {
                              min: 2,
                              message: <Trans>{t("minlength_msg")}</Trans>,
                            },
                            {
                              max: 1000,
                              message: (
                                <>
                                  <Trans>{t("maxlength_msg")}</Trans> {1000}
                                  {t("characters")}
                                </>
                              ),
                            },
                          ]}
                        >
                          <TextArea
                            className="remarktextarea"
                            max={1000}
                            placeholder={t("pl_Water_treatment_system")}
                            onChange={(value) =>
                              handleWaterInputChange(
                                "water_treatment_system",
                                value,
                                index
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} lg={8}>
                        <Space>
                          <Form.Item
                            name="water_treatment_capacity"
                            label={t("pl_capacity_UOM")}
                            rules={[
                              {
                                required: true,
                                message: <Trans>{t("val_capacity")}</Trans>,
                              },
                              {
                                // pattern: /^[0-9]+$/,
                                pattern: /^[a-zA-Z0-9]+$/,
                                message: <Trans>{t("val_capacity")}</Trans>,
                              },
                            ]}
                          >
                            <Input
                              // type="number"
                              step="0.01"
                              placeholder={t("pl_capacity_UOM")}
                              onChange={(value) =>
                                handleWaterInputChange(
                                  "water_treatment_capacity",
                                  value,
                                  index
                                )
                              }
                            />
                          </Form.Item>
                          {/* {state.water_treatment[0].water_treatment_system && (
                            <ButtonField
                              className="resizebtn"
                              size="small"
                              type="primary"
                              onClick={resetWaterSystem}
                              label={t("reset")}
                            />
                          )} */}
                        </Space>
                      </Col>
                    </Row>
                  </div>
                </div>

                {/* <div className="graybox">
                  <div className="watersystem">
                    <Row gutter={[15]} key={index}>
                      <Col xs={24}>
                        <Space>
                          <Form.Item
                            name="utilitiesFileUpload"
                            label={t("utilitiesFileUpload")}
                          >
                            <div className="uploadinside">
                              <Upload {...uploadProps}>
                                <Button
                                  type="primary"
                                  size="small"
                                  icon={<UploadOutlined />}
                                >
                                  {t("upload")}
                                </Button>
                                <div className="condition">
                                  {" "}
                                  <p
                                    style={{
                                      color:
                                        size > 62914560 ? "red" : "GrayText",
                                    }}
                                  >
                                    {t("upload_fileSize")}{" "}
                                    {t("upload_fileType")}
                                  </p>
                                </div>
                              </Upload>
                              {size <= 62914560 && (
                                <div className="download">
                                  <Space size={[12, 16]} className="antspace">
                                    <a
                                      href={fileLink}
                                      className="link-color"
                                      download
                                    >
                                      {fileLink ? filename : fileName}
                                    </a>
                                    {fileLink && (
                                      <DeleteOutlined
                                        onClick={handleFileDelete}
                                      />
                                    )}
                                    {fileName && (
                                      <DeleteOutlined
                                        onClick={handleFileDelete}
                                      />
                                    )}
                                    <Spin
                                      spinning={loading}
                                      indicator={loadingIcon}
                                    />
                                  </Space>
                                  <Space>
                                    {fileLink ? (
                                      <a
                                        href={fileLink}
                                        className="link-color"
                                        download
                                      >
                                        {fileLink ? <DownloadOutlined /> : ""}{" "}
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </Space>
                                </div>
                              )}
                            </div>
                          </Form.Item>
                        </Space>
                      </Col>
                    </Row>
                  </div>
                </div> */}
              </>
            );
          })}

          {/* <div className="graybox ">
          
          </div> */}
          <div className="graybox">
            <Row gutter={[15]}>
              <Col xs={24}>
                <Form.Item name="nitrogen_plant">
                  <label className="radiolbl">
                    {t("nitrogen_generation_plant")}
                  </label>
                  <Radio.Group
                    onChange={handleRadioChange}
                    value={state.nitrogen_plant}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            {hide && (
              <Row gutter={[15]}>
                <Col xs={24} sm={12} lg={10}>
                  <Space size={[12, 16]} align="start">
                    <Form.Item
                      name="nitrogen_plant_capacity"
                      label={t("pl_nitrogen_capacity")}
                      rules={[
                        {
                          required: true,
                          message: <Trans>{t("val_capacity")}</Trans>,
                        },
                        {
                          // pattern: /^[0-9]+(\.[0-9]{1,4})?$/,
                          pattern: /^[a-zA-Z0-9]+(\.[0-9]{1,4})?$/,
                          message: <Trans>{t("val_capacity")}</Trans>,
                        },
                      ]}
                    >
                      <Input
                        // type="number"
                        step="0.01"
                        placeholder={t("pl_nitrogen_capacity")}
                        onChange={(value) =>
                          setState({
                            ...state,
                            nitrogen_plant_capacity: value.target.value,
                          })
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="nitrogen_purity"
                      label={t("pl_nitrogen_purity")}
                      rules={[
                        {
                          required: true,
                          message: <Trans>{t("val_purity")}</Trans>,
                        },
                        {
                          // pattern: /^[0-9]+$/,
                          // pattern: /^[0-9]+(\.[0-9]{1,4})?$/,
                          pattern: /^[a-zA-Z0-9]+(\.[0-9]{1,4})?$/,
                          message: <Trans>{t("val_purity")}</Trans>,
                        },
                      ]}
                    >
                      <Input
                        // type="number"
                        step="0.01"
                        placeholder={t("pl_nitrogen_purity")}
                        onChange={(value) =>
                          setState({
                            ...state,
                            nitrogen_purity: value.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            )}
          </div>

          <div className="graybox">
            <h3>{t("otherUtilities")}</h3>
            {state.other_utilities.map((x, index) => {
              return (
                <>
                  <Row gutter={[15]} key={index}>
                    <Col xs={24} sm={12} lg={6}>
                      <Form.Item
                        name={`utility_type` + index}
                        label={t("pl_type_of_utility")}
                      >
                        <Input
                          placeholder={t("pl_type_of_utility")}
                          onChange={(value) =>
                            handleCloneInputChange("utility_type", value, index)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={4} lg={4}>
                      <Form.Item
                        name={`capacity` + index}
                        label={t("pl_nitrogen_capacity_utility")}
                        rules={[
                          {
                            required: false,
                            message: <Trans>{t("val_capacity")}</Trans>,
                          },
                          {
                            max: 15,
                            message: (
                              <>
                                <Trans>{t("maxlength_msg")}</Trans> 15
                                {t("characters")}
                              </>
                            ),
                          },
                          {
                            // pattern: /^[a-zA-Z0-9/]+$/, //Alphanumeric
                            // pattern: /^[a-zA-Z0-9\/\s]+$/,
                            pattern: /^[a-zA-Z0-9\/\s\-_`!~@#$%^&*_+=()]+$/,
                            message: <Trans>{t("val_capacity1")}</Trans>,
                          },
                        ]}
                      >
                        <Input
                          // type="number"
                          placeholder={t("pl_nitrogen_capacity_utility")}
                          onChange={(value) =>
                            handleCloneInputChange("capacity", value, index)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} lg={12} className="otherutility">
                      <Space size={[12, 12]}>
                        <Form.Item
                          name={`remarks` + index}
                          label={t("pl_remark")}
                        >
                          <TextArea
                            className="remarktextarea"
                            max={1000}
                            placeholder={t("pl_remark")}
                            onChange={(value) =>
                              handleCloneInputChange("remarks", value, index)
                            }
                          />
                        </Form.Item>
                        {/* {state.other_utilities[index].utility_type && (
                          <ButtonField
                            type="primary"
                            size="small"
                            className="resizebtn"
                            onClick={() => resetIndex(index, x.sub_utility_id)}
                            label={t("reset")}
                          />
                        )} */}
                      </Space>
                    </Col>
                  </Row>
                </>
              );
            })}
          </div>
          <Col xs={24} className="savebtn">
            <ButtonField
              type="primary"
              className="resizebtn"
              htmlType="submit"
              loading={btnLoading}
              label={t("save")}
            />
          </Col>
        </Form>
      </div>
    </>
  );
};

Utilities.propTypes = {
  waterTreatment_id: PropTypes.number,
  subUtility_id: PropTypes.array,
  utility_api_id: PropTypes.number,
  utility_formulation_id: PropTypes.number,
  setCurrentTab: PropTypes.func,
};

export default Utilities;
