import axios from "axios";
import config from "../Config";
import { ErrorNotificationMsg } from "../utils/Notifications";

const axiosClient = axios.create();
axiosClient.defaults.baseURL = config.API_URL;

// axiosClient.defaults.timeout = 5000;
axiosClient.defaults.timeout = 300000;

const token = localStorage.getItem("auth_token");

if (token && token !== null && token !== undefined) {
  axiosClient.defaults.headers.Authorization = token ? `Bearer ${token}` : null;
}

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 500) {
      ErrorNotificationMsg("Server Error");
      return Promise.reject(error);
    } else if (error.response.status === 401 || error.response.status === 400) {
      if (error.response.data.errors && error.response.data.errors.length > 0) {
        ErrorNotificationMsg(error.response.data.errors[0].detail);
      } else {
        ErrorNotificationMsg(error.response.data.message);
      }
    }
  }
);

export function getRequest(URL, params = null) {
  return axiosClient.get(`/${URL}`, { params }).then((response) => response);
}

export function postRequest(URL, payload) {
  return axiosClient.post(`/${URL}`, payload).then((response) => response);
}

export function patchRequest(URL, payload) {
  return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL, params = null) {
  return axiosClient
    .delete(`/${URL}`, { data: params })
    .then((response) => response);
}
