import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, patchRequest, postRequest } from "../../../../../axios";

export const addClassificationAPI = async (data) => {
    const response = await postRequest(`facility/list-create-api-plant-lab-classification/${data.id}`, data);
    return response.data;
};

export const getclassficationAPI = createAsyncThunk("get_classiicationAPI", async (id) => {
    const response = await getRequest("facility/list-api-plant-lab-classification/" + id);
    return response.data;
});

export const getClassificationAPICount = createAsyncThunk("get_ClassificationAPI_count", async (count) => {
    return count;
});

export const getClassificationAPIById = createAsyncThunk("get_classificationAPI_by_id", async (id) => {
    const response = await getRequest("facility/list-create-api-plant-lab-classification/" + id);
    return response.data;
});

export const updateClassificationAPI = async (data) => {
    const response = await patchRequest(`facility/update-delete-api-plant-lab-classification/${data.id}`, { ...data });
    return response.data;
};

export const resetAfterUpdate = createAsyncThunk("reset_afterUpdate", async (afterUpdate) => {
    return afterUpdate;
});

export const deleteClassificationAPI = async (id) => {
    const response = await deleteRequest("facility/update-delete-api-plant-lab-classification/" + id);
    return response.data;
};

// Formulation

export const addClassificationFormulation = async (data) => {
    const response = await postRequest(`facility/list-create-formulation-plant-lab-classification/${data.id}`, data);
    return response.data;
};

export const getclassficationFormulation = createAsyncThunk("get_classiicationFormulation", async (id) => {
    const response = await getRequest("facility/list-create-formulation-plant-lab-classification/" + id);
    return response.data;
});

export const getClassificationFormulationCount = createAsyncThunk("get_ClassificationFormulation_count", async (count) => {
    return count;
});

export const getClassificationFormulationById = createAsyncThunk("get_classificationFormulation_by_id", async (id) => {
    const response = await getRequest("facility/list-formulation-plant-lab-classification/" + id);
    return response.data;
});

export const updateClassificationFormulation = async (data) => {
    const response = await patchRequest(`facility/update-delete-formulation-plant-lab-classification/${data.id}`, { ...data });
    return response.data;
};

export const deleteClassificationFormulation = async (id) => {
    const response = await deleteRequest("facility/update-delete-formulation-plant-lab-classification/" + id);
    return response.data;
};

const initialState = {
    classification: [],
    classificationAPI_id: null,
    classificationFormulation: [],
    classificationFormulation_id: null,
    classificationAPICount: null,
    classificationFormulationCount: null
}

const plantLab = createSlice({
    name: "plantLab",
    initialState,
    reducers: {},
    extraReducers: {

        //  get Classification API By Id

        [getClassificationAPIById.fulfilled]: (state, action) => {
            return { ...state, classification: action.payload, classificationAPI_id: action.payload.results[0].id };
        },

        [getClassificationAPICount.fulfilled]: (state, action) => {
            return { ...state, classificationAPICount: action.payload };
        },

        [getclassficationAPI.fulfilled]: (state, action) => {
            return { ...state, classificationAPICount: action.payload.count };
        },

        //  get Classification Formulation By Id

        [getClassificationFormulationById.fulfilled]: (state, action) => {
            return { ...state, classificationFormulation: action.payload, classificationFormulation_id: action.payload.results[0].id };
        },

        [getClassificationFormulationCount.fulfilled]: (state, action) => {
            return { ...state, classificationFormulationCount: action.payload };
        },

        [getclassficationFormulation.fulfilled]: (state, action) => {
            return { ...state, classificationFormulationCount: action.payload.count };
        },

        // resetAfterUpdate

        [resetAfterUpdate.fulfilled]: (state, action) => {
            return { ...state, classification: null, classificationAPI_id: null, classificationFormulation: null, classificationFormulation_id: null };
        }
    }
});

export default plantLab.reducer
