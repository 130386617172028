import React, { useState, useRef, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Row,
  Col,
  Button,
  Form,
  Divider,
  Radio,
  Upload,
  DatePicker,
  Input,
  Select,
  Space,
  Breadcrumb,
  Table,
  Modal,
  Tooltip,
} from "antd";
import {
  CloseCircleOutlined,
  PlusCircleOutlined,
  UploadOutlined,
  DeleteOutlined,
  EyeOutlined,
  DownloadOutlined,
  FundViewOutlined,
} from "@ant-design/icons";
import "react-tooltip/dist/react-tooltip.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../authentication/Auth.scss";
import "../../styles/Main.scss";
import "antd/dist/antd.css";
import moment from "moment";
import { deleteRequest, getRequest, patchRequest } from "../../axios";
import InputField from "../../common/htmlComponents/InputField";
import TextAreaField from "../../common/htmlComponents/TextAreaField";
import CountryWithFlag from "../../common/htmlComponents/CountryWithFlag/CountryWithFlag";
import EmailField from "../../common/htmlComponents/EmailField";
import NumberField from "../../common/htmlComponents/NumberField";
import ButtonField from "../../common/htmlComponents/ButtonField";
import { getAllCountry } from "../../common/htmlComponents/CountryWithFlag/countrySlice";
import dummyCert from "../../images/checked.png";

import APIcapacity from "../manufacturingFacility/APIcapacity";
import FormulationCapacity from "../manufacturingFacility/FormulationCapacity";
import {
  // getManufacturingFormulation,
  // getManufacturingCapacityTotal,
  getManufacturingCapacity,
  getManufacturingFormulation,
  getManufacturingCapacityTotal,
} from "../manufacturingFacility/ManageFacilitySlice";
import {
  CompanyAdmin,
  SuperAdminOrSubAdmin,
  authUserData,
} from "../../utils/Helper";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/Notifications";
import config from "../../Config";
import { useUnsavedChanges } from "../UnsavedChangesProvider";

const MyCompany = () => {
  const { t } = useTranslation();
  const formRef = useRef();
  const dispatch = useDispatch();
  const { unsavedChanges, setShowModal, setClickedPath, setUnsavedChanges } =
    useUnsavedChanges();
  function getBase64(file, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  }

  const initialState = {
    company_name: null,
    address_line_1: null,
    address_line_2: null,
    city: null,
    landmark: null,
    state: null,
    country: null,
    pincode: null,
    website: null,
    person_name: null,
    designation: null,
    email: null,
    phone: null,
    ho_email: null,
    ho_contact_number: null,
    company_registration_no: null,
    gst_no: null,
    company_corporate_presentation: null,
    about_company: null,
    company_established_date: null,
    no_of_facilities: null,
    no_of_employees: "1-10 employees",
    key_person: [
      {
        person_name: null,
        email: null,
        designation: null,
        contact_number: null,
      },
    ],
    manufacturing_country: [],
    supplying_country: [],
    video_url: null,
    inquiry_consent: false,
    data_store_consent: false,
  };
  const [state, setState] = useState(initialState);
  const [btnLoading, setBtnLoading] = useState(false);
  const [tableState, setTableState] = useState({
    page: 1,
    ordering: "-id",
    pagesize: 10,
    search: "",
    total: 0,
  });
  const [showDiv, setShowDiv] = useState("link");
  const [size, setSize] = useState(null);
  const [uploadFile, setUploadFile] = useState("");
  const [fileName, setFileName] = useState(null);
  const [fileLink, setFileLink] = useState(null);

  const { companyId } = useParams();
  const ID = companyId ? atob(companyId) : null;

  const id = ID ? ID : authUserData()?.company_id;

  useEffect(() => {
    dispatch(getAllCountry());
    const getCompanyDetail = async (id) => {
      try {
        const apiResponse = await getRequest("company/details/" + id);
        if (apiResponse.data.success) {
          formRef.current.setFieldsValue({
            ...apiResponse.data.data,
            company_established_date: apiResponse.data.data
              .company_established_date
              ? moment(
                  apiResponse.data.data.company_established_date,
                  "DD-MM-YYYY"
                )
              : null,
            manufacturing_country:
              apiResponse.data.data.manufacturing_country.map(function (el) {
                return el.id;
              }),
            supplying_country: apiResponse.data.data.supplying_country.map(
              function (el) {
                return el.id;
              }
            ),
          });
          setWeightSizeFieldValue(apiResponse.data.data.key_person);
          setFileLink(apiResponse.data.data.company_corporate_presentation);
          setState({
            ...apiResponse.data.data,
            pincode: String(apiResponse.data.data.pincode),
            company_established_date: apiResponse.data.data
              .company_established_date
              ? moment(
                  apiResponse.data.data.company_established_date,
                  "DD-MM-YYYY"
                ).format("DD-MM-YYYY")
              : null,
            manufacturing_country:
              apiResponse.data.data.manufacturing_country.map(function (el) {
                return el.id;
              }),
            supplying_country: apiResponse.data.data.supplying_country.map(
              function (el) {
                return el.id;
              }
            ),
          });
        } else {
          ErrorNotificationMsg(t("err_getcompanyDetail"));
        }
      } catch (error) {
        ErrorNotificationMsg(error);
      }
    };
    if (id !== null) {
      getCompanyDetail(id);
    }
  }, [dispatch, id, t]);

  const url = state.company_corporate_presentation;

  let filename = "";
  try {
    if (fileLink) {
      filename = url.split("/").pop();
    }
  } catch (e) {
    console.error(e);
  }

  const handleNavigate = (to, event) => {
    const path = typeof to === "string" ? to : to.pathname;
    if (
      unsavedChanges.outerClickChild1 === true ||
      unsavedChanges.outerClickChild2 === true
    ) {
      event.preventDefault();
      setShowModal(true);
      setClickedPath(path);
    }
  };

  const handleRadioChange = (showDiv) => {
    setShowDiv(showDiv.target.value ? showDiv.target.value : showDiv);
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value ? value : null });
  };
  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const handleDatepickerChange = (date, dateString, fieldName) => {
    setState({ ...state, [fieldName]: dateString ? dateString : null });
  };
  const handleFormChange = (changedValues, allValues) => {
    // Check if the changed values include the fields you want to track

    if (
      "company_name" in changedValues ||
      "country" in changedValues ||
      "person_name" in changedValues ||
      "designation" in changedValues ||
      "email" in changedValues ||
      "ho_email" in changedValues ||
      "ho_contact_number" in changedValues ||
      "company_registration_no" in changedValues ||
      "gst_no" in changedValues ||
      "phone" in changedValues ||
      "person_name0" in changedValues ||
      "designation0" in changedValues ||
      "email0" in changedValues ||
      "contact_number0" in changedValues ||
      "person_name1" in changedValues ||
      "designation1" in changedValues ||
      "email1" in changedValues ||
      "contact_number1" in changedValues ||
      "person_name2" in changedValues ||
      "designation2" in changedValues ||
      "email2" in changedValues ||
      "contact_number2" in changedValues ||
      "no_of_employees" in changedValues ||
      "address_line_1" in changedValues ||
      "address_line_2" in changedValues ||
      "city" in changedValues ||
      "landmark" in changedValues ||
      "state" in changedValues ||
      "pincode" in changedValues ||
      "website" in changedValues ||
      // Add more fields to track here if needed
      "company_established_date" in changedValues ||
      "no_of_facilities" in changedValues ||
      "manufacturing_country" in changedValues ||
      "supplying_country" in changedValues ||
      "video_url" in changedValues ||
      "about_company" in changedValues ||
      "person_name3" in changedValues ||
      "designation3" in changedValues ||
      "email3" in changedValues ||
      "contact_number3" in changedValues ||
      "person_name4" in changedValues ||
      "designation4" in changedValues ||
      "email4" in changedValues ||
      "contact_number4" in changedValues ||
      "person_name5" in changedValues ||
      "designation5" in changedValues ||
      "email5" in changedValues ||
      "contact_number5" in changedValues ||
      "person_name6" in changedValues ||
      "designation6" in changedValues ||
      "email6" in changedValues ||
      "contact_number6" in changedValues ||
      "person_name7" in changedValues ||
      "designation7" in changedValues ||
      "email7" in changedValues ||
      "contact_number7" in changedValues
    ) {
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        outerClickChild1: true,
        // outerClickChild2: false,
      }));
    }
  };
  const uploadProps = {
    accept: ".pdf,.pptx,.ppt,doc,.docx",
    showUploadList: false,
    beforeUpload: (file) => {
      setUploadFile(file);
      getBase64(file, async (fileUrl) => {
        setSize(file.size);
        // if (file.type !== "application/pdf") {
        //   ErrorNotificationMsg(
        //     "This file is not an accepted format. You can upload .pdf file format only"
        //   );
        // }
        if (file.size <= 62914560) {
          await setState((prevState) => ({
            ...prevState,
            company_corporate_presentation: fileUrl,
          }));
          setFileName(file.name);
          setUnsavedChanges((prevTab) => ({
            ...prevTab,
            outerClickChild1: true,
            // outerClickChild2: true,
          }));
        } else {
          await setState((prevState) => ({
            ...prevState,
            company_corporate_presentation: null,
          }));
          setFileName(null);
        }
      });
      return false;
    },
  };

  const handleDelete = () => {
    setState((prevState) => ({
      ...prevState,
      company_corporate_presentation: null,
    }));
    setFileName(null);
    setSize(null);
    setUnsavedChanges((prevTab) => ({
      ...prevTab,
      outerClickChild1: true,
      // outerClickChild2: true,
    }));
  };

  const handleFileDelete = async () => {
    let id = authUserData()?.company_id;
    const apiResponse = await deleteRequest(
      `company/delete-presentation/${id}/`
    );
    if (apiResponse.data.success) {
      setFileLink(null);
      setSize(null);
    }
  };

  const addPerson = () => {
    let newPersonArr = [
      ...state.key_person,
      {
        person_name: null,
        email: null,
        designation: null,
        contact_number: null,
      },
    ];
    setState({ ...state, key_person: newPersonArr });
    setWeightSizeFieldValue(newPersonArr);
  };

  const removePerson = async (lastIndex) => {
    let filterPersonArr = state.key_person.filter(
      (item, index) => index !== lastIndex
    );
    setState({ ...state, key_person: filterPersonArr });
    setWeightSizeFieldValue(filterPersonArr);
  };

  const handleCloneInputChange = (field, e, index) => {
    let newPersonArr = state.key_person;
    newPersonArr[index][field] = e.target.value ? e.target.value : null;
    setState({ ...state, key_person: newPersonArr });
  };

  const handleClonePhoneInputChange = (field, phone, index) => {
    let newPersonArr = state.key_person;
    newPersonArr[index][field] = phone ? phone : null;
    setState({ ...state, key_person: newPersonArr });
  };

  const setWeightSizeFieldValue = (data) => {
    let stateArr = [];
    let fieldObject = {};
    data.map((objectValue, objectKey) => {
      stateArr.push(objectValue);
      fieldObject["person_name" + objectKey] = objectValue.person_name;
      fieldObject["email" + objectKey] = objectValue.email;
      fieldObject["designation" + objectKey] = objectValue.designation;
      fieldObject["contact_number" + objectKey] = objectValue.contact_number;
      return null;
    });
    formRef.current.setFieldsValue(fieldObject);
  };

  // const validatePhoneNumber = (_, value) => {
  //   if (!value || value.toString().length < 8) {
  //     return Promise.reject(new Error("Please enter a valid phone."));
  //   }
  //   return Promise.resolve();
  // };
  const phoneValidLength = state.ho_contact_number;

  const validatePhoneNumber = () => {
    if (
      (phoneValidLength && phoneValidLength.length < 8) ||
      phoneValidLength === "" ||
      phoneValidLength === null
    ) {
      return Promise.reject(new Error("Please enter valid phone."));
    }
    return Promise.resolve();
  };

  const handleSubmit = async () => {
    setBtnLoading(true);
    const payload = {
      company_name: state.company_name,
      address_line_1: state.address_line_1,
      address_line_2: state.address_line_2,
      city: state.city,
      landmark: state.landmark,
      state: state.state,
      country: state.country,
      pincode: state.pincode,
      website: state.website,
      person_name: state.person_name,
      designation: state.designation,
      email: state.email,
      phone: state.phone,
      ho_email: state.ho_email,
      ho_contact_number: state.ho_contact_number,
      company_registration_no: state.company_registration_no,
      gst_no: state.gst_no,
      about_company: state.about_company,
      company_established_date: state.company_established_date
        ? moment(state.company_established_date, "DD-MM-YYYY").format(
            "DD-MM-YYYY"
          )
        : null,
      no_of_facilities: state.no_of_facilities,
      no_of_employees: state.no_of_employees,
      key_person: state.key_person,
      manufacturing_country: state.manufacturing_country,
      supplying_country: state.supplying_country,
      video_url: state.video_url,
      inquiry_consent: state.inquiry_consent,
      data_store_consent: state.data_store_consent,
    };
    try {
      let customerHeaders = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const formData = new FormData();

      formData.append("company_corporate_presentation", uploadFile);
      formData.append("form_data", JSON.stringify(payload));
      const apiResponse = await patchRequest(
        `company/details/${id}/`,
        formData,
        customerHeaders
      );
      if (apiResponse.data.status) {
        SuccessNotificationMsg(t("updatedCompanyDetailSuccessful"));
        setUnsavedChanges((prevTab) => ({
          ...prevTab,
          outerClickChild1: false,
          // outerClickChild2: false,
        }));
      } else {
        ErrorNotificationMsg("Error in company detail");
      }
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
    }
  };

  const [facilityList, setFacilityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState();

  const [capacityId, setCapacityId] = useState(null);
  const [formulationId, setFormulationId] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenb, setIsModalOpenb] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelb = () => {
    setIsModalOpenb(false);
  };

  useEffect(() => {
    setLoading(true);
    const getFacility = async (data) => {
      const apiResponse = await getRequest(
        `facility/list-create?company_id=${id}`,
        data
      );
      setFacilityList(apiResponse.data.results);
      setCount(apiResponse.data.count);
      setLoading(false);
    };
    try {
      getFacility(tableState);
    } catch (error) {
      ErrorNotificationMsg(t("err_getFacility"));
    }
  }, [dispatch, tableState, t]); //Remove state from the dependency array

  useEffect(() => {
    if (capacityId !== null) {
      // dispatch(getManufacturingCapacityTotal(capacityId));
      // dispatch(
      //   getManufacturingCapacityTotal({
      //     id: capacityId,
      //   })
      // );
      dispatch(getManufacturingCapacityTotal(capacityId));
      dispatch(
        getManufacturingCapacity({
          id: capacityId,
        })
      );
    }
  }, [dispatch, capacityId]);

  // useEffect(() => {
  //   if (formulationId !== null) {
  //     dispatch(
  //       getManufacturingFormulation({
  //         id: formulationId,
  //       })
  //     );
  //   }
  // }, [dispatch, formulationId]);
  useEffect(() => {
    if (formulationId !== null) {
      dispatch(
        getManufacturingFormulation({
          id: formulationId,
        })
      );
    }
  }, [dispatch, formulationId]);

  const handleTableChange = async (pagination, filters, sorter, ordering) => {
    const orderBy = GetSortingEnumValue(sorter.columnKey, sorter.order);

    setTableState((prevState) => ({
      ...prevState,
      page: pagination.current,
      pagesize: pagination.pageSize,
      ordering: orderBy,
    }));
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = "id";

    if (sortField === "company" && sortOrder === "ascend") {
      sortingEnumKey = "company__company_name";
    }
    if (sortField === "company" && sortOrder === "descend") {
      sortingEnumKey = "-company__company_name";
    }

    if (sortField === "facility_location" && sortOrder === "ascend") {
      sortingEnumKey = "facility_location";
    }
    if (sortField === "facility_location" && sortOrder === "descend") {
      sortingEnumKey = "-facility_location";
    }

    if (sortField === "facility_type" && sortOrder === "ascend") {
      sortingEnumKey = "facility_type";
    }
    if (sortField === "facility_type" && sortOrder === "descend") {
      sortingEnumKey = "-facility_type";
    }

    return sortingEnumKey;
  };
  const colors = ["#D9E0E2"];
  const columns = [
    {
      title: "Company name",
      dataIndex: "company",
      key: "company",
      sorter: true,
      render: (e, res) => res.company.company_name,
    },
    {
      title: "Location",
      dataIndex: "facility_location",
      key: "facility_location",
      sorter: true,
    },
    {
      title: "Facility Type",
      dataIndex: "facility_type",
      key: "facility_type",
      sorter: true,
    },
    // {
    //   title: "Manufacturing Capacity",
    //   dataIndex: "ManufacturingCapacity",
    //   key: "Manufacturing Capacity",
    //   render: (e, res) => (
    //     <button
    //       onClick={(e) => {
    //         setCapacityId(res.id);
    //         setIsModalOpen(true);
    //       }}
    //       className="link-color"
    //       style={{
    //         background: "none",
    //         border: "none",
    //         padding: 0,
    //         cursor: "pointer",
    //         color: "#022f4",
    //       }}
    //     >
    //       {res.facility_type === "API" || res.facility_type === "CDMO" ? (
    //         <>{t("detail")}</>
    //       ) : (
    //         ""
    //       )}
    //     </button>
    //   ),
    // },
    // {
    //   title: "Formulation Capacity",
    //   dataIndex: "FormulationCapacity",
    //   key: "Formulation Capacity",
    //   render: (e, res) => (
    //     <button
    //       onClick={(e) => {
    //         setFormulationId(res.id);
    //         setIsModalOpenb(true);
    //       }}
    //       className="link-color"
    //       style={{
    //         background: "none",
    //         border: "none",
    //         padding: 0,
    //         cursor: "pointer",
    //         color: "#022f4",
    //       }}
    //     >
    //       {res.facility_type === "Formulation" ||
    //       res.facility_type === "CDMO" ? (
    //         <>{t("detail")}</>
    //       ) : (
    //         ""
    //       )}
    //     </button>
    //   ),
    // },

    {
      title: "Product Portfolio",
      dataIndex: "ProductPortfolio",
      key: "Product Portfolio",
      render: (e, res) => (
        <>
          <button
            onClick={(e) => {
              setCapacityId(res.id);
              setIsModalOpen(true);
            }}
            className="link-color"
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
              color: "#022f4",
            }}
          >
            {res.facility_type === "API" || res.facility_type === "CDMO" ? (
              // <>{t("detail")}</>
              <>
                <FundViewOutlined />
                API Quick View
                {"\u00A0"} {"\u00A0"} {"\u00A0"} {/* Non-breaking space */}
              </>
            ) : (
              ""
            )}
          </button>
          <button
            onClick={(e) => {
              setFormulationId(res.id);
              setIsModalOpenb(true);
            }}
            className="link-color"
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
              color: "#022f4",
            }}
          >
            {res.facility_type === "Formulation" ||
            res.facility_type === "CDMO" ? (
              <>
                <FundViewOutlined />
                Formulation Quick View
              </>
            ) : (
              ""
            )}
          </button>
        </>
      ),
    },
    {
      title: "Certification",
      key: "facility_regulator",
      dataIndex: "facility_regulator",
      render: (res) => (
        <Space size={[12, 16]} className="certifycol">
          {res.map(function (cValue, idx) {
            return (
              <>
                <div className="countrynm" key={cValue.id}>
                  <Tooltip
                    color={colors}
                    title={
                      <span style={{ color: "#000000" }}>
                        {cValue.certification}
                      </span>
                    }
                  >
                    <img
                      width="120"
                      height="40"
                      src={
                        cValue.certification_icon
                          ? `${config.BUCKET_URL}/certificate-files/${cValue.certification_icon}`
                          : dummyCert
                      }
                      alt={cValue.certification}
                    />
                  </Tooltip>
                </div>
              </>
            );
          })}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "Action",
      dataIndex: "Action",
      render: (e, res) => (
        <Space size={[12, 16]}>
          <Space size="middle">
            {authUserData()?.user_permission.includes(
              "facility.view_facility"
            ) && (
              ID !== null?
              <Link
                className="action"
                to={{
                  pathname: "/manage-facility/view-facility/" + btoa(res.id)+"/manage-company",
                }}
                target="_blank"
              >
                <Tooltip
                  color={colors}
                  title={
                    <span style={{ color: "#000000" }}>
                      {" "}
                      View your listed manufacturing facility with given
                      details.
                    </span>
                  }
                >
                  <EyeOutlined />
                </Tooltip>
              </Link>
              :
              <Link
              className="action"
              to={{
                pathname: "/manage-facility/view-facility/" + btoa(res.id)+"/my-company",
              }}
              target="_blank"
            >
              <Tooltip
                color={colors}
                title={
                  <span style={{ color: "#000000" }}>
                    {" "}
                    View your listed manufacturing facility with given
                    details.
                  </span>
                }
              >
                <EyeOutlined />
              </Tooltip>
            </Link>
            )}
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <div className="contentpart">
      <div className="right_content_col">
        <div className="content_wraper ">
          <div className="mycompany">
            <div className="pagename">
              <Tooltip
                color={colors}
                title={
                  <span style={{ color: "#000000" }}>
                    {" "}
                    Register your company and explore a world of opportunities.
                  </span>
                }
              >
                {t("myCompany")}
              </Tooltip>

              <Breadcrumb>
                <Breadcrumb.Item>
                  {SuperAdminOrSubAdmin && (
                    <Link
                      to="/dashboard"
                      onClick={(event) => handleNavigate("/dashboard", event)}
                    >
                      {t("home")}{" "}
                    </Link>
                  )}
                  {CompanyAdmin && (
                    <Link
                      to="/business-lounge"
                      onClick={(event) =>
                        handleNavigate("/business-lounge", event)
                      }
                    >
                      {t("home")}
                    </Link>
                  )}
                </Breadcrumb.Item>
                {SuperAdminOrSubAdmin && (
                <Breadcrumb.Item>
             
                    <Link
                      to="/manage-company"
                      onClick={(event) => handleNavigate("/manage-company", event)}
                    >
                      {t("Manage Company")}{" "}
                    </Link>
               
                </Breadcrumb.Item>
              )}
                <Breadcrumb.Item>{t("myCompany")}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <br />
            <Form
              ref={formRef}
              onValuesChange={handleFormChange}
              onFinish={handleSubmit}
              autoComplete="off"
              initialValues={{
                company_name: state.company_name
                  ? state.company_name
                  : authUserData()?.company_name,
                country: state.country
                  ? state.country
                  : authUserData()?.country,
                person_name: state.person_name
                  ? state.person_name
                  : authUserData()?.first_name,
                designation:
                  state.designation !== null
                    ? state.designation
                    : authUserData()?.designation,
                email: state.email ? state.email : authUserData()?.email,
                phone: state.phone ? state.phone : authUserData()?.phone,
                no_of_employees: state.no_of_employees,
              }}
            >
              <Row gutter={[15]}>
                <Col xs={24} sm={12} lg={6}>
                  <InputField
                    name="company_name"
                    label={t("pl_companyName")}
                    placeholder={t("pl_companyName")}
                    rules={t("val_companyName")}
                    handleInputChange={handleInputChange}
                    value={state.company_name}
                    readOnly={true}
                  />
                </Col>
              </Row>
              <Row gutter={[15]}>
                <Col xs={24} sm={24} lg={24}>
                  <h5 className="formtitle">{t("pl_corporateAddress")} </h5>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <InputField
                    name="address_line_1"
                    label={t("pl_addressLine1")}
                    placeholder={t("pl_addressLine1")}
                    rules={t("val_addressLine")}
                    // pattern={"^[a-zA-Z0-9s, '-/]*$"}
                    pattern={
                      "^[a-zA-Z0-9\\s,'\\-/!@#$%^&*()_+=\\[\\]{}|\\\\:;\"'<>,.?]*$"
                    }
                    handleInputChange={handleInputChange}
                    value={state.address_line_1}
                    max={150}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <InputField
                    name="address_line_2"
                    label={t("pl_addressLine2")}
                    placeholder={t("pl_addressLine2")}
                    nonRequiredRules={t("val_addressLine")}
                    // pattern={"^[a-zA-Z0-9s, '/-]*$"}
                    pattern={
                      "^[a-zA-Z0-9\\s,'\\-/!@#$%^&*()_+=\\[\\]{}|\\\\:;\"'<>,.?]*$"
                    }
                    handleInputChange={handleInputChange}
                    value={state.address_line_2}
                    max={150}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <InputField
                    name="city"
                    label={t("pl_city")}
                    placeholder={t("pl_city")}
                    rules={t("val_city")}
                    handleInputChange={handleInputChange}
                    value={state.city}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <InputField
                    name="landmark"
                    label={t("pl_landmark")}
                    placeholder={t("pl_landmark")}
                    handleInputChange={handleInputChange}
                    value={state.landmark}
                    max={30}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <InputField
                    name="state"
                    label={t("pl_state")}
                    placeholder={t("pl_state")}
                    rules={t("val_state")}
                    handleInputChange={handleInputChange}
                    value={state.state}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <CountryWithFlag
                    name="country"
                    label={t("pl_country")}
                    rules={t("val_country")}
                    value={state.country}
                    handleSelectChange={handleSelectChange}
                    disabled={true}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item
                    name="pincode"
                    label={t("pl_pincode")}
                    placeholder={t("pl_pincode")}
                    rules={[
                      {
                        required: true,
                        // pattern: "^[1-9][0-9]{5}$",
                        pattern: "^[a-zA-Z0-9\\s]+$",
                        max: 30,
                        message: <Trans>{t("val_pincode")}</Trans>,
                      },
                    ]}
                  >
                    <Input
                      name="pincode"
                      placeholder={t("pl_pincode")}
                      onChange={(value) =>
                        setState({ ...state, pincode: value.target.value })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <InputField
                    name="website"
                    label={t("pl_website")}
                    placeholder={t("pl_website")}
                    rules={t("val_website")}
                    // pattern={
                    //   "^((https?|ftp|smtp)://)?(www.)?[a-z0-9]+.[a-z]+(/[a-zA-Z0-9#]+/?)*$"
                    // }
                    handleInputChange={handleInputChange}
                    value={state.website}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <InputField
                    name="person_name"
                    label={t("pl_personName")}
                    placeholder={t("pl_personName")}
                    rules={t("val_personName")}
                    handleInputChange={handleInputChange}
                    value={state.person_name}
                    max={30}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <InputField
                    name="designation"
                    label={t("pl_designation")}
                    placeholder={t("pl_designation")}
                    rules={t("val_designation")}
                    handleInputChange={handleInputChange}
                    value={state.designation}
                    max={30}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <EmailField
                    name="email"
                    label={t("pl_email")}
                    rules={t("val_email")}
                    handleInputChange={handleInputChange}
                    value={state.email}
                    readOnly={true}
                  />
                </Col>
                <Col xs={24} md={12} lg={6}>
                  <Form.Item
                    name="phone"
                    label={t("whatsappNo")}
                    rules={[
                      {
                        required: true,
                        message: <Trans> {t("val_phone")}</Trans>,
                      },
                    ]}
                  >
                    <PhoneInput
                      country={"in"}
                      value={state.phone}
                      onChange={(phone) => setState({ ...state, phone: phone })}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <EmailField
                    name="ho_email"
                    label={t("pl_headOffice_email")}
                    placeholder={t("pl_headOffice_email")}
                    rules={t("val_email")}
                    handleInputChange={handleInputChange}
                    value={state.ho_email}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item
                    name="ho_contact_number"
                    label={t("pl_headOffice_contactNo")}
                    rules={[
                      {
                        required: true,
                        validator: validatePhoneNumber,
                        message: <Trans> {t("val_phone")}</Trans>,
                      },
                    ]}
                  >
                    <PhoneInput
                      country={"in"}
                      value={state.ho_contact_number}
                      onChange={(phone) =>
                        setState({ ...state, ho_contact_number: phone })
                      }
                    />
                  </Form.Item>
                  {/* <NumberField
                    name="ho_contact_number"
                    label={t("pl_headOffice_contactNo")}
                    placeholder={t("pl_headOffice_contactNo")}
                    rules={t("val_phone")}
                    pattern={"^[0-9]{8,10}$"}
                    // validatePhoneNumber={validatePhoneNumber}
                    handleInputChange={handleInputChange}
                    value={state.ho_contact_number}
                  /> */}
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <NumberField
                    name="company_registration_no"
                    label={t("pl_company_RegistrationNo")}
                    placeholder={t("pl_company_RegistrationNo")}
                    handleInputChange={handleInputChange}
                    value={state.company_registration_no}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <NumberField
                    name="gst_no"
                    label={t("pl_gstNo")}
                    placeholder={t("pl_gstNo")}
                    nonRequiredRules={t("val_gstNo")}
                    pattern={
                      "^[0-9]{2}[A-Z]{5}[0-9]{4}" +
                      "[A-Z]{1}[1-9A-Z]{1}" +
                      "Z[0-9A-Z]{1}$"
                    }
                    handleInputChange={handleInputChange}
                    value={state.gst_no}
                  />
                </Col>
                <Col xs={24} sm={24} lg={24}>
                  <h5 className="formtitle">{t("companyKeypersons")}</h5>
                </Col>
              </Row>
              {state.key_person.map((x, index) => {
                return (
                  <Row key={index} className="customcol" gutter={[15]}>
                    <Col xs={24} sm={12} md={5}>
                      <Form.Item
                        name={`person_name` + index}
                        label={t("pl_personName")}
                        rules={[
                          {
                            min: 2,
                            message: <Trans>{t("minlength_msg")}</Trans>,
                          },
                          {
                            max: 30,
                            message: <Trans>{t("maxlength_personName")}</Trans>,
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("pl_personName")}
                          onChange={(value) =>
                            handleCloneInputChange("person_name", value, index)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={5}>
                      <Form.Item
                        name={`designation` + index}
                        label={t("pl_designation")}
                        rules={[
                          {
                            min: 2,
                            message: <Trans>{t("minlength_msg")}</Trans>,
                          },
                          {
                            max: 30,
                            message: (
                              <Trans>{t("maxlength_designation")}</Trans>
                            ),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("pl_designation")}
                          onChange={(value) =>
                            handleCloneInputChange("designation", value, index)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={5}>
                      <Form.Item
                        name={`email` + index}
                        label={t("pl_email")}
                        rules={[
                          {
                            type: "email",
                            message: <Trans>{t("val_email")}</Trans>,
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("pl_email")}
                          onChange={(value) =>
                            handleCloneInputChange("email", value, index)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={5}>
                      <Form.Item
                        name={`contact_number` + index}
                        label={t("pl_ContactNo")}
                        rules={[
                          {
                            message: <Trans>{t("val_phone")}</Trans>,
                          },
                        ]}
                      >
                        <PhoneInput
                          country={"in"}
                          onChange={(phone) =>
                            handleClonePhoneInputChange(
                              "contact_number",
                              phone,
                              index
                            )
                          }
                        />
                      </Form.Item>
                      {/* <Form.Item
                        name={`contact_number` + index}
                        label={t("pl_ContactNo")}
                        rules={[
                          {
                            pattern: "^[0-9]{8,10}$",
                            message: <Trans>{t("val_phone")}</Trans>,
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("pl_ContactNo")}
                          onChange={(value) =>
                            handleCloneInputChange(
                              "contact_number",
                              value,
                              index
                            )
                          }
                        />
                      </Form.Item> */}
                    </Col>
                    <Col xs={24} sm={12} md={4}>
                      <div className="addwrp">
                        <Space>
                          {index !== 0 ? (
                            <CloseCircleOutlined
                              className="add"
                              onClick={() => removePerson(index)}
                            />
                          ) : (
                            ""
                          )}

                          <PlusCircleOutlined
                            className="add"
                            onClick={(e) => addPerson(e)}
                          />
                        </Space>
                      </div>
                    </Col>
                  </Row>
                );
              })}
              <Row gutter={[15]}>
                <Col xs={24} sm={24} lg={24}>
                  <h5 className="formtitle">
                    {t("company_corporatePresentation")}{" "}
                  </h5>
                </Col>
                <Col xs={24} sm={24} lg={24}>
                  <div className="uploadwrap">
                    <Radio.Group value={showDiv} onChange={handleRadioChange}>
                      <Radio value={"link"}>{t("videolink_URL")}</Radio>
                      <Radio value={"upload"}>{t("upload")}</Radio>
                    </Radio.Group>
                    {showDiv === "upload" && (
                      <>
                        <div className="uploadinside">
                          <Upload {...uploadProps}>
                            <Button type="primary" icon={<UploadOutlined />}>
                              {t("upload")}
                            </Button>
                            <div className="condition">
                              {" "}
                              <p
                                style={{
                                  color: size > 62914560 ? "red" : "GrayText",
                                }}
                              >
                                {t("upload_fileSize")} {t("upload_fileType")}
                              </p>
                              <br />
                            </div>
                          </Upload>
                          {size <= 62914560 && (
                            <>
                              <Space size={[12, 16]}>
                                <a
                                  href={fileLink}
                                  className="link-color"
                                  download
                                >
                                  {/* {fileLink ? <DownloadOutlined /> : ""}{" "} */}
                                  {fileLink ? filename : fileName}
                                </a>
                                {fileLink && (
                                  <DeleteOutlined
                                    onClick={handleFileDelete}
                                    className="delet"
                                  />
                                )}
                                {fileName && (
                                  <DeleteOutlined
                                    onClick={handleDelete}
                                    className="delet"
                                  />
                                )}
                              </Space>
                              <Space>
                                {fileLink ? (
                                  <a
                                    href={fileLink}
                                    className="ant-btn ant-btn-primary dwnload"
                                    download
                                  >
                                    {fileLink ? <DownloadOutlined /> : ""}{" "}
                                  </a>
                                ) : (
                                  ""
                                )}
                              </Space>
                            </>
                          )}
                        </div>
                      </>
                    )}
                    {showDiv === "link" && (
                      <>
                        <div className="videourl">
                          <Space size={[12, 16]}>
                            <InputField
                              name="video_url"
                              placeholder={t("pl_video_url")}
                              nonRequiredRules={t("val_video_url")}
                              pattern={
                                // "^(https?://)?(www.youtube.com|youtu.be)/.+$" ||
                                // "/^https?://(?:www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/" ||
                                // "/^[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/"
                                // "^https?://(?:www.)?.+$"
                                "^(https?|http)://(?:www.)?.+$"
                              }
                              handleInputChange={handleInputChange}
                              value={state.video_url}
                            />

                            {state.video_url && (
                              <a
                                href={state.video_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ant-btn ant-btn-primary"
                              >
                                Open Link
                              </a>
                            )}
                          </Space>
                        </div>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={[15]}>
                <Col xs={24} sm={24} lg={24}>
                  <TextAreaField
                    name="about_company"
                    label={t("pl_about_company")}
                    placeholder={t("pl_about_company")}
                    handleInputChange={handleInputChange}
                    value={state.about_company}
                    max={1000}
                    rows={4}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item
                    name="company_established_date"
                    label={t("pl_company_establishedDate")}
                  >
                    <DatePicker
                      inputReadOnly
                      format={"DD-MM-YYYY"}
                      onChange={(date, dateString) =>
                        handleDatepickerChange(
                          date,
                          dateString,
                          "company_established_date"
                        )
                      }
                      disabledDate={(current) => current.isAfter(moment())}
                      value={
                        state.company_established_date
                          ? state.company_established_date
                          : null
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item
                    name="no_of_facilities"
                    label={t("pl_no_of_manufacturing_facilities")}
                    rules={[
                      {
                        required: true,
                        message: (
                          <Trans>
                            {t("val_no_of_manufacturing_facilities")}
                          </Trans>
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("pl_no_of_manufacturing_facilities")}
                      value={state.no_of_facilities}
                      onChange={(value) =>
                        setState({
                          ...state,
                          no_of_facilities:value.target.value,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <Form.Item name="no_of_employees" label={t("employees")}>
                    <Select
                      showArrow
                      placeholder={t("employees")}
                      onChange={(value) =>
                        handleSelectChange("no_of_employees", value)
                      }
                    >
                      <Select.Option value="1-10 employees">
                        1-10 employees
                      </Select.Option>
                      <Select.Option value="11-50 employees">
                        11-50 employees
                      </Select.Option>
                      <Select.Option value="51-200 employees">
                        51-200 employees
                      </Select.Option>
                      <Select.Option value="201-500 employees">
                        201-500 employees
                      </Select.Option>
                      <Select.Option value="501-1000 employees">
                        501-1000 employees
                      </Select.Option>
                      <Select.Option value="1001-5000 employees">
                        1001-5000 employees
                      </Select.Option>
                      <Select.Option value="5001-10000 employees">
                        5001-10000 employees
                      </Select.Option>
                      <Select.Option value="10000+ employees">
                        10000+ employees
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[15]}>
                <Col xs={24} sm={12} lg={6}>
                  <CountryWithFlag
                    mode="multiple"
                    name="manufacturing_country"
                    placeholder={t("pl_manufacturing_country")}
                    label={t("pl_manufacturing_country")}
                    rules={t("val_manufacturing_country")}
                    handleSelectChange={handleSelectChange}
                    value={state.manufacturing_country}
                  />
                </Col>
                <Col xs={24} sm={12} lg={6}>
                  <CountryWithFlag
                    mode="multiple"
                    name="supplying_country"
                    placeholder={t("pl_supplying_country")}
                    label={t("pl_supplying_country")}
                    rules={t("val_supplying_country")}
                    handleSelectChange={handleSelectChange}
                    value={state.supplying_country}
                  />
                </Col>
              </Row>

              <Row gutter={[15]} className="bottomButtons">
                <Col xs={24} align="end" className="text-right">
                  <ButtonField
                    type="primary"
                    htmlType="submit"
                    loading={btnLoading}
                    label={t("submit")}
                  />
                </Col>
              </Row>
              <Divider />
            </Form>
            <br />
            {authUserData()?.user_permission.includes(
              "facility.view_facility"
            ) && (
              <Table
                className="table_grid"
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={facilityList}
                scroll={{ x: 1200 }}
                loading={loading}
                pagination={{
                  defaultCurrent: state.page,
                  defaultPageSize: state.pagesize,
                  total: count,
                  showSizeChanger: true,
                }}
                onChange={handleTableChange}
              />
            )}
            {/* </Form> */}
          </div>

          <Modal
            title="API"
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
            width={900}
            className="APImodel"
          >
            <APIcapacity capacityId={capacityId} />
          </Modal>

          <Modal
            title="Formulation"
            open={isModalOpenb}
            footer={null}
            onCancel={handleCancelb}
            width={600}
            className="APImodel"
          >
            <FormulationCapacity formulationId={formulationId} />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default MyCompany;
