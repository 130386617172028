import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, patchRequest, postRequest } from "../../../../../../axios";

export const getAllReactionCapability = createAsyncThunk("all_reaction_capability", async () => {
    const response = await getRequest("manufacturing-reaction-capability/create");
    return response.data;
});

export const addReactionCapability = createAsyncThunk("add_reaction_capability", async (state) => {
    const response = await postRequest("manufacturing-reaction-capability/create/", state);
    return response.data;
});

export const deleteReactionCapability = createAsyncThunk("delete_reaction_capability", async (id) => {
    const response = await deleteRequest("manufacturing-reaction-capability/update-delete/" + id);
    return response.data;
});

export const addFacilityAPI = createAsyncThunk("add_facility_api", async (state) => {
    const response = await postRequest(`facility/list-create-api/${state.id}`, state);
    return response.data;
});

export const getFacilityAPIId = createAsyncThunk("get_facility_api_id", async (id) => {
    const response = await getRequest("facility/manage-facility-api/" + id);
    return response.data;
});

export const updateFacilityAPI = async ({ id, manufacturing_capacity, total_qty, facility_reaction_capability }) => {
    const response = await patchRequest(`facility/update-delete-api/${id}`, { manufacturing_capacity, total_qty, facility_reaction_capability });
    return response.data;
};

export const resetAfterFacilityAPIId = createAsyncThunk("reset_facility_api_id", async (reset) => {
    return reset;
});

const initialState = {
    apiLoading: false,
    reaction_capabilityList: [],
    facilityAPI: [],
    facility_api_id: null,
    plant_lab_api_id: null,
    utility_api_id: null,
    sub_utility_api_id: [],
    waterTreatment_api_id: null
}

const facilityAPI = createSlice({
    name: "facilityAPI",
    initialState,
    reducers: {},
    extraReducers: {

        // get reaction capabilityList 

        [getAllReactionCapability.fulfilled]: (state, action) => {
            return { ...state, apiLoading: false, reaction_capabilityList: action.payload.data };
        },

        // add reaction capability

        [addReactionCapability.fulfilled]: (state, action) => {
            state.btnnLoading = false;
            state.type = action.type;
        },

        // delete reaction capability

        [deleteReactionCapability.fulfilled]: (state, action) => {
            state.btnnLoading = false;
            state.type = action.type;
            state.user = state.reaction_capabilityList.filter((i) => i.id !== action.payload.id)
        },

        // add Facility API

        [addFacilityAPI.fulfilled]: (state, action) => {
            state.btnLoading = false;
            state.type = action.type;
            state.facility_api_id = action.payload.data.id
            state.plantLab_api_id = action.payload.data.plantlab_id
            state.utility_api_id = action.payload.data.utility_id
            state.sub_utility_api_id = action.payload.data.sub_utility_id
            state.waterTreatment_api_id = action.payload.data.water_treatment_id
        },

        // get Facility API Id

        [getFacilityAPIId.fulfilled]: (state, action) => {
            state.apiLoading = false
            state.facilityAPI = action.payload
            state.facility_api_id = action.payload[0].id
            state.plant_lab_api_id = action.payload[0].plant_lab_id
            state.utility_api_id = action.payload[0].utility_id
            state.sub_utility_api_id = action.payload[0].sub_utility_id
            state.waterTreatment_api_id = action.payload[0].water_treatment_id
        },

        // resetAfterFacilityAPIId

        [resetAfterFacilityAPIId.fulfilled]: (state, action) => {
            state.reaction_capabilityList = []
            state.facilityAPI = []
            state.facility_api_id = null
            state.plant_lab_api_id = null
            state.utility_api_id = null
            state.sub_utility_api_id = []
            state.waterTreatment_api_id = null
        }
    }
});

export default facilityAPI.reducer
