import React from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { Trans, useTranslation } from "react-i18next";

const EmailField = (props) => {
  const { t } = useTranslation();
  let fieldRequired = false;

  if (props.rules) {
    fieldRequired = true;
  }

  return (
    <>
      <Form.Item
        name={props.name}
        label={props.label}
        rules={[
          {
            required: fieldRequired,
            type: "email",
            message: <Trans>{props.rules}</Trans>,
          },
        ]}
      >
        <Input
          name={props.name}
          placeholder={props.placeholder ? props.placeholder : t("pl_email")}
          onChange={props.handleInputChange}
          className={props.className}
          disabled={props.disabled}
          readOnly={props.readOnly}
          prefix={<MailOutlined className="site-form-item-icon" />}
          value={props.value ? props.value : null}
        />
      </Form.Item>
    </>
  );
};

EmailField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.string,
  placeholder: PropTypes.string,
  handleInputChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  prefix: PropTypes.node, // Assuming it's a React node, adjust the type if needed
  value: PropTypes.string, // Adjust the type if needed
};

export default EmailField;
