import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import ButtonField from "../../../../../common/htmlComponents/ButtonField";

const ConfirmAlertModel = ({ handleCancelc, handleTableChange }) => {
  const { t } = useTranslation();

  const backToList = () => {
    handleCancelc();
  };
  const handleNextPage = () => {
    // const { currenttab } = currentTab;
    // let showTabName = componentArr[currenttab + 1];
    // hideComponent(showTabName);
    handleTableChange();
    handleCancelc();
  };
  return (
    <>
      <p>{t("model_description_message")}</p>
      <Row gutter={[15]} className="bottomButtons">
        <Col xs={12} align="center">
          <ButtonField
            type="primary"
            htmlType=""
            // loading={btnLoading}
            label={t("button_back")}
            onClick={handleNextPage}
          />
        </Col>
        <Col xs={12} align="center">
          <ButtonField
            type="primary"
            htmlType=""
            // loading={btnLoading}
            label={t("button_save")}
            onClick={backToList}
          />
        </Col>
      </Row>
    </>
  );
};
ConfirmAlertModel.propTypes = {
  handleCancelc: PropTypes.func,
  handleTableChange: PropTypes.func,
};

export default ConfirmAlertModel;
