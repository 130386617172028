import React, { useEffect, useState } from "react";
import { Row, Col, Space, Table, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { DownloadOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { CSVLink } from "react-csv";
import "../../../styles/Main.scss";
import "antd/dist/antd.css";
import { getRequest } from "../../../axios";
import { ErrorNotificationMsg } from "../../../utils/Notifications";

const { Search } = Input;

const RegisteredUserNewsletter = () => {
  const { t } = useTranslation();

  const [unSubscribeduserList, setUnsubscribeduserList] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [apiLoading, setapiLoading] = useState(false);

  const initialState = {
    page: 1,
    ordering: "-id",
    pagesize: 10,
    search: "",
    total: 0,
  };
  const [state, setState] = useState(initialState);

  const headers = [
    { label: "Company", key: "company_name" },
    { label: "Name", key: "first_name" },
    { label: "Email", key: "email" },
  ];
  const csvReport = {
    data: unSubscribeduserList,
    headers: headers,
    filename: "Unregistered_User_Report.csv",
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = "-id";

    if (sortField === "first_name" && sortOrder === "ascend") {
      sortingEnumKey = "first_name";
    }
    if (sortField === "first_name" && sortOrder === "descend") {
      sortingEnumKey = "-first_name";
    }
    if (sortField === "email" && sortOrder === "ascend") {
      sortingEnumKey = "email";
    }
    if (sortField === "email" && sortOrder === "descend") {
      sortingEnumKey = "-email";
    }
    if (sortField === "company_name" && sortOrder === "ascend") {
      sortingEnumKey = "company__company_name";
    }
    if (sortField === "company_name" && sortOrder === "descend") {
      sortingEnumKey = "-company__company_name";
    }

    return sortingEnumKey;
  };

  const handleTableChange = async (pagination, filters, sorter, ordering) => {
    const orderBy = GetSortingEnumValue(sorter.columnKey, sorter.order);
    setState({
      ...state,
      page: pagination.current,
      pagesize: pagination.pageSize,
      ordering: orderBy,
    });
  };

  useEffect(() => {
    const getAllUnregisteredUserNewsletterList = async (data) => {
      await getRequest("user/get-unsubscribe-users/", data)
        .then((response) => {
          setUnsubscribeduserList(response.data.results);
          settotalCount(response.data.count);
        })
        .catch((error) => {
          ErrorNotificationMsg(error);
        });
    };
    try {
      setapiLoading(true);
      getAllUnregisteredUserNewsletterList(state);
      setapiLoading(false);
    } catch (error) {
      setapiLoading(false);
      ErrorNotificationMsg(error);
    }
  }, [state]);

  const searchCallbackDelayed = (event) => {
    const { value } = event.target;
    debounceLoadData(value, onSearch);
  };

  const onSearch = (value) => {
    setState({
      ...state,
      search: value,
    });
  };

  const debounceLoadData = debounce((value, onSearch) => {
    onSearch(value);
  }, 1000);
  // const handleStatus = async (user_id, status) => {
  //   confirmAlert({
  //     title: t("confirmAlert_title"),
  //     message: t("confirmAlert_message"),
  //     buttons: [
  //       {
  //         label: t("confirmAlert_ok"),
  //         onClick: () => changeStatus(user_id, status),
  //       },
  //       {
  //         label: t("confirmAlert_cancel"),
  //         onClick: () => navigate("/unsubscribed-newsletter"),
  //       },
  //     ],
  //   });
  // };

  // const changeStatus = async (value, id) => {
  //   await patchRequest(`user/unsubscribe-user-newsletter/${id}`, {
  //     subscribe_newsletter: value,
  //   })
  //     .then((response) => {
  //       if (response.data.data.subscribe_newsletter === true) {
  //         SuccessNotificationMsg(t("Subscribe"));
  //       } else {
  //         SuccessNotificationMsg(t("Unsubscribe"));
  //       }
  //       setReload(true);
  //     })
  //     .catch((error) => {});
  // };

  const columns = [
    {
      title: "Company",
      dataIndex: "company_name",
      key: "company_name",
      sorter: true,
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Reason For Unsubscribe ",
      dataIndex: "unsubscribe_reason",
      key: "unsubscribe_reason",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "subscribe_newsletter",
      key: "subscribe_newsletter",
      render: (e, record) => (
        <>
          {record.subscribe_newsletter === false && (
            <div style={{ color: "#FF0000" }}>Unsubscribed</div>
          )}
        </>
      ),
      // render: (e, record) => (
      //   <Select
      //     className="gridselect"
      //     onChange={(e) => handleStatus(e, record.id)}
      //     defaultValue={record.subscribe_newsletter}
      //   >
      //     <Option value={true}>
      //       <div>True</div>
      //     </Option>
      //     <Option value={false}>
      //       <div>False</div>
      //     </Option>
      //   </Select>
      // ),
    },
  ];

  return (
    <>
      <div className="heading">
        <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }} align="middle">
          <Col flex="auto" align="center">
            <Space>
              <Button
                type="primary"
                htmlType="button"
                size="small"
                icon={<DownloadOutlined />}
              >
                <CSVLink {...csvReport} style={{ color: "#fff" }}>
                  {" "}
                  &nbsp; {t("download")} &nbsp;
                </CSVLink>
              </Button>
              <div className="searchwrp">
                <Search
                  placeholder={"search"}
                  onChange={searchCallbackDelayed}
                />
              </div>
            </Space>
          </Col>
        </Row>
      </div>
      <Table
        className="table_grid"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={unSubscribeduserList}
        scroll={{ x: 970 }}
        loading={apiLoading}
        pagination={{
          defaultCurrent: state.page,
          defaultPageSize: state.pagesize,
          total: totalCount,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default RegisteredUserNewsletter;
