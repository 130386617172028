import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  Form,
  Space,
  Select,
  Upload,
  Breadcrumb,
  Input,
  Tooltip,
  Radio,
} from "antd";
import {
  UploadOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../../styles/Main.scss";
import "antd/dist/antd.css";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../../axios";
import InputField from "../../../common/htmlComponents/InputField";
import EmailField from "../../../common/htmlComponents/EmailField";
import ButtonField from "../../../common/htmlComponents/ButtonField";
import CountryWithFlag from "../../../common/htmlComponents/CountryWithFlag/CountryWithFlag";
import { getAllCountry } from "../../../common/htmlComponents/CountryWithFlag/countrySlice";
import { getAllUsers } from "../../../authentication/redux/authSlice";
import { facility_regulator } from "./facilitySlice";
import { CompanyAdmin, SuperAdmin, authUserData } from "../../../utils/Helper";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../../utils/Notifications";
import { useUnsavedChanges } from "../../UnsavedChangesProvider";

const AddFacility = () => {
  const dispatch = useDispatch();
  const { unsavedChanges, setShowModal, setClickedPath, setUnsavedChanges } =
    useUnsavedChanges();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formRef = useRef();

  const { id } = useParams();
  const Id = id ? atob(id) : undefined;

  const group = authUserData()?.group;
  const auth_data = JSON.parse(localStorage.getItem("auth_data"));

  const companyList = useSelector((state) => state.users.userList);
  const facilityRegulator = useSelector(
    (state) => state.facility.all_facility_regulator
  );

  const initialState = {
    company: group === "Company Admin" ? authUserData()?.company_id : null,
    facility_location: "",
    facility_email: "",
    facility_contact_no: null,
    address1: "",
    address2: null,
    city: "",
    state: "",
    pincode: "",
    country: null,
    no_of_employees: "1-10 employees",
    facility_regulator: [],
    facility_type: null,
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnnLoading, setBtnnLoading] = useState(false);
  const [showDiv, setShowDiv] = useState("link");
  const [state, setState] = useState(initialState);
  const [size, setSize] = useState(null);
  const [uploadFile, setUploadFile] = useState("");
  const [fileName, setFileName] = useState(null);
  const [fileLink, setFileLink] = useState(null);
  const [locationMsg, setLocationMsg] = useState("");
  const [editFlag, setEditFlag] = useState(false);
  const [editHandleSubmit, setEditHandleSubmit] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(false);

  useEffect(() => {
    const getFacilityById = async (id) => {
      try {
        const apiResponse = await getRequest(
          "facility/list-facilitydetails/" + id
        );
        if (apiResponse.status) {
          formRef.current.setFieldsValue({
            ...apiResponse.data[0],
            company: apiResponse.data[0].company.company,
            facility_regulator: apiResponse.data[0].facility_regulator.map(
              function (el) {
                return el.id;
              }
            ),
          });

          setFileLink(apiResponse.data[0].facility_presentation);
          setState({
            ...apiResponse.data[0],
            company: apiResponse.data[0].company.company,
            facility_regulator: apiResponse.data[0].facility_regulator.map(
              function (el) {
                return el.id;
              }
            ),
          });
          setSelectDisabled(true);
          const presentation = apiResponse?.data[0]?.facility_presentation;
          const presentation_link =
            apiResponse?.data[0]?.facility_presentation_link;

          if (presentation_link === null || presentation_link === "") {
            if (presentation !== null) {
              setShowDiv("upload");
            }
          } else {
            setShowDiv("link");
          }
        } else {
          ErrorNotificationMsg(t("err_getFacility"));
        }
      } catch (error) {
        ErrorNotificationMsg(error);
      }
    };
    if (Id !== undefined) {
      getFacilityById(Id);
    }
  }, [Id, t]);

  useEffect(() => {
    dispatch(facility_regulator());
    dispatch(getAllCountry());
    if (group === "Super Admin") dispatch(getAllUsers());
  }, [dispatch, group]);

  const handleFormChange = (changedValues, allValues) => {
    // Check if the changed values include the fields you want to track
    // console.log(allValues);
    if (
      "address1" in changedValues ||
      "address2" in changedValues ||
      "city" in changedValues ||
      "country" in changedValues ||
      "facility_contact_no" in changedValues ||
      "facility_email" in changedValues ||
      "facility_location" in changedValues ||
      "facility_regulator" in changedValues ||
      "facility_type" in changedValues ||
      "no_of_employees" in changedValues ||
      "pincode" in changedValues ||
      "state" in changedValues
    ) {
      setEditFlag(true);
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        // outerClickChild1: false,
        outerClickChild1: true,
      }));
      // setChild1(true);
      // Assuming currentTab state has child1 and child2 properties
      // setCurrentTab((prevTab) => ({
      //   ...prevTab,
      //   // child1: false,
      //   child2: true, // Set child2 to false or adjust based on your requirement
      // }));
    }
  };
  const handleNavigate = (to, event) => {
    if (
      unsavedChanges.outerClickChild1 === true ||
      unsavedChanges.outerClickChild2 === true
    ) {
      event.preventDefault();
      setShowModal(true);
      setClickedPath(to);
    }
  };
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
  };
  const handleRadioChange = (showDiv) => {
    setShowDiv(showDiv.target.value ? showDiv.target.value : showDiv);
  };
  const handleLocationChange = async (e) => {
    let { value } = e.target;
    setState({ ...state, facility_location: value });

    value.length > 2 &&
      (await getRequest(
        "facility/duplicate-facility-location/?facility_location=" +
          value +
          "&company_id=" +
          authUserData()?.company_id
      ).then((response) => {
        if (response.data.success === true) {
          setState({ ...state, facility_location: value });
          setLocationMsg(response.data.message);
        } else {
          setLocationMsg(response.data.message);
        }
      }));
  };

  const url = state.facility_presentation;

  let filename = "";
  try {
    if (fileLink) {
      filename = url.split("/").pop();
    }
  } catch (e) {
    console.error(e);
  }

  const uploadProps = {
    accept: ".pdf,.pptx,.ppt,doc,.docx",
    showUploadList: false,
    beforeUpload: (file) => {
      setSize(file.size);
      // if (file.type !== "application/pdf") {
      //   ErrorNotificationMsg(t("upload_fileTypeErr"));
      // }
      if (file.size <= 62914560) {
        setUploadFile(file);
        setFileName(file.name);
        setEditFlag(true); // Set editFlag to true when a new file is uploaded
        setUnsavedChanges((prevTab) => ({
          ...prevTab,
          outerClickChild1: true,
          // outerClickChild2: true,
        }));
      }
      return false;
    },
  };

  const handleDelete = () => {
    setUploadFile("");
    setFileName(null);
    setSize(null);
    setEditFlag(true); // Set editFlag to true when a file is deleted
    setUnsavedChanges((prevTab) => ({
      ...prevTab,
      outerClickChild1: true,
      // outerClickChild2: true,
    }));
  };

  const handleFileDelete = async () => {
    const apiResponse = await deleteRequest(
      `facility/delete-facility-presentation/${Id}`
    );
    if (apiResponse.data.success) {
      SuccessNotificationMsg(t("msg_deleteFileSuccess"));
      setFileLink(null);
      setSize(null);
      // setEditFlag(true); // Set editFlag to true when a file is deleted
    }
  };
  const handleNavigateAdd = (Id) => {
    setUnsavedChanges((prevTab) => ({
      ...prevTab,
      outerClickChild1: false,
      // outerClickChild2: false,
    }));
    navigate("/manage-facility/add/" + btoa(Id));
  };
  const handleSubmit = async () => {
    try {
      let customerHeaders = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const formData = new FormData();
      formData.append("facility_presentation", uploadFile);
      formData.append("form_data", JSON.stringify(state));
      setBtnLoading(true);
      if (Id === undefined) {
        const apiResponse = await postRequest(
          "facility/list-create/",
          formData,
          customerHeaders
        );
        if (apiResponse.status) {
          var obj = {
            ...auth_data,
            facilityType: apiResponse.data.data.facility_type,
          };
          localStorage.setItem("auth_data", JSON.stringify(obj));
          setUnsavedChanges((prevTab) => ({
            ...prevTab,
            outerClickChild1: false,
            // outerClickChild2: true,
          }));
          navigate("/manage-facility/add/" + btoa(apiResponse.data.data.id));
        } else {
          ErrorNotificationMsg(t("err_addfacility"));
        }
        setBtnLoading(false);
      } else {
        const apiResponse = await patchRequest(
          "facility/update-delete/" + Id,
          formData,
          customerHeaders
        );
        if (apiResponse.data.success) {
          var obj1 = {
            ...auth_data,
            facilityType: apiResponse.data.message.facility_type,
          };
          localStorage.setItem("auth_data", JSON.stringify(obj1));
          setUnsavedChanges((prevTab) => ({
            ...prevTab,
            outerClickChild1: false,
            // outerClickChild2: false,
          }));
          navigate(
            "/manage-facility/edit/" + btoa(apiResponse.data.message.id)
          );
        } else {
          ErrorNotificationMsg(t("err_facilityUpdated"));
        }
        setBtnLoading(false);
      }
    } catch (error) {
      setBtnLoading(false);
    }
  };
  const phoneValidLength = state.facility_contact_no;

  const handleEdit = async () => {
    try {
      let customerHeaders = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const formData = new FormData();
      formData.append("facility_presentation", uploadFile);
      formData.append("form_data", JSON.stringify(state));
      setBtnnLoading(true);
      if (Id !== undefined) {
        const apiResponse = await patchRequest(
          "facility/update-delete/" + Id,
          formData,
          customerHeaders
        );
        if (apiResponse.data.success) {
          var obj1 = {
            ...auth_data,
            facilityType: apiResponse.data.message.facility_type,
          };
          localStorage.setItem("auth_data", JSON.stringify(obj1));
          SuccessNotificationMsg(t("msg_facilityUpdated"));
          setEditHandleSubmit(true);
          setEditFlag(false);
          setUnsavedChanges((prevTab) => ({
            ...prevTab,
            outerClickChild1: false,
            // outerClickChild2: false,
          }));
        } else {
          ErrorNotificationMsg(t("err_facilityUpdated"));
        }
        setBtnnLoading(false);
      }
    } catch (error) {
      setBtnnLoading(false);
    }
  };

  const handleAlert = (Id) => {
    if (editFlag) {
      confirmAlert({
        title: t("confirmAlertFacility_title"),
        message: t("confirmAlertFacility_message"),
        buttons: [
          {
            label: t("confirmAlertFacility_ok"),
            onClick: handleSubmit,
          },
          {
            label: t("confirmAlertFacility_cancel"),
            // onClick: () => navigate("/manage-facility/add/" + btoa(Id)),
            onClick: () => handleNavigateAdd(Id),
          },
        ],
      });
    } else {
      editHandleSubmit
        ? handleSubmit()
        : // : navigate("/manage-facility/add/" + btoa(Id));
          handleNavigateAdd(Id);
    }
  };

  const validatePhoneNumber = () => {
    if (
      (phoneValidLength && phoneValidLength.length < 8) ||
      phoneValidLength === "" ||
      state.facility_contact_no === null
    ) {
      return Promise.reject(new Error("Please enter valid phone."));
    }
    return Promise.resolve();
  };

  const colors = ["#D9E0E2"];

  return (
    <>
      <div className="contentpart">
        <div className="right_content_col">
          <div className="content_wraper ">
            <div className="mycompany">
              <div className="pagename">
                {t("my_manufacturing_facility")}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    {" "}
                    {SuperAdmin && (
                      <Link
                        to="/dashboard"
                        onClick={(event) => handleNavigate("/dashboard", event)}
                      >
                        {t("home")}
                      </Link>
                    )}
                    {CompanyAdmin && (
                      <Link
                        to="/business-lounge"
                        onClick={(event) =>
                          handleNavigate("/business-lounge", event)
                        }
                      >
                        {t("home")}
                      </Link>
                    )}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      to="/manage-facility"
                      onClick={(event) =>
                        handleNavigate("/manage-facility", event)
                      }
                    >
                      {t("managefacility")}
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{t("facility")}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <br />
              <Form
                onFinish={handleSubmit}
                autoComplete="off"
                ref={formRef}
                onValuesChange={handleFormChange}
              >
                <Row gutter={[15]}>
                  {group === "Super Admin" && (
                    <Col xs={24} sm={12} lg={6}>
                      <Form.Item
                        name="company"
                        label={t("pl_choose_company")}
                        placeholder={t("pl_choose_company")}
                        rules={[
                          {
                            required: true,
                            message: <Trans>{t("val_company")}</Trans>,
                          },
                        ]}
                      >
                        <Select
                          showArrow
                          placeholder={t("pl_choose_company")}
                          className="container-dropdown"
                          popupClassName="dropdownCoin"
                          onChange={(e) => {
                            setState({ ...state, company: e });
                          }}
                          disabled={selectDisabled} // Set disabled state of Select
                        >
                          {companyList.length &&
                            companyList.map((i, key) => {
                              return (
                                <Select.Option
                                  key={key}
                                  value={i.id}
                                  label={i.company_name}
                                >
                                  {i.company_name}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item
                      name="facility_location"
                      label={t("pl_mfg_facility_location")}
                      rules={[
                        {
                          required: true,
                          message: (
                            <Trans>{t("val_mfg_facility_location")}</Trans>
                          ),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("pl_mfg_facility_location")}
                        value={state.facility_location}
                        onChange={handleLocationChange}
                        max={30}
                      />
                    </Form.Item>
                    <div className="duplicationMsg">
                      {locationMsg && (
                        <label className="duplicationMsgLabel">
                          <>
                            {locationMsg === "not available" &&
                              state.facility_location?.length >= 3 && (
                                <Trans style={{ color: "red" }}>
                                  {t("location_exsits")}
                                </Trans>
                              )}
                          </>
                        </label>
                      )}
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <EmailField
                      label={t("pl_facility_email")}
                      placeholder={t("pl_facility_email")}
                      rules={t("val_email")}
                      handleInputChange={handleInputChange}
                      name="facility_email"
                      value={state.facility_email}
                    />
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item
                      name="facility_contact_no"
                      label={t("pl_facility_contact_number")}
                      rules={[
                        {
                          required: true,
                          validator: validatePhoneNumber,
                          message: <Trans> {t("val_phone")}</Trans>,
                        },
                      ]}
                    >
                      <PhoneInput
                        country={"in"}
                        value={state.facility_contact_no}
                        placeholder={t("pl_facility_contact_number")}
                        onChange={(facility_contact_no) =>
                          setState({
                            ...state,
                            facility_contact_no: facility_contact_no,
                          })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <InputField
                      label={t("pl_address_line_1")}
                      placeholder={t("pl_address_line_1")}
                      name="address1"
                      rules={t("val_addressLine")}
                      // pattern={"^[a-zA-Z0-9s, '-/]*$"}
                      pattern={
                        "^[a-zA-Z0-9\\s,'\\-/!@#$%^&*()_+=\\[\\]{}|\\\\:;\"'<>,.?]*$"
                      }
                      handleInputChange={handleInputChange}
                      value={state.address1}
                      max={150}
                    />
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <InputField
                      label={t("pl_address_line_2")}
                      placeholder={t("pl_address_line_2")}
                      name="address2"
                      nonRequiredRules={t("val_addressLine")}
                      pattern={
                        "^[a-zA-Z0-9\\s,'\\-/!@#$%^&*()_+=\\[\\]{}|\\\\:;\"'<>,.?]*$"
                      }
                      handleInputChange={handleInputChange}
                      value={state.address2}
                      max={150}
                    />
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <InputField
                      label={t("pl_city")}
                      placeholder={t("pl_city")}
                      name="city"
                      rules={t("val_city")}
                      handleInputChange={handleInputChange}
                      value={state.city}
                      max={20}
                    />
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <InputField
                      label={t("pl_state")}
                      placeholder={t("pl_state")}
                      name="state"
                      rules={t("val_state")}
                      handleInputChange={handleInputChange}
                      value={state.state}
                      max={20}
                    />
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <InputField
                      placeholder={t("pl_pincode")}
                      name="pincode"
                      label={t("pl_pincode")}
                      rules={t("val_pincode")}
                      // pattern={"^[1-9][0-9]{5}$"}
                      pattern={"^[a-zA-Z0-9\\s]+$"}
                      handleInputChange={handleInputChange}
                      value={state.pincode}
                      max={30}
                    />
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <CountryWithFlag
                      name="country"
                      placeholder={
                        <div className="select_placeholder">
                          {t("pl_chooseCountry")}
                        </div>
                      }
                      label={t("pl_country")}
                      rules={t("val_country")}
                      value={state.country}
                      handleSelectChange={handleSelectChange}
                    />
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item
                      name="facility_regulator"
                      label={t("pl_regulatory_approvals")}
                      className="container-dropdown"
                      rules={[
                        {
                          required: true,
                          message: <Trans>{t("val_facility_regulator")}</Trans>,
                        },
                      ]}
                    >
                      <Select
                        showArrow
                        mode="multiple"
                        maxTagCount={"responsive"}
                        placeholder={t("pl_regulatory_approvals")}
                        optionFilterProp="children"
                        onChange={(value) => {
                          setState({ ...state, facility_regulator: value });
                        }}
                      >
                        {facilityRegulator.length &&
                          facilityRegulator.map((i, key) => {
                            return (
                              <Select.Option
                                key={key}
                                value={i.id}
                                label={i.name}
                              >
                                {i.name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item name="no_of_employees" label={t("employees")}>
                      <Select
                        showArrow
                        placeholder={t("employees")}
                        onChange={(value) =>
                          handleSelectChange("no_of_employees", value)
                        }
                      >
                        <Select.Option value="1-10 employees">
                          1-10 employees
                        </Select.Option>
                        <Select.Option value="11-50 employees">
                          11-50 employees
                        </Select.Option>
                        <Select.Option value="51-200 employees">
                          51-200 employees
                        </Select.Option>
                        <Select.Option value="201-500 employees">
                          201-500 employees
                        </Select.Option>
                        <Select.Option value="501-1000 employees">
                          501-1000 employees
                        </Select.Option>
                        <Select.Option value="1001-5000 employees">
                          1001-5000 employees
                        </Select.Option>
                        <Select.Option value="5001-10000 employees">
                          5001-10000 employees
                        </Select.Option>
                        <Select.Option value="10000 + employees">
                          10000+ employees
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Form.Item
                      name="facility_type"
                      label={
                        <span>
                          <Tooltip
                            color={colors}
                            placement="right"
                            title={
                              <span style={{ color: "#000000" }}>
                                {" "}
                                Describe your manufacturing facility, products,
                                capabilities, certifications and infrastructure.
                              </span>
                            }
                          >
                            {t("facilityType")}
                          </Tooltip>
                        </span>
                      }
                      className="container-dropdown"
                      rules={[
                        {
                          required: true,
                          message: <Trans>{t("val_facility_type")}</Trans>,
                        },
                      ]}
                    >
                      <Select
                        showArrow
                        placeholder={t("facilityType")}
                        onChange={(value) =>
                          setState({ ...state, facility_type: value })
                        }
                      >
                        <Select.Option value="API">API</Select.Option>
                        <Select.Option value="Formulation">
                          FORMULATION
                        </Select.Option>
                        <Select.Option value="CDMO">CDMO</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[15]}>
                  <Col xs={24} sm={24} lg={24}>
                    <h5 className="formtitle">{t("Facilitypresentation")}</h5>
                  </Col>
                  <Col xs={24} sm={24} lg={24}>
                    <div className="uploadwrap">
                      <Radio.Group value={showDiv} onChange={handleRadioChange}>
                        <Radio value={"link"}>{t("videolink_URL")}</Radio>
                        <Radio value={"upload"}>{t("upload")}</Radio>
                      </Radio.Group>
                      <></>
                      {showDiv === "upload" && (
                        <>
                          <div className="uploadinside">
                            <Upload {...uploadProps}>
                              <Button type="primary" icon={<UploadOutlined />}>
                                {t("upload")}
                              </Button>
                              <div className="condition">
                                {" "}
                                <p
                                  style={{
                                    color: size > 62914560 ? "red" : "GrayText",
                                  }}
                                >
                                  {t("upload_fileSize")} {t("upload_fileType")}
                                </p>
                              </div>
                            </Upload>
                            {size <= 62914560 && (
                              <div className="downlod">
                                <Space size={[12, 16]}>
                                  <a
                                    href={fileLink}
                                    className="link-color"
                                    download
                                  >
                                    {fileLink ? filename : fileName}
                                  </a>
                                  {fileLink && (
                                    <DeleteOutlined
                                      onClick={handleFileDelete}
                                    />
                                  )}
                                  {fileName && (
                                    <DeleteOutlined onClick={handleDelete} />
                                  )}
                                </Space>
                                <Space>
                                  {fileLink ? (
                                    <a
                                      href={fileLink}
                                      className="link-color"
                                      download
                                    >
                                      {fileLink ? <DownloadOutlined /> : ""}{" "}
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </Space>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {showDiv === "link" && (
                        <>
                          <div className="videourl">
                            <Space size={[12, 16]}>
                              <InputField
                                name="facility_presentation_link"
                                placeholder={t("pl_video_url")}
                                nonRequiredRules={t("val_video_url")}
                                pattern={
                                  // "^(https?://)?(www.youtube.com|youtu.be)/.+$" ||
                                  // "/^https?://(?:www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/" ||
                                  // "/^[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/"
                                  // "^https?://(?:www.)?.+$"
                                  "^(https?|http)://(?:www.)?.+$"
                                }
                                handleInputChange={handleInputChange}
                                value={state.facility_presentation_link}
                              />

                              {state.facility_presentation_link && (
                                <a
                                  href={state.facility_presentation_link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="ant-btn ant-btn-primary"
                                >
                                  Open Link
                                </a>
                              )}
                            </Space>
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row gutter={[15]} className="bottomButtons">
                  <Col xs={24} align="end" className="text-right">
                    {Id !== undefined && (
                      <>
                        <ButtonField
                          type="primary"
                          label={t("save")}
                          onClick={handleEdit}
                          loading={btnnLoading}
                          disabled={
                            (phoneValidLength && phoneValidLength.length < 8) ||
                            locationMsg === "not available"
                              ? true
                              : false
                          }
                        />
                        &nbsp;&nbsp;&nbsp;
                        <ButtonField
                          type="primary"
                          label={t("next")}
                          onClick={() => handleAlert(Id)}
                          loading={btnLoading}
                          disabled={locationMsg === "not available" && true}
                        />
                      </>
                    )}
                    {Id === undefined && (
                      <>
                        <ButtonField
                          type="primary"
                          label={t("next")}
                          htmlType="submit"
                          loading={btnLoading}
                          disabled={locationMsg === "not available" && true}
                        />
                      </>
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFacility;
