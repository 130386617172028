import React, { useState, useEffect } from "react";
import { Row, Col, Button, Space, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CheckOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import ManageAddProduct from "./addProduct/ManageAddProduct";
import PlantAndLab from "../plant&lab/Plant&Lab";
import Utilities from "../utilities/Utilities";
import PhotoGallery from "../photoGallery/PhotoGallery";
import { getFacilityAPIId } from "./redux/AddFacilityAPISlice";
import { getAPIPhotogalleryById } from "../photoGallery/PhotoGallerySlice";
import { getclassficationAPI } from "../plant&lab/Plant&LabSlice";
import { getUtilities } from "../utilities/redux/UtilitiesSlice";
import ConfirmAlertModel from "../api/ConfirmAlertModel";
import ButtonField from "../../../../../common/htmlComponents/ButtonField";
import ConfirmAlertPrevious from "../cdmo/ConfirmAlertPrevious";
import ConfirmPreviousFacility from "../cdmo/ConfirmPreviousFacility";
import RequiredFieldsModel from "./addProduct/RequiredFieldsModel";
import RequiredFieldsNextModel from "./addProduct/RequiredFieldsNextModal";
import { useUnsavedChanges } from "../../../../UnsavedChangesProvider";
const API = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setUnsavedChanges } = useUnsavedChanges();
  const navigate = useNavigate();
  const { facilityId } = useParams();
  const facility_id = atob(facilityId);
  const location = useLocation();

  // const { currentTab, setCurrentTab } = useCurrentTab();
  // const { currenttab, child1, child2 } = currentTab;
  const initialState = {
    currenttab: 0,
    child1: false,
    child2: false,
    child3: false,
    child4: false,
  };
  const [currentTab, setCurrentTab] = useState(initialState);
  const [tabName, setTabName] = useState("showAddProductComponent");
  const productCount = useSelector((state) => state.addProduct.productCount);
  const plantLabCount = useSelector(
    (state) => state.plantLab.classificationAPICount
  );
  const utilityCount = useSelector(
    (state) => state.UtilitiesCount.utilityCount
  );
  const photoGalleryAPI_count = useSelector(
    (state) => state.photoGallery.photoGalleryAPI_count
  );

  const plant_lab_api_id = useSelector(
    (state) => state.facilityAPI.plant_lab_api_id
  );
  const utility_api_id = useSelector(
    (state) => state.facilityAPI.utility_api_id
  );
  const sub_utility_api_id = useSelector(
    (state) => state.facilityAPI.sub_utility_api_id
  );
  const waterTreatment_api_id = useSelector(
    (state) => state.facilityAPI.waterTreatment_api_id
  );

  const facility_type_id = useSelector(
    (state) => state.facilityAPI.facility_api_id
  );

  const [showAddProductComponent, setShowAddProductComponent] = useState(true);
  const [showPlantAndLabComponent, setShowPlantAndLabComponent] =
    useState(false);
  const [showUtilitiesComponent, setShowUtilitiesComponent] = useState(false);
  const [showPhotoGalleryComponent, setShowPhotoGalleryComponent] =
    useState(false);
  const [componentArrIndex, setComponentArrIndex] = useState(0);
  const [isModalOpenc, setIsModalOpenc] = useState(false);
  const [isModalOpenc1, setIsModalOpenc1] = useState(false);
  const [isModalOpenc3, setIsModalOpenc3] = useState(false);
  const [isModalOpenc4, setIsModalOpenc4] = useState(false);
  const [isModalOpenValidationAPI, setIsModalOpenValidationAPI] =
    useState(false);
  const [isModalNextValidationAPI, setIsModalNextValidationAPI] =
    useState(false);
  useEffect(() => {
    if (facility_id) {
      dispatch(getFacilityAPIId(facility_id));
    }
    if (facility_type_id) {
      dispatch(getAPIPhotogalleryById(facility_type_id));
    }
    if (plant_lab_api_id) {
      dispatch(getclassficationAPI(plant_lab_api_id));
    }
    if (utility_api_id) {
      dispatch(getUtilities(utility_api_id));
    }
  }, [
    dispatch,
    facility_id,
    facility_type_id,
    plant_lab_api_id,
    utility_api_id,
  ]);
  useEffect(() => {
    const isMatchingUrl =
      !location.pathname.startsWith("/manage-facility/add/") &&
      !location.pathname.startsWith("/manage-facility/edit/");

    if (isMatchingUrl) {
      // Initialize the state here
      setCurrentTab((prevTab) => ({
        ...prevTab,
        currenttab: 0,
        child1: false,
        child2: false, // Set child2 to false or adjust based on your requirement
        child3: false, //For null check
        child4: false, //For Grid Productlist
      }));
    }

    // ... (rest of the useEffect logic)
  }, [
    location.pathname,
    setCurrentTab,
    // ... (other dependencies)
  ]);

  const componentArr = [
    "showAddProductComponent",
    "showPlantAndLabComponent",
    "showUtilitiesComponent",
    "showPhotoGalleryComponent",
  ];
  const handleCancelc = () => {
    setIsModalOpenc(false);
  };
  const handleCancelc1 = () => {
    setIsModalOpenc1(false);
  };
  const handleCancelc3 = () => {
    setIsModalOpenc3(false);
  };
  const handleCancelc4 = () => {
    setIsModalOpenc4(false);
  };
  const handleCancelValidationAPI = () => {
    setIsModalOpenValidationAPI(false);
  };
  const handleCancelNextValidationAPI = () => {
    setIsModalNextValidationAPI(false);
  };

  const hideComponent = (name) => {
    switch (name) {
      case "showAddProductComponent":
        setShowAddProductComponent(true);
        setShowPlantAndLabComponent(false);
        setShowUtilitiesComponent(false);
        setShowPhotoGalleryComponent(false);
        setComponentArrIndex(0);

        setCurrentTab((prevState) => ({
          ...prevState,
          currenttab: 0,
        }));

        // ((currentTab) => {});
        break;
      case "showPlantAndLabComponent":
        // if (child1 === false && child2 === false) {
        setShowPlantAndLabComponent(true);
        setShowAddProductComponent(false);
        setShowUtilitiesComponent(false);
        setShowPhotoGalleryComponent(false);
        setComponentArrIndex(1);
        // setCurrentTab(1);
        setCurrentTab((prevState) => ({
          ...prevState,
          currenttab: 1,
        }));
        // }
        // else {
        //   setIsModalOpenc(true);
        // }
        break;
      case "showUtilitiesComponent":
        setShowUtilitiesComponent(true);
        setShowPlantAndLabComponent(false);
        setShowAddProductComponent(false);
        setShowPhotoGalleryComponent(false);
        setComponentArrIndex(2);
        // setCurrentTab(2);
        setCurrentTab((prevState) => ({
          ...prevState,
          currenttab: 2,
        }));
        break;
      case "showPhotoGalleryComponent":
        setShowPhotoGalleryComponent(true);
        setShowPlantAndLabComponent(false);
        setShowAddProductComponent(false);
        setShowUtilitiesComponent(false);
        setComponentArrIndex(3);
        // setCurrentTab(3);
        setCurrentTab((prevState) => ({
          ...prevState,
          currenttab: 3,
        }));

        break;
      default:
    }
  };

  // const handleNext = () => {
  //   let showTabName = componentArr[componentArrIndex + 1];
  //   hideComponent(showTabName);

  // };
  const handleNext = () => {
    // let showTabName = componentArr[componentArrIndex + 1];
    // hideComponent(showTabName);
    // if (currentTab === 0) {
    // }
    // const { currenttab, child1, child2 } = currentTab;
    // console.log(currenttab, child1, child2);
    // if (currenttab === 0 && child1 === true) {
    // handleConfirmAlert(currenttab, child1, child2);
    if (currentTab.child3 === false && currentTab.child4 === false) {
      if (currentTab.child1 === false && currentTab.child2 === false) {
        let showTabName = componentArr[componentArrIndex + 1];
        hideComponent(showTabName);
      } else {
        setIsModalOpenc(true);
        // console.log(currenttab);
      }
    } else {
      setIsModalNextValidationAPI(true);
    }
    // facility/update-delete-api/1685
    // }
  };
  // const handleConfirmAlert = (currenttab, child1, child2) => {
  //   confirmAlert({
  //     title: t("confirmAlertFacility_title"),
  //     message: t("confirmAlertFacility_message"),
  //     buttons: [
  //       {
  //         label: t("confirmAlertFacility_ok"),
  //         onClick: handleTabChange,
  //       },
  //       {
  //         label: t("confirmAlertFacility_cancel"),
  //         onClick: () => navigate("/manage-facility/add/" + btoa(Id)),
  //       },
  //     ],
  //   });
  // };
  const handleTabChange = () => {
    let showTabName = componentArr[componentArrIndex + 1];
    hideComponent(showTabName);
    setCurrentTab((prevState) => ({
      ...prevState,
      child1: false,
      child2: false,
    }));
    setUnsavedChanges((prevTab) => ({
      ...prevTab,
      outerClickChild1: false,
      outerClickChild2: false,
    }));
  };

  const handleAboveTabChange = (name) => {
    setTabName((tabName) => (tabName = name));
    // let showTabName = componentArr[componentArrIndex + 1];
    // console.log(currentTab.currenttab);
    // const previousTab = currentTab.currenttab;

    if (currentTab.child3 === false && currentTab.child4 === false) {
      if (currentTab.child1 === false && currentTab.child2 === false) {
        hideComponent(name);
        // console.log(name);
        name === "showAddProductComponent"
          ? facility_id && dispatch(getFacilityAPIId(facility_id))
          : console.log("");

        setCurrentTab((prevState) => ({
          ...prevState,
          child1: false,
          child2: false,
        }));
      } else {
        setIsModalOpenc1(true);
        // setCurrentTab((prevState) => ({
        //   ...prevState,
        //   child1: false,
        //   child2: false,
        // }));
      }
    } else {
      setIsModalOpenValidationAPI(true);
    }
    // if (currentTab.child3 === false && currentTab.child4 === false) {
    //   hideComponent(name);
    //   // console.log(name);
    //   name === "showAddProductComponent"
    //     ? facility_id && dispatch(getFacilityAPIId(facility_id))
    //     : console.log("");

    //   setCurrentTab((prevState) => ({
    //     ...prevState,
    //     child3: false,
    //     child4: false,
    //   }));
    // } else {
    //   setIsModalOpenValidationAPI(true);
    //   // setCurrentTab((prevState) => ({
    //   //   ...prevState,
    //   //   child1: false,
    //   //   child2: false,
    //   // }));
    // }
  };
  const handleNextPageAbove = () => {
    hideComponent(tabName);
    // console.log(tabName);
    tabName === "showAddProductComponent"
      ? facility_id && dispatch(getFacilityAPIId(facility_id))
      : console.log("");
    setCurrentTab((prevState) => ({
      ...prevState,
      child1: false,
      child2: false,
    }));
    setUnsavedChanges((prevTab) => ({
      ...prevTab,
      outerClickChild1: false,
      outerClickChild2: false,
    }));
    handleCancelc1();
  };
  const handlePrev = () => {
    // let showTabName = componentArr[componentArrIndex - 1];
    // hideComponent(showTabName);
    setCurrentTab((prevState) => ({
      ...prevState,
      child3: false,
      child4: false,
    }));

    if (currentTab.child1 === false && currentTab.child2 === false) {
      let showTabName = componentArr[componentArrIndex - 1];
      hideComponent(showTabName);
      // console.log(showTabName);
      showTabName === "showAddProductComponent"
        ? facility_id && dispatch(getFacilityAPIId(facility_id))
        : console.log("");
    } else {
      setIsModalOpenc3(true);
      // console.log(currenttab);
    }
  };
  const handlePreviousTabChange = () => {
    let showTabName = componentArr[componentArrIndex - 1];
    hideComponent(showTabName);
    // console.log(showTabName);
    showTabName === "showAddProductComponent"
      ? facility_id && dispatch(getFacilityAPIId(facility_id))
      : console.log("");
    setCurrentTab((prevState) => ({
      ...prevState,
      child1: false,
      child2: false,
    }));
    setUnsavedChanges((prevTab) => ({
      ...prevTab,
      outerClickChild1: false,
      outerClickChild2: false,
    }));
  };
  // const handleFacility = () => {
  //   navigate("/manage-facility/edit-facility/" + btoa(facility_id));
  // };

  const handleFacility = () => {
    if (currentTab.child1 === false && currentTab.child2 === false) {
      navigate("/manage-facility/edit-facility/" + btoa(facility_id));
    } else {
      setIsModalOpenc4(true);
    }
  };
  const handlePreviousFacilityChange = () => {
    navigate("/manage-facility/edit-facility/" + btoa(facility_id));
    setCurrentTab((prevState) => ({
      ...prevState,
      child1: false,
      child2: false,
    }));
    setUnsavedChanges((prevTab) => ({
      ...prevTab,
      outerClickChild1: false,
      outerClickChild2: false,
    }));
  };

  return (
    <>
      <Space size={[0, 0]} className="tabbutton">
        <Button
          type="link"
          className={showAddProductComponent ? "success" : ""}
          // onClick={() => hideComponent(componentArr[0])}
          onClick={() => handleAboveTabChange(componentArr[0])}
          // onClick={() => handleShowProductComponent}
        >
          <div>
            {productCount > 0 ? (
              <CheckOutlined />
            ) : (
              <Loading3QuartersOutlined />
            )}
          </div>
          {t("addProduct")}
        </Button>
        <Button
          type="link"
          className={showPlantAndLabComponent ? "success" : ""}
          // onClick={() => hideComponent(componentArr[1])}
          onClick={() => handleAboveTabChange(componentArr[1])}
        >
          <div>
            {plantLabCount > 0 ? (
              <CheckOutlined />
            ) : (
              <Loading3QuartersOutlined />
            )}
          </div>
          {t("plantLab")}
        </Button>
        <Button
          type="link"
          className={showUtilitiesComponent ? "success" : ""}
          // onClick={() => hideComponent(componentArr[2])}
          onClick={() => handleAboveTabChange(componentArr[2])}
        >
          <div>
            {utilityCount === 1 ? (
              <CheckOutlined />
            ) : (
              <Loading3QuartersOutlined />
            )}
          </div>{" "}
          {t("utilities")}
        </Button>
        <Button
          type="link"
          className={showPhotoGalleryComponent ? "success" : ""}
          // onClick={() => hideComponent(componentArr[3])}
          onClick={() => handleAboveTabChange(componentArr[3])}
        >
          <div>
            {photoGalleryAPI_count === 5 ? (
              <CheckOutlined />
            ) : (
              <Loading3QuartersOutlined />
            )}
          </div>
          {t("photoGallery")}
        </Button>
      </Space>

      {showAddProductComponent && (
        <Row gutter={[15]}>
          <Col xs={24}>
            <ManageAddProduct
              facility_id={facility_id}
              setCurrentTab={setCurrentTab}
            />
          </Col>
        </Row>
      )}

      {showPlantAndLabComponent && (
        <Row gutter={[15]}>
          <Col xs={24}>
            <PlantAndLab
              facility_type_id={facility_type_id}
              plant_lab_api_id={plant_lab_api_id}
              setCurrentTab={setCurrentTab}
            />
          </Col>
        </Row>
      )}
      {showUtilitiesComponent && (
        <Row gutter={[15]}>
          <Col xs={24}>
            <Utilities
              facility_type_id={facility_type_id}
              utility_api_id={utility_api_id}
              subUtility_id={sub_utility_api_id}
              waterTreatment_id={waterTreatment_api_id}
              setCurrentTab={setCurrentTab}
            />
          </Col>
        </Row>
      )}
      {showPhotoGalleryComponent && (
        <Row gutter={[15]}>
          <Col xs={24}>
            <PhotoGallery facility_type_id={facility_type_id} />
          </Col>
        </Row>
      )}
      <br />
      {facility_type_id && (
        <Row>
          <Col xs={24} align="end" className="text-right">
            <Space size={[16, 16]}>
              {componentArrIndex >= 1 && (
                <Button type="primary" onClick={handlePrev}>
                  {t("previous")}
                </Button>
              )}
              {componentArrIndex === 0 && (
                <Button type="primary" onClick={handleFacility}>
                  {t("previous")}
                </Button>
              )}
              {componentArrIndex <= 2 && (
                <Button type="primary" onClick={handleNext}>
                  {t("next")}
                </Button>
              )}
              {componentArrIndex === 3 && (
                <Button
                  type="primary"
                  onClick={() => navigate("/manage-facility")}
                >
                  {t("finish")}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      )}
      <Modal
        title={t("model_title")}
        open={isModalOpenc}
        footer={null}
        onCancel={handleCancelc}
        width={580}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <ConfirmAlertModel
          handleCancelc={handleCancelc}
          handleTableChange={handleTabChange}
        />
      </Modal>
      <Modal
        title={t("model_title")}
        open={isModalOpenc1}
        footer={null}
        onCancel={handleCancelc1}
        width={580}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <>
          <p>{t("model_description_message")}</p>
          <Row gutter={[15]} className="bottomButtons">
            <Col xs={12} align="center">
              <ButtonField
                type="primary"
                htmlType=""
                // loading={btnLoading}
                label={t("button_back")}
                onClick={handleNextPageAbove}
              />
            </Col>
            <Col xs={12} align="center">
              <ButtonField
                type="primary"
                htmlType=""
                // loading={btnLoading}
                label={t("button_save")}
                onClick={handleCancelc1}
              />
            </Col>
          </Row>
        </>
      </Modal>
      <Modal
        title={t("model_title")}
        open={isModalOpenc3}
        footer={null}
        onCancel={handleCancelc3}
        width={580}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <ConfirmAlertPrevious
          handleCancelc3={handleCancelc3}
          handlePreviousTabChange={handlePreviousTabChange}
        />
      </Modal>
      <Modal
        title={t("model_title")}
        open={isModalOpenc4}
        footer={null}
        onCancel={handleCancelc4}
        width={580}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <ConfirmPreviousFacility
          handleCancelc4={handleCancelc4}
          handlePreviousFacilityChange={handlePreviousFacilityChange}
        />
      </Modal>
      <Modal
        title={t("model_title_required")}
        open={isModalOpenValidationAPI}
        footer={null}
        onCancel={handleCancelValidationAPI}
        width={300}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <RequiredFieldsModel handleCancelc4={handleCancelValidationAPI} />
      </Modal>
      <Modal
        title={t("model_title_required")}
        open={isModalNextValidationAPI}
        footer={null}
        onCancel={handleCancelNextValidationAPI}
        width={300}
        className="APImodel"
        wrapClassName="modal-wrapper"
        closable={false}
      >
        <RequiredFieldsNextModel
          handleCancelc4={handleCancelNextValidationAPI}
        />
      </Modal>
    </>
  );
};

export default API;
