import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

// import NotAccess from "../common/NotAccess";
import AddFacility from "../feature/manufacturingFacility/facilities/AddFacility";
import AddFacilityType from "../feature/manufacturingFacility/facilities/facilityType/AddFacilityType";
import MyCompany from "../feature/mycompany/MyCompany";
import {
  authUserData,
  CompanyAdmin,
  SpinLoading,
  SuperAdminOrSubAdmin,
} from "../utils/Helper";
import RequestForPriceList from "../authentication/RequestForPriceList";
import FacilityConsentModal from "../feature/manufacturingFacility/FacilityConsentModal";
import Unsubscribe from "../authentication/Unsubscribe";
import SubscribedNewsletter from "../feature/newsletter/subscribedNewsletter/SubscribedNewsletter";
import UnsubscribedNewsletter from "../feature/newsletter/unsubscribedNewsletter/UnsubscribedNewsletter";
import CompanyConsentModal from "../feature/mycompany/CompanyConsentModal";
import RequestedConcierge from "../feature/requestedConcierge/RequestedConcierge";
import ShortListedCompany from "../feature/shortlistedCompany/ShortListedCompany";
import InActivePurchasedConciergeUsd from "../feature/subscription/InActivePurchasedConciergeUsd";
// import PaymentSuccessPage from "../authentication/PaymentSuccessPage";
// import ManageAddProduct from "../feature/manufacturingFacility/facilities/facilityType/api/addProduct/ManageAddProduct";

const Support = lazy(() => import("../authentication/Support"));
const Login = lazy(() => import("../authentication/Login"));
const Register = lazy(() => import("../authentication/CompanyRegister"));
const VerifyEmail = lazy(() => import("../authentication/VerifyEmail"));
const VerifySuccess = lazy(() => import("../authentication/VerifySuccess"));
const VerifyAccount = lazy(() => import("../authentication/VerifyAccount"));
const ForgotSuccess = lazy(() => import("../authentication/ForgotSuccess"));
const ForgotPassword = lazy(() => import("../authentication/ForgotPassword"));
const ResetPassword = lazy(() => import("../authentication/ResetPassword"));
const PasswordSuccess = lazy(() => import("../authentication/PasswordSuccess"));
const RegisterSuccess = lazy(() => import("../authentication/RegisterSuccess"));
const SupportSuccess = lazy(() => import("../authentication/SupportSuccess"));
const RequestSuccess = lazy(() => import("../authentication/RequestSuccess"));
const RequestForCredentials = lazy(() =>
  import("../authentication/RequestForCredentials")
);
const SearchCompany = lazy(() =>
  import("../authentication/searchCompany/SearchCompany")
);
const PaymentSuccessPage = lazy(() =>
  import("../authentication/PaymentSuccessPage")
);
const Logout = lazy(() => import("../authentication/Logout"));
const CompanyDetail = lazy(() =>
  import("../authentication/companyDetail/CompanyDetail")
);
const BusinessLounge = lazy(() =>
  import("../feature/businessLounge/BusinessLounge")
);

const ConciergeService = lazy(() =>
  import("../feature/conciergeService/ConciergeService")
);
// const PaymentSuccess = lazy(() =>
//   import("../feature/conciergeService/PaymentSuccess")
// );

const PaymentFailure = lazy(() =>
  import("../feature/conciergeService/PaymentFailure")
);

const Dashboard = lazy(() => import("../feature/dashboard/Dashboard"));
const ActivityLog = lazy(() => import("../feature/dashboard/ActivityLog"));
const ManageCompany = lazy(() =>
  import("../feature/manageCompany/ManageCompany")
);
const MonthlyManageCompany = lazy(() =>
  import("../feature/manageCompany/MonthlyManageCompany")
);

const ManageManufacturingFacility = lazy(() =>
  import("../feature/manufacturingFacility/ManageManufacturingFacility")
);
const APIManageManufacturingFacility = lazy(() =>
  import("../feature/manufacturingFacility/APIManageManufacturingFacility")
);
const FormulationManageManufacturingFacility = lazy(() =>
  import("../feature/manufacturingFacility/FormulationManageManufacturingFacility")
);
const CDMOManageManufacturingFacility = lazy(() =>
  import("../feature/manufacturingFacility/CDMOManageManufacturingFacility")
);

const ViewManufacturingFacility = lazy(() =>
  import("../feature/manufacturingFacility/ViewManufacturingFacility")
);
const ViewManufacturingFacilityBusinessLounge = lazy(() =>
  import("../feature/manufacturingFacility/ViewManufacturingFacilityBusinessLounge")
);
const ViewManufacturingFacilityShortlistedCompany = lazy(() =>
  import("../feature/manufacturingFacility/ViewManufacturingFacilityShortlistedCompany")
);
const ViewManufacturingFacilityMyCompany = lazy(() =>
  import("../feature/manufacturingFacility/ViewManufacturingFacilityMyCompany")
);
const ViewManufacturingFacilityManageCompany = lazy(() =>
  import("../feature/manufacturingFacility/ViewManufacturingFacilityManageCompany")
);
const ManageTicket = lazy(() => import("../feature/ticket/ManageTicket"));
const CreateTicket = lazy(() => import("../feature/ticket/CreateTicket"));
const PurchasedConcierge = lazy(() =>
  import("../feature/subscription/PurchasedConcierge")
);
const PurchasedConciergeInr = lazy(() =>
  import("../feature/subscription/PurchasedConciergeInr")
);
const PurchasedConciergeUsd = lazy(() =>
  import("../feature/subscription/PurchasedConciergeUsd")
);
const ActivePurchasedConcierge = lazy(() =>
  import("../feature/subscription/ActivePurchasedConcierge")
);
const InActivePurchasedConcierge = lazy(() =>
  import("../feature/subscription/InActivePurchasedConcierge")
);
const ActivePurchasedConciergeUsd = lazy(() =>
  import("../feature/subscription/ActivePurchasedConciergeUsd")
);
const MyOrder = lazy(() => import("../feature/myOrder/MyOrder"));
const ReceivedInquiry = lazy(() =>
  import("../feature/receivedInquiry/ReceivedInquiry")
);
const OpenReceivedInquiry = lazy(() =>
  import("../feature/receivedInquiry/OpenReceivedInquiry")
);
const RespondedReceivedInquiry = lazy(() =>
  import("../feature/receivedInquiry/RespondedReceivedInquiry")
);
const SentInquiry = lazy(() => import("../feature/sentInquiry/SentInquiry"));
const ManageConciergeService = lazy(() =>
  import("../feature/subscription/ManageConciergeService")
);
const AddConciergeService = lazy(() =>
  import("../feature/subscription/AddConciergeService")
);
const ManageRegulatoryApprovals = lazy(() =>
  import("../feature/manageRegulatoryApprovals/ManageRegulatoryApprovals")
);
const AddRegulatoryApprovals = lazy(() =>
  import("../feature/manageRegulatoryApprovals/AddRegulatoryApprovals")
);
const PromocodeList = lazy(() =>
  import("../feature/managePromocode/PromocodeList")
);
const AddPromocode = lazy(() =>
  import("../feature/managePromocode/AddPromocode")
);

const Home = lazy(() => import("./Home"));
const PrivateRoute = lazy(() => import("./PrivateRoute"));
const EditProfile = lazy(() => import("../feature/profile/EditProfile"));
const Users = lazy(() => import("../feature/manageUsers/Users"));
const AddUser = lazy(() => import("../feature/manageUsers/AddUser"));
const Settings = lazy(() => import("../feature/settings/settings"));

let loggedInUser = localStorage.getItem("isLoggedIn");

// const Root = ({ component: Component, ...rest }) => {
const Root = () => {
  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     e.preventDefault(); // This is important to show the custom dialog.

  //     const confirmationMessage = "Are you sure you want to leave this page?";

  //     e.returnValue = confirmationMessage; // Display the message in the dialog.

  //     // Show a custom confirmation dialog with "Yes" and "No" options.
  //     const userResponse = window.confirm(confirmationMessage);

  //     if (!userResponse) {
  //       // If the user clicks "No," prevent them from leaving the page.
  //       e.returnValue = ""; // Clear the message to allow staying on the page.
  //     } else {
  //       alert("locl")
  //       console.log("first local")
  //       localStorage.clear(); // Clear localStorage if user wants to leave.
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const [pageLoaded, setPageLoaded] = useState(
    document.readyState === "complete"
  );

  useEffect(() => {
    const handleLoad = () => {
      setPageLoaded(true);
    };

    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", handleLoad);
    } else {
      handleLoad();
    }

    return () => {
      document.removeEventListener("DOMContentLoaded", handleLoad);
    };
  }, []);

  return (
    <>
      {pageLoaded ? (
        <Suspense fallback={<SpinLoading />}>
          <Routes>
            <>
              <Route
                path="/"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <Login />
                  </Suspense>
                }
              />
              <Route
                path="/home"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <Home />
                  </Suspense>
                }
              />
              <Route
                path="/login"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <Login />
                  </Suspense>
                }
              />
              <Route
                path="/payment-success"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <PaymentSuccessPage />
                  </Suspense>
                }
              />
              <Route
                path="/company-registration"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <Register />
                  </Suspense>
                }
              />
              <Route
                path="/support"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <Support />
                  </Suspense>
                }
              />

              <Route
                path="/reset-password/:resetToken"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <ResetPassword />
                  </Suspense>
                }
              />
              <Route
                path="/forgot-password"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <ForgotPassword />
                  </Suspense>
                }
              />
              <Route
                path="/mail-sent"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <ForgotSuccess />
                  </Suspense>
                }
              />

              <Route
                path="/support-success"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <SupportSuccess />
                  </Suspense>
                }
              />
              <Route
                path="/password-success"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <PasswordSuccess />
                  </Suspense>
                }
              />
              <Route
                path="/verify-success"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <VerifySuccess />
                  </Suspense>
                }
              />
              <Route
                path="/register-success"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <RegisterSuccess />
                  </Suspense>
                }
              />
              <Route
                path="/request-success"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <RequestSuccess />
                  </Suspense>
                }
              />
              <Route
                path="/verify-account"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <VerifyAccount />
                  </Suspense>
                }
              />
              <Route
                path="/verify-email/:mode/:id"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <VerifyEmail />
                  </Suspense>
                }
              />

              {/* <Route path="*" element={<Suspense fallback={<SpinLoading />}><NotFound /></Suspense>} /> */}
              <Route
                path="/logout"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <Logout />
                  </Suspense>
                }
              />

              <Route
                path="/signup"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <SearchCompany />
                  </Suspense>
                }
              />
              <Route
                path="/request-for-credentials/:id/:companyName"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <RequestForCredentials />
                  </Suspense>
                }
              />
              <Route path="/company-detail/:id" element={<CompanyDetail />} />
              <Route
                path="/concierge"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <RequestForPriceList />
                  </Suspense>
                }
              />
              <Route
                path="/unsubscribe/:email"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <Unsubscribe />
                  </Suspense>
                }
              />

              <Route
                render={() => (
                  <Suspense fallback={<div>Loading...</div>}>
                    {CompanyAdmin && loggedInUser ? (
                      <Route
                        path="/business-lounge"
                        element={
                          <Suspense fallback={<SpinLoading />}>
                            <BusinessLounge />
                          </Suspense>
                        }
                      />
                    ) : (
                      <Route
                        path="/"
                        element={
                          <Suspense fallback={<SpinLoading />}>
                            <Login />
                          </Suspense>
                        }
                      />
                    )}
                  </Suspense>
                )}
              />

              <Route
                exact
                path="/"
                element={
                  <Suspense fallback={<SpinLoading />}>
                    <PrivateRoute
                    // {...rest}
                    // render={(props) => {
                    //   // const navigate = useNavigate();

                    //   return userToken ? (
                    //     <Component {...props} />
                    //   ) : (
                    //     // Use navigate to redirect
                    //     navigate("/login", {
                    //       state: { from: props.location },
                    //     })
                    //   );
                    // }}
                    />
                  </Suspense>
                }
              >
                {/* <Route exact path='/' element={<Suspense fallback={<SpinLoading />}>{SuperAdminOrSubAdmin && loggedInUser ? <Dashboard/> : <Login/>}</Suspense>}>
                        <Route exact path='/' element={<Suspense fallback={<SpinLoading />}>{CompanyAdmin && loggedInUser ? <BusinessLounge/> : <Login/>}</Suspense>}> */}

                {SuperAdminOrSubAdmin && (
                  <Route
                    path="/dashboard"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <Dashboard />
                      </Suspense>
                    }
                  />
                )}
                {SuperAdminOrSubAdmin && (
                  <Route
                    path="/activity-log"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <ActivityLog />
                      </Suspense>
                    }
                  />
                )}
                {SuperAdminOrSubAdmin && (
                  <Route
                    path="/manage-company"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <ManageCompany />
                      </Suspense>
                    }
                  />
                )}
                  {SuperAdminOrSubAdmin && (
                  <Route
                    path="/manage-company-month"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <MonthlyManageCompany />
                      </Suspense>
                    }
                  />
                )}
                <Route
                  path="/business-lounge"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <BusinessLounge />
                    </Suspense>
                  }
                />
                {CompanyAdmin && (
                  <Route
                    path="/concierge-service"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <ConciergeService />
                      </Suspense>
                    }
                  />
                )}
                {/* <Route
                  path="/payment-success"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <PaymentSuccess />
                    </Suspense>
                  }
                /> */}
                <Route
                  path="/payment-failure"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <PaymentFailure />
                    </Suspense>
                  }
                />

                {(authUserData()?.company_inquiry_consent &&
                  authUserData()?.company_data_store_consent) === false ? (
                  <>
                    <Route
                      path="/my-company"
                      element={<CompanyConsentModal />}
                    />
                    <Route
                      path="/my-company/:companyId"
                      element={
                        <Suspense fallback={<SpinLoading />}>
                          <CompanyConsentModal />
                        </Suspense>
                      }
                    />
                  </>
                ) : (
                  <>
                    <Route path="/my-company" element={<MyCompany />} />
                    <Route
                      path="/my-company/:companyId"
                      element={
                        <Suspense fallback={<SpinLoading />}>
                          <MyCompany />
                        </Suspense>
                      }
                    />
                  </>
                )}

                <Route
                  path="/my-profile"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <EditProfile />
                    </Suspense>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <Settings />
                    </Suspense>
                  }
                />

                <Route
                  path="/shortlisted-company-list"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <ShortListedCompany />
                    </Suspense>
                  }
                />
                {SuperAdminOrSubAdmin && (
                  <Route
                    path="/manage-facility"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <ManageManufacturingFacility />
                      </Suspense>
                    }
                  />
                  
                )}
                {SuperAdminOrSubAdmin && (
                  <Route
                    path="/manage-facility-api"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <APIManageManufacturingFacility />
                      </Suspense>
                    }
                  />
                  
                )}
                {SuperAdminOrSubAdmin && (
                  <Route
                    path="/manage-facility-formulation"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <FormulationManageManufacturingFacility />
                      </Suspense>
                    }
                  />
                  
                )}
                {SuperAdminOrSubAdmin && (
                  <Route
                    path="/manage-facility-cdmo"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <CDMOManageManufacturingFacility />
                      </Suspense>
                    }
                  />
                  
                )}
                {(authUserData()?.facility_inquiry_consent &&
                  authUserData()?.facility_data_store_consent) === false ? (
                  <>
                    {CompanyAdmin && (
                      <Route
                        path="/manage-facility"
                        element={
                          <Suspense fallback={<SpinLoading />}>
                            <FacilityConsentModal />
                          </Suspense>
                        }
                      />
                    )}
                  </>
                ) : (
                  <Route
                    path="/manage-facility"
                    element={
                      <Suspense fallback={<SpinLoading />}>
                        <ManageManufacturingFacility />
                      </Suspense>
                    }
                  />
                )}
                <Route path="/manage-facility/add" element={<AddFacility />} />
                <Route
                  path="/manage-facility/edit-facility/:id"
                  element={<AddFacility />}
                />

                <Route
                  path="/manage-facility/add/:facilityId"
                  element={<AddFacilityType />}
                />
                <Route
                  path="/manage-facility/edit/:facilityId"
                  element={<AddFacilityType />}
                />

                <Route
                  path="/manage-facility/view-facility/:facilityId"
                  element={<ViewManufacturingFacility />}
                />
                <Route
                  path="/manage-facility/view-facility/:facilityId/business-lounge"
                  element={<ViewManufacturingFacilityBusinessLounge />}
                />
                <Route
                  path="/manage-facility/view-facility/:facilityId/shortlisted-company"
                  element={<ViewManufacturingFacilityShortlistedCompany />}
                />
                <Route
                  path="/manage-facility/view-facility/:facilityId/my-company"
                  element={<ViewManufacturingFacilityMyCompany />}
                />
                <Route
                  path="/manage-facility/view-facility/:facilityId/manage-company"
                  element={<ViewManufacturingFacilityManageCompany />}
                />
                <Route
                  path="/manage-users"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <Users />
                    </Suspense>
                  }
                />
                <Route
                  path="/manage-users/add-user"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <AddUser />
                    </Suspense>
                  }
                />
                <Route path="/manage-ticket/add" element={<CreateTicket />} />
                <Route path="/manage-ticket" element={<ManageTicket />} />
                <Route
                  path="/purchased-concierge"
                  element={<PurchasedConcierge />}
                />
                <Route path="/my-order" element={<MyOrder />} />
                <Route
                  path="/received-inquiry"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <ReceivedInquiry />
                    </Suspense>
                  }
                />
                <Route
                  path="/received-inquiry-open"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <OpenReceivedInquiry />
                    </Suspense>
                  }
                />
                <Route
                  path="/received-inquiry-responded"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <RespondedReceivedInquiry />
                    </Suspense>
                  }
                />
                <Route
                  path="/sent-inquiry"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <SentInquiry />
                    </Suspense>
                  }
                />
                <Route
                  path="/manage-regulatory-approvals"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <ManageRegulatoryApprovals />
                    </Suspense>
                  }
                />
                <Route
                  path="/manage-regulatory-approvals/add"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <AddRegulatoryApprovals />
                    </Suspense>
                  }
                />
                <Route
                  path="/manage-regulatory-approvals/edit/:id"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <AddRegulatoryApprovals />
                    </Suspense>
                  }
                />
                <Route
                  path="/manage-promocode"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <PromocodeList />
                    </Suspense>
                  }
                />
                <Route
                  path="/manage-promocode/add"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <AddPromocode />
                    </Suspense>
                  }
                />
                <Route
                  path="/manage-promocode/edit/:id"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <AddPromocode />
                    </Suspense>
                  }
                />
                <Route
                  path="/manage-concierge-service"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <ManageConciergeService />
                    </Suspense>
                  }
                />
                <Route
                  path="/manage-concierge-service/add"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <AddConciergeService />
                    </Suspense>
                  }
                />
                <Route
                  path="/manage-concierge-service/edit/:id"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <AddConciergeService />
                    </Suspense>
                  }
                />
                <Route
                  path="/purchased-concierge-service"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <PurchasedConcierge />
                    </Suspense>
                  }
                />
                <Route
                  path="/purchased-concierge-service-usd"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <PurchasedConciergeUsd />
                    </Suspense>
                  }
                />
                <Route
                  path="/purchased-concierge-service-inr"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <PurchasedConciergeInr />
                    </Suspense>
                  }
                />
                <Route
                  path="/purchased-concierge-service-active"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <ActivePurchasedConcierge />
                    </Suspense>
                  }
                />
                 <Route
                  path="/purchased-concierge-service-active-usd"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <ActivePurchasedConciergeUsd />
                    </Suspense>
                  }
                />
                   <Route
                  path="/purchased-concierge-service-inactive"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <InActivePurchasedConcierge />
                    </Suspense>
                  }
                />
                   <Route
                  path="/purchased-concierge-service-inactive-usd"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <InActivePurchasedConciergeUsd />
                    </Suspense>
                  }
                />
                <Route
                  path="/requested-concierge-service"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <RequestedConcierge />
                    </Suspense>
                  }
                />
                <Route
                  path="/subscribed-newsletter"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <SubscribedNewsletter />
                    </Suspense>
                  }
                />
                <Route
                  path="/unsubscribed-newsletter"
                  element={
                    <Suspense fallback={<SpinLoading />}>
                      <UnsubscribedNewsletter />
                    </Suspense>
                  }
                />
              </Route>
            </>
          </Routes>
        </Suspense>
      ) : (
        <SpinLoading />
      )}
    </>
  );
};

export default Root;
