import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../axios";
import { authUserData } from "../../utils/Helper";

export const getProgressPrec = createAsyncThunk("get_progressPrec", async () => {
    const response = await getRequest("company/profile-perc/" + authUserData()?.company_id);
    return response.data;
});

export const reloadAfterUpdate = createAsyncThunk("reload_afterUpdate", async (afterUpdate) => {
    return afterUpdate;
});

const initialState = {
    progressPerc_count: null,
    refresh: false
}

const progressBar = createSlice({
    name: "progressBar",
    initialState,
    reducers: {},
    extraReducers: {

        [getProgressPrec.fulfilled]: (state, action) => {
            return { ...state, progressPerc_count: action.payload.data.profile_perc };
        },

        [reloadAfterUpdate.fulfilled]: (state, action) => {
            return { ...state, refresh: true };
        },
    }
});

export default progressBar.reducer
