import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { getManufacturingFormulation } from "./ManageFacilitySlice";

const FormulationCapacity = (props) => {
  const dispatch = useDispatch();

  const list = useSelector((state) => state.allFacility.mfgFormulationList);
  const apiLoading = useSelector((state) => state.allFacility.apiLoading);
  const mfgFormulationList = list && list.results;
  const [state, setState] = useState({
    page: 1,
    ordering: "id",
    pagesize: 10,
    total: 0,
  });

  const handleTableChange = async (pagination, filters, sorter, ordering) => {
    const orderBy = GetSortingEnumValue(sorter.columnKey, sorter.order);

    setState((prevState) => ({
      ...prevState,
      page: pagination.current,
      pagesize: pagination.pageSize,
      ordering: orderBy,
    }));
    dispatch(
      getManufacturingFormulation({
        id: props.formulationId,
        page: pagination.current,
        ordering: orderBy,
        pagesize: pagination.pageSize,
      })
    );
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = "id";

    if (sortField === "formulation_type_name" && sortOrder === "ascend") {
      sortingEnumKey = "formulation_type";
    }
    if (sortField === "formulation_type_name" && sortOrder === "descend") {
      sortingEnumKey = "-formulation_type";
    }

    if (sortField === "monthly_capacity" && sortOrder === "ascend") {
      sortingEnumKey = "monthly_capacity";
    }
    if (sortField === "monthly_capacity" && sortOrder === "descend") {
      sortingEnumKey = "-monthly_capacity";
    }

    return sortingEnumKey;
  };

  const columns = [
    {
      title: "Type of formulation",
      dataIndex: "formulation_type_name",
      key: "formulation_type_name",
      sorter: true,
    },
    {
      title: "Capacity per month",
      dataIndex: "monthly_capacity",
      key: "monthly_capacity",
      sorter: true,
    },
  ];

  return (
    <>
      <Table
        className="table_grid"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={mfgFormulationList}
        scroll={{ x: 500 }}
        loading={apiLoading}
        pagination={{
          defaultCurrent: state.page,
          defaultPageSize: state.pagesize,
          total: list.count,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

FormulationCapacity.propTypes = {
  formulationId: PropTypes.string, // Add this line
};

export default FormulationCapacity;
