import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteRequest, getRequest, patchRequest, postRequest } from "../../../../../../axios";

export const addProductAPI = async (data) => {
    const response = await postRequest(`facility/list-create-api-product/${data.id}`, data);
    return response.data;
};

// export const getAllProducts = async (data) => {
//     const response = await getRequest(`facility/list-create-api-product/${data.id}`, {
//         ordering: data.ordering, search: data.search,
//         page: data.page,
//         pagesize: data.pagesize
//     });
//     return response.data;
// };

export const getProductById = createAsyncThunk("get_product_by_id", async (id) => {
    const response = await getRequest("facility/list-api-product/" + id);
    return response.data;
});

export const getProductCount = createAsyncThunk("get_product_count", async (count) => {
    return count;
});

export const updateProductAPI = async (data) => {
    const response = await patchRequest(`facility/update-delete-api-product/${data.id}`, { ...data });
    return response.data;
};

export const resetAfterUpdate = createAsyncThunk("reset_afterUpdate", async (afterUpdate) => {
    return afterUpdate;
});

export const deleteProductAPI = async (id) => {
    const response = await deleteRequest("facility/update-delete-api-product/" + id);
    return response.data;
};


const initialState = {
    product: [],
    product_id: null,
    productCount: null
}

const addProduct = createSlice({
    name: "addProduct",
    initialState,
    reducers: {},
    extraReducers: {

        [getProductCount.fulfilled]: (state, action) => {
            return { ...state, productCount: action.payload };
        },

        // get product by id

        [getProductById.fulfilled]: (state, action) => {
            return { ...state, product: action.payload, product_id: action.payload.results[0].id };
        },

        // resetAfterUpdate

        [resetAfterUpdate.fulfilled]: (state, action) => {
            return { ...state, product: null, product_id: null };
        }
    }
});

export default addProduct.reducer
