import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Radio, Breadcrumb, Tooltip } from "antd";
import "../../../../styles/Main.scss";
import "antd/dist/antd.css";
import API from "./api/API";
import CDMO from "./cdmo/CDMO";
import Formulation from "./formulation/Formulation";
import {
  CompanyAdmin,
  SuperAdmin,
  authUserData,
} from "../../../../utils/Helper";
import { useUnsavedChanges } from "../../../UnsavedChangesProvider";

const AddFacilityType = () => {
  const { t } = useTranslation();
  const facilityType = authUserData()?.facilityType;
  const { unsavedChanges, setShowModal, setClickedPath } = useUnsavedChanges();
  const { facilityId } = useParams();
  const id = facilityId ? atob(facilityId) : undefined;

  const colors = ["#D9E0E2"];
  const handleNavigate = (to, event) => {
    const path = typeof to === "string" ? to : to.pathname;
    if (
      unsavedChanges.outerClickChild1 === true ||
      unsavedChanges.outerClickChild2 === true
    ) {
      event.preventDefault();
      setShowModal(true);
      setClickedPath(path);
    }
  };
  return (
    <div className="contentpart">
      <div className="right_content_col">
        <div className="content_wraper ">
          <div className="addfacilitytype">
            <div className="pagename">
              <Tooltip
                color={colors}
                placement="right"
                title={
                  <span style={{ color: "#000000" }}>
                    {" "}
                    Describe your manufacturing facility, products,
                    capabilities, certifications and infrastructure.{" "}
                  </span>
                }
              >
                {" "}
                {t("facilityType")}
              </Tooltip>

              <Breadcrumb>
                <Breadcrumb.Item>
                  {SuperAdmin && (
                    <Link
                      to="/dashboard"
                      onClick={(event) => handleNavigate("/dashboard", event)}
                    >
                      {t("home")}
                    </Link>
                  )}
                  {CompanyAdmin && (
                    <Link
                      to="/business-lounge"
                      onClick={(event) =>
                        handleNavigate("/business-lounge", event)
                      }
                    >
                      {t("home")}
                    </Link>
                  )}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to="/manage-facility"
                    onClick={(event) =>
                      handleNavigate("/manage-facility", event)
                    }
                  >
                    {t("managefacility")}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link
                    to={{
                      pathname: "/manage-facility/edit-facility/" + btoa(id),
                    }}
                    onClick={(event) =>
                      handleNavigate(
                        {
                          pathname:
                            "/manage-facility/edit-facility/" + btoa(id),
                        },
                        event
                      )
                    }
                  >
                    {t("facility")}
                  </Link>
                </Breadcrumb.Item>

                <Breadcrumb.Item>{t("facilityType")}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <br />
            <Row gutter={[15]}>
              <Col xs={24}>
                <Radio.Group defaultValue={facilityType}>
                  {facilityType === "API" && <Radio value="API">API</Radio>}
                  {facilityType === "Formulation" && (
                    <Radio value="Formulation">FORMULATION</Radio>
                  )}
                  {facilityType === "CDMO" && <Radio value="CDMO">CDMO</Radio>}
                </Radio.Group>
              </Col>
            </Row>
            {facilityType === "API" && (
              <Row gutter={[15]}>
                <Col xs={24}>
                  <API />
                </Col>
              </Row>
            )}
            {facilityType === "Formulation" && (
              <Row gutter={[15]}>
                <Col xs={24}>
                  <Formulation />
                </Col>
              </Row>
            )}
            {facilityType === "CDMO" && (
              <Row gutter={[15]}>
                <Col xs={24}>
                  <CDMO />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFacilityType;
