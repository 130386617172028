import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Space } from "antd";
import { getRequest } from "../../../../../../axios";
import EditableTherapeuticSegment from "./EditableTherapeuticSegment";
import EditableDmfCep from "./EditableDmfCep";
import InputField from "../../../../../../common/htmlComponents/InputField";
import ButtonField from "../../../../../../common/htmlComponents/ButtonField";
import NumberField from "../../../../../../common/htmlComponents/NumberField";
import {
  addProductAPI,
  resetAfterUpdate,
  updateProductAPI,
} from "../redux/AddProductSlice";
import { SuccessNotificationMsg } from "../../../../../../utils/Notifications";
import { useUnsavedChanges } from "../../../../../UnsavedChangesProvider";

const AddProduct = ({
  facility_type_id,
  setNewProductAdded,
  newProductAdded,
  setCurrentTab,
}) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const dispatch = useDispatch();
  const { setUnsavedChanges } = useUnsavedChanges();
  const productById = useSelector((state) => state.addProduct.product);
  const product = productById && productById.results;
  const productId = useSelector((state) => state.addProduct.product_id);

  const initialState = {
    product_name: null,
    cas_no: null,
    therapeutic_segment_list: [],
    product_id: null,
    dmf_cep_list: [],
  };
  const [state, setState] = useState(initialState);
  const [btnLoading, setBtnloading] = useState(false);
  const [dmfCepList, setdmfCepList] = useState([]);
  const [therapeuticList, setTherapeuticList] = useState([]);
  const [deleted, setDeleted] = useState(false);

  const getdmfCepList = async () => {
    await getRequest("facility/list-create-dmf-cep/").then((res) => {
      setdmfCepList(res.data.data);
    });
  };
  const getTherapeuticList = async () => {
    await getRequest("facility/therapeutic_segment").then((res) => {
      setTherapeuticList(res.data.data);
    });
  };

  useEffect(() => {
    getdmfCepList();
    getTherapeuticList();
    // dispatch(resetAfterUpdate(true));

    if (productId) {
      formRef.current.setFieldsValue({
        product_name: product[0].product_name,
        cas_no: product[0].cas_no,
        therapeutic_segment_list: product[0].therapeutic_list.map(function (
          el
        ) {
          return el.id;
        }),
        dmf_cep_list: product[0].dmf_cep_list.map(function (el) {
          return el.id;
        }),
      });
      setState({
        ...state,
        product_id: productId,
        product_name: product[0].product_name,
        cas_no: product[0].cas_no,
        therapeutic_segment_list: product[0].therapeutic_list.map(function (
          el
        ) {
          return el.id;
        }),
        dmf_cep_list: product[0].dmf_cep_list.map(function (el) {
          return el.id;
        }),
      });
    }
  }, [product, productId, formRef, deleted]);
  const handleFormChange = (changedValues, allValues) => {
    // Check if the changed values include the fields you want to track

    if (
      "product_name" in changedValues ||
      "cas_no" in changedValues ||
      "therapeutic_segment_list" in changedValues ||
      "dmf_cep_list" in changedValues
    ) {
      // setChild1(true);
      // Assuming currentTab state has child1 and child2 properties
      setCurrentTab((prevTab) => ({
        ...prevTab,
        // child1: false,
        child2: true, // Set child2 to false or adjust based on your requirement
      }));
      setUnsavedChanges((prevTab) => ({
        ...prevTab,
        // outerClickChild1: false,
        outerClickChild2: true,
      }));
    }
  };
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value ? value : null });
  };

  const resetField = () => {
    setState(initialState);
    formRef.current.setFieldsValue(initialState);
  };

  const removeId = (fieldName, id) => {
    const updatedArray = state.fieldName.filter((item) => item !== id);
    setState({ ...state, fieldName: updatedArray });
    formRef.current.setFieldsValue({
      ...state,
      fieldName: updatedArray,
    });
  };

  const handleSelectChange = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const handleSubmit = async () => {
    try {
      setBtnloading(true);
      if (state.product_id === null) {
        await addProductAPI({
          id: facility_type_id,
          product_name: state.product_name,
          cas_no: state.cas_no,
          therapeutic_segment_list: state.therapeutic_segment_list,
          dmf_cep_list: state.dmf_cep_list,
        });
        setState(initialState);
        formRef.current.setFieldsValue(initialState);
        SuccessNotificationMsg(t("msg_addProductSuccess"));
        setNewProductAdded(!newProductAdded);
        setBtnloading(false);
        setCurrentTab((prevTab) => ({
          ...prevTab,
          // child1: false,
          child2: false, // Set child2 to false or adjust based on your requirement
        }));
        setUnsavedChanges((prevTab) => ({
          ...prevTab,
          // outerClickChild1: false,
          outerClickChild2: false,
        }));
      } else {
        await updateProductAPI({
          id: productId,
          product_name: state.product_name,
          cas_no: state.cas_no,
          therapeutic_segment_list: state.therapeutic_segment_list,
          dmf_cep_list: state.dmf_cep_list,
        });
        setState(initialState);
        dispatch(resetAfterUpdate(true));
        formRef.current.setFieldsValue(initialState);
        SuccessNotificationMsg(t("msg_updateProductSuccess"));
        setNewProductAdded(!newProductAdded);
        setBtnloading(false);
        setCurrentTab((prevTab) => ({
          ...prevTab,
          // child1: false,
          child2: false, // Set child2 to false or adjust based on your requirement
        }));
        setUnsavedChanges((prevTab) => ({
          ...prevTab,
          // outerClickChild1: false,
          outerClickChild2: false,
        }));
      }
    } catch (err) {
      setBtnloading(false);
    }
  };

  return (
    <>
      <Form
        ref={formRef}
        onFinish={handleSubmit}
        autoComplete="off"
        onValuesChange={handleFormChange}
      >
        <Row gutter={[15]}>
          <Col xs={24} sm={12} lg={6}>
            <InputField
              name="product_name"
              label={t("pl_product_name")}
              placeholder={t("pl_product_name")}
              rules={t("val_product_name")}
              handleInputChange={handleInputChange}
              max={20}
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <NumberField
              name="cas_no"
              // type="number"
              label={t("pl_CAS_no")}
              placeholder={t("pl_CAS_no")}
              rules={t("val_CAS_no")}
              handleInputChange={handleInputChange}
              max={20}
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <EditableTherapeuticSegment
              mode="multiple"
              maxTagCount="responsive"
              handleSelectChange={handleSelectChange}
              data={therapeuticList}
              setDeleted={setDeleted}
              deleted={deleted}
              removeId={removeId}
              name="therapeutic_segment_list"
              value={state.therapeutic_segment_list}
              placeholder={t("pl_therapeutic_segment")}
              label={t("pl_therapeutic_segment")}
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <EditableDmfCep
              mode="multiple"
              maxTagCount="responsive"
              handleSelectChange={handleSelectChange}
              data={dmfCepList}
              setDeleted={setDeleted}
              deleted={deleted}
              removeId={removeId}
              name="dmf_cep_list"
              value={state.dmf_cep_list}
              placeholder={t("pl_dmf_cep")}
              label={t("pl_dmf_cep")}
            />
          </Col>

          <Col xs={24} className="savebtn">
            <Space size={[16, 16]}>
              <ButtonField
                type="primary"
                size="medium"
                htmlType="submit"
                loading={btnLoading}
                label={t("save")}
              />
              {state.product_id !== null && (
                <ButtonField
                  type="primary"
                  loading={btnLoading}
                  onClick={resetField}
                  label={t("cancel")}
                />
              )}
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
};

AddProduct.propTypes = {
  facility_type_id: PropTypes.any, // Adjust the type as per your needs
  setNewProductAdded: PropTypes.func,
  newProductAdded: PropTypes.bool,
  currentTab: PropTypes.any,
  setCurrentTab: PropTypes.func,
};

export default AddProduct;
