import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postRequest, getRequest, patchRequest } from "../../axios";

export const getCompanyLocation = createAsyncThunk(
  "get_company_location",
  async (id) => {
    const response = await getRequest("facility/list-facility/" + id);
    return response.data;
  }
);

export const ticketRequest = createAsyncThunk(
  "ticket_request",
  async (state) => {
    const response = await postRequest("tickets/ticket-request/", state);
    return response.data;
  }
);

// export const getAllTicketRequest = createAsyncThunk("all_ticket_request", async (state) => {
//     const response = await getRequest("tickets/get-ticket-details", state);
//     return response.data
// });
export const getAllTicketRequest = createAsyncThunk(
  "all_ticket_request",
  async (state, { rejectWithValue }) => {
    try {
      const response = await getRequest("tickets/get-ticket-details", state);

      // Check if response indicates no active subscription
      if (
        response?.data?.success === false &&
        response?.data?.message === "No active subscription found."
      ) {
        // Redirect to "concierge/" page
        window.location.href = window.location.origin + "/concierge";
        // window.location.href = window.location.origin + "/concierge-service";
        // navigate("/concierge");

        return rejectWithValue("No active subscription found.");
      }

      // Return data if no redirection is needed
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message); // Handle any other errors
    }
  }
);

export const updateTicketStatus = createAsyncThunk(
  "update_ticket_status",
  async (state) => {
    const response = await patchRequest("tickets/update-ticket-status/", state);
    return response.data;
  }
);

const initialState = {
  apiLoading: false,
  btnLoading: false,
  success: false,
  error: "",
  type: "",
  ticketList: [],
  companyLocation: [],
};
const tickets = createSlice({
  name: "tickets",
  initialState,
  reducers: {},
  extraReducers: {
    // get location

    [getCompanyLocation.pending]: (state) => {
      state.apiLoading = true;
    },
    [getCompanyLocation.fulfilled]: (state, action) => {
      return { ...state, apiLoading: false, companyLocation: action.payload };
    },
    [getCompanyLocation.rejected]: (state, action) => {
      state.apiLoading = false;
      state.error = action.payload;
    },

    // send ticket request

    [ticketRequest.pending]: (state) => {
      state.btnLoading = true;
    },
    [ticketRequest.fulfilled]: (state, action) => {
      state.btnLoading = false;
      state.type = action.type;
    },
    [ticketRequest.rejected]: (state, action) => {
      state.btnLoading = false;
      state.error = action.payload;
    },

    // get ticket request

    [getAllTicketRequest.pending]: (state) => {
      state.apiLoading = true;
    },
    [getAllTicketRequest.fulfilled]: (state, action) => {
      return { ...state, apiLoading: false, ticketList: action.payload };
    },
    [getAllTicketRequest.rejected]: (state, action) => {
      state.apiLoading = false;
      state.error = action.payload;
    },

    // update ticket status

    [updateTicketStatus.pending]: (state) => {
      state.btnLoading = true;
    },
    [updateTicketStatus.fulfilled]: (state, action) => {
      state.btnLoading = false;
      state.type = action.type;
    },
    [updateTicketStatus.rejected]: (state, action) => {
      state.btnLoading = false;
      state.error = action.payload;
    },
  },
});

export default tickets.reducer;
