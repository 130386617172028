import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Table, Popconfirm, Space, Form } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  deleteFormulation,
  getFormulationById,
  getFormulationCount,
  getFormulationMaster,
  getFormulationPlantLabId,
  getFormulationUtilityId,
} from "./AddFormulationSlice";
import { getRequest } from "../../../../../../axios";
import { SuccessNotificationMsg } from "../../../../../../utils/Notifications";
import { getProgressPrec } from "../../../../../profile/progressSlice";
import { authUserData } from "../../../../../../utils/Helper";

const FormulationList = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formRef = useRef();
  const group = authUserData()?.group;
  const { setCurrentTab } = props;

  const [formulationList, setFormulationList] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(false);

  const inialstate = {
    page: 1,
    ordering: "id",
    pagesize: 5,
    search: "",
    total: 0,
  };
  const [state, setState] = useState(inialstate);

  useEffect(() => {
    if (group === "Company Admin") {
      dispatch(getProgressPrec());
    }
    try {
      setLoading(true);
      const getAllFormulationAsync = async (data) => {
        await getRequest(
          `facility/list-create-formulations-product/${props.facility_id}`,
          data
        ).then((response) => {
          setFormulationList(response.data.results);

          setCount(response.data.count);
          if (response.data.results.length === 0) {
            setCurrentTab((prevTab) => ({
              ...prevTab,
              child4: true,
            }));
          } else {
            setCurrentTab((prevTab) => ({
              ...prevTab,
              child4: false,
            }));
          }
          dispatch(getFormulationCount(response.data.count));
          dispatch(
            getFormulationMaster(response.data.results[0].master_formulation)
          );
          dispatch(
            getFormulationPlantLabId(response.data.results[0].plant_lab_id)
          );
          dispatch(
            getFormulationUtilityId(response.data.results[0].utility_id)
          );
        });
      };
      props.facility_id && getAllFormulationAsync(state);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [
    props.newFormulationAdded,
    props.facility_id,
    deleted,
    state,
    dispatch,
    group,
  ]);

  const handleTableChange = async (pagination, filters, sorter, ordering) => {
    const orderBy = GetSortingEnumValue(sorter.columnKey, sorter.order);
    setState({
      ...state,
      page: pagination.current,
      pagesize: pagination.pageSize,
      ordering: orderBy,
    });
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = "id";

    if (sortField === "formulation_type_name" && sortOrder === "ascend") {
      sortingEnumKey = "formulation_type";
    }
    if (sortField === "formulation_type_name" && sortOrder === "descend") {
      sortingEnumKey = "-formulation_type";
    }

    if (sortField === "monthly_capacity" && sortOrder === "ascend") {
      sortingEnumKey = "monthly_capacity";
    }
    if (sortField === "monthly_capacity" && sortOrder === "descend") {
      sortingEnumKey = "-monthly_capacity";
    }

    return sortingEnumKey;
  };

  const confirmDelete = async (id) => {
    await deleteFormulation(id);
    SuccessNotificationMsg(t("msg_formulationDeleted"));
    setDeleted(!deleted);
  };

  const handleEditButtonClick = async (id) => {
    dispatch(getFormulationById(id));
    props.setRadioValue("addFormulation");
    props.setFormulationBulkUploadComponent(false);
  };

  const columns = [
    {
      title: "Formulation Type",
      dataIndex: "formulation_type_name",
      key: "formulation_type_name",
      sorter: true,
    },
    {
      title: "Capacity (qty/month)",
      dataIndex: "monthly_capacity",
      key: "monthly_capacity",
      sorter: true,
    },
    {
      title: "Action",
      className: props.hideAction && "hide",
      render: (e, record) => (
        <Space size={[12, 16]}>
          <Space size="middle">
            <EditOutlined onClick={() => handleEditButtonClick(record.id)} />

            <Popconfirm
              className="action"
              title={t("pop_title")}
              okText={t("pop_ok")}
              cancelText={t("pop_cancel")}
              onConfirm={() => confirmDelete(record.id)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Form ref={formRef} autoComplete="off">
        <Table
          className="table_grid"
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={formulationList}
          scroll={{ x: 500 }}
          loading={loading}
          pagination={{
            defaultCurrent: state.page,
            defaultPageSize: state.pagesize,
            total: count,
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
        />
      </Form>
    </>
  );
};

FormulationList.propTypes = {
  facility_id: PropTypes.string,
  newFormulationAdded: PropTypes.bool,
  setNewFormulationAdded: PropTypes.func,
  hideAction: PropTypes.bool,
  setRadioValue: PropTypes.func,
  setFormulationBulkUploadComponent: PropTypes.func,
  setCurrentTab: PropTypes.func,
};

export default FormulationList;
