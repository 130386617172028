import React, { createContext, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
// Step 1: Create a Context
const UnsavedChangesContext = createContext();

// Step 2: Create a Provider Component
export const UnsavedChangesProvider = ({ children }) => {
  // const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState({
    outerClickChild1: false,
    outerClickChild2: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [clickedPath, setClickedPath] = useState(null);

  const handleModalCancel = () => {
    setShowModal(false);
    // setUnsavedChanges(false);
    setClickedPath(null);
  };

  const handleModalConfirm = () => {
    setShowModal(false);
    // setUnsavedChanges(false);
    if (clickedPath) {
      // navigate(clickedPath);
    }
  };

  const handleNavigate = (path) => {
    if (!unsavedChanges) {
      // Navigate to path
    } else {
      setShowModal(true);
      setClickedPath(path);
      // navigate(clickedPath);
    }
  };

  return (
    <UnsavedChangesContext.Provider
      value={{
        unsavedChanges,
        setUnsavedChanges,
        showModal,
        setShowModal,
        clickedPath,
        setClickedPath,
        handleModalCancel,
        handleModalConfirm,
        handleNavigate,
      }}
    >
      {children}
    </UnsavedChangesContext.Provider>
  );
};

UnsavedChangesProvider.propTypes = {
  children: PropTypes.node.isRequired, // children is required and should be a React node
};

// Step 3: Use the Context in your Components
export const useUnsavedChanges = () => useContext(UnsavedChangesContext);
