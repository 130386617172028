export const TRANSLATIONS_GJ = {
  loginTitle: "લૉગિન",
  registration: "નોંધણી",
  home: "Home",
  search: "શોધ",
  ok: "બરાબર",
  add: "ઉમેરો",
  back: "પાછા",
  cancel: "રદ કરો",
  save: "નોંધ કરો",
  edit: "ફેરફાર કરો",
  submit: "સબમિટ",
  print: "છાપો",
  delete: "કાઢી નાખો",
  login: "પ્રવેશ કરો",
  keep_me_login: "મને આ ઉપકરણ પર લૉગ ઇન રાખો",
  dont_have_acc: "Don't Have An Account Yet?",
  dont_verify_acc: "Didn't Verify Account Yet?",
  go_to_app: "એપ્લિકેશન પર જાઓ",
  sign_up: "Sign Up",
  verify: "ચકાસો",
  reset: "રીસેટ",
  register: "નોંધણી કરો",
  email: "ઇમેઇલ",
  forgot_password: "પાસવર્ડ ભૂલી ગયા છો?",
  i_accept_the: "હું સ્વીકારું છું",
  terms_and_conditions: "નિયમો અને શરત",
  dashboard: "Dashboard",
  forgot_password_msg:
    "તમારું ઈ-મેલ સરનામું દાખલ કરો અને અમે તમને તમારો પાસવર્ડ રીસેટ કરવા માટે એક લિંક મોકલીશું",
  create_new_password: "નવો પાસવર્ડ બનાવો",
  verify_account: "Account ચકાસણી",
  verify_account_msg:
    "કૃપા કરીને ચકાસણી કોડનો ઉપયોગ કરીને તમારા ઇમેઇલને ચકાસો.",
  registration_successfull: "નોંધણી સફળ!",
  registration_successfull_msg:
    "ઇમેઇલ ચકાસણી માટે કૃપા કરીને તમારું નોંધાયેલ ઇમેઇલ તપાસો.",
  passsword_conditions:
    "પાસવર્ડ ઓછામાં ઓછા છ અક્ષરનો, ઓછામાં ઓછો એક અક્ષર અને એક નંબર અને એક વિશેષ અક્ષરનો હોવો જોઈએ.",
  password_notconfirm_msg: "તમે દાખલ કરેલ બે પાસવર્ડ મેળ ખાતા નથી!",

  pl_firstName: "પ્રથમ નામ",
  pl_middleName: "વચલું નામ",
  pl_lastName: "છેલ્લું નામ",
  pl_phone: "ફોન નં.",
  pl_designation: "હોદ્દો",
  pl_aadharcardNo: "આધારકાર્ડ નં.",
  pl_password: "પાસવર્ડ",
  pl_confirm_password: "પાસવર્ડની પુષ્ટિ કરો",
  pl_email: "ઈ - મેઈલ સરનામું",
  pl_landline: "લેન્ડલાઇન",
  pl_registrationNo: "નોંધણી નં.",
  pl_faxNumber: "ફેક્સ નં.",
  pl_degree: "ડીગ્રી",
  pl_speciality: "વિશેષતા",


  // pop-ups

  pop_title: "શું તમે ખરેખર કાઢી નાખશો?",
  pop_ok: "હા",
  pop_cancel: "ના",
};
