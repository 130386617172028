import React, { useEffect, useState } from "react";
import { Space, Table, Modal, Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  EyeOutlined,
  CheckCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { getRequest, postRequest } from "../../axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import dummyCert from "../../images/checked.png";
import SendInquiry from "../businessLounge/SendInquiry";
import ProductList from "../manufacturingFacility/facilities/facilityType/api/addProduct/ProductList";
import FormulationList from "../manufacturingFacility/facilities/facilityType/formulation/addFormulation/FormulationList";
import { resetPaging } from "../businessLounge/BuisenessloungeSlice";
import { authUserData } from "../../utils/Helper";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/Notifications";
import config from "../../Config";

const ShortlistedCompanyGrid = ({ type }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenb, setIsModalOpenb] = useState(false);
  const [isModalOpenc, setIsModalOpenc] = useState(false);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();

  const resetPage = useSelector((state) => state.BLPage.resetPage);

  const initialState = {
    page: 1,
    ordering: "id",
    pagesize: 10,
    search: "",
    total: 0,
  };
  const [state, setState] = useState(initialState);

  const [facilityList, setFacilityList] = useState([]);
  const [totalFacilityCount, settotalFacilityCount] = useState(0);
  const [facilityId, setFacilityId] = useState(undefined);
  const [manufacturingCapacity, setManufacturingCapacity] = useState(undefined);
  const [totalqty, settotalQty] = useState(undefined);

  const [to_email, setto_email] = useState("");
  const [to_company, setto_company] = useState("");

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelb = () => {
    setIsModalOpenb(false);
  };

  const handleCancelc = () => {
    setIsModalOpenc(false);
  };

  const [checked, setChecked] = useState(true);
  const toggleChecked = async (id, value) => {
    await postRequest("facility/list-create-favorite/" + id, {
      shortlisted: value ? "True" : "False",
    })
      .then((response) => {
        if (response.data.data.shortlisted === false) {
          SuccessNotificationMsg(t("msg_facility_unlike"), "top");
        }
      })
      .catch((error) => {});
    setChecked(!checked);
  };

  // useEffect(() => {
  //   const getAllAPIFacilities = async (data) => {
  //     setloading(true);
  //     await getRequest(`facility/get-business-lounge/?shortlisted=true`, {
  //       ...data,
  //       page: resetPage ? 1 : data.page,
  //     })
  //       .then((response) => {
  //         if (response.status === 200 && data) {
  //           setFacilityList(response.data.results);
  //           settotalFacilityCount(response.data.count);
  //         }
  //         setloading(false);
  //       })
  //       .catch((error) => {
  //         setloading(false);
  //         ErrorNotificationMsg(error);
  //       });
  //   };
  //   try {
  //     getAllAPIFacilities(state);
  //   } catch (error) {
  //     setloading(false);
  //     ErrorNotificationMsg(error);
  //   }
  // }, [state, checked, resetPage]);

  useEffect(() => {
    const getAllAPIFacilities = async (data) => {
      setloading(true);
      try {
        const response = await getRequest(
          `facility/get-business-lounge/?shortlisted=true`,
          {
            ...data,
            page: resetPage ? 1 : data.page,
          }
        );

        if (response?.status === 200 && data) {
          setFacilityList(response?.data?.results);
          settotalFacilityCount(response?.data?.count);
        }

        setloading(false);

        // Check if response indicates no active subscription
        if (
          response?.data?.success === false &&
          response?.data?.message === "No active subscription found."
        ) {
          // Redirect to "concierge/" page
          navigate("/concierge"); // You need to implement this function
        }
      } catch (error) {
        setloading(false);
        ErrorNotificationMsg(error);
      }
    };

    getAllAPIFacilities(state);
  }, [state, checked, resetPage]);

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = "id";

    if (sortField === "company" && sortOrder === "ascend") {
      sortingEnumKey = "company";
    }
    if (sortField === "company" && sortOrder === "descend") {
      sortingEnumKey = "-company";
    }

    if (sortField === "facility_type" && sortOrder === "ascend") {
      sortingEnumKey = "facility_type";
    }
    if (sortField === "facility_type" && sortOrder === "descend") {
      sortingEnumKey = "-facility_type";
    }

    return sortingEnumKey;
  };

  const handleTableChange = async (pagination, filters, sorter, ordering) => {
    dispatch(resetPaging(false));
    const orderBy = GetSortingEnumValue(sorter.columnKey, sorter.order);
    setState({
      ...state,
      page: pagination.current,
      pagesize: pagination.pageSize,
      ordering: orderBy,
    });
  };

  const showModalc = async (to_company, to_email) => {
    setto_email(to_email);
    setto_company(to_company);
    setIsModalOpenc(true);
  };

  const SendInquiy = async (data) => {
    await postRequest("inquiry/send-inquiry/", data)
      .then((response) => {
        if (response.status) {
          SuccessNotificationMsg(t("msg_sent_inquiry"));
        }
      })
      .catch((error) => {});
    handleCancelc();
  };

  const openManufacturingCapcity = (id, id2, id3) => {
    setFacilityId(id);
    setManufacturingCapacity(id2);
    settotalQty(id3);
    setIsModalOpen(true);
  };

  const openFormulationCapcity = (id) => {
    setFacilityId(id);
    setIsModalOpenb(true);
  };

  const colors = ["#D9E0E2"];

  const columns = [
    {
      title: "Facility name",
      dataIndex: "company",
      key: "company",
      sorter: true,
    },

    {
      title: "Facility Type",
      dataIndex: "facility_type",
      key: "facility_type",
      sorter: true,
    },
    {
      title: "Manufacturing Capacity",
      dataIndex: "ManufacturingCapacity",
      key: "ManufacturingCapacity",
      className: type === "Formulation" && "hide",
      render: (e, res) => (
        <button
          onClick={(e) =>
            openManufacturingCapcity(
              res.id,
              res.facility_api.manufacturing_capacity,
              res.facility_api.total_qty
            )
          }
          className="link-color"
          style={{
            background: "none",
            border: "none",
            padding: 0,
            cursor: "pointer",
            color: "#022f4",
          }}
        >
          {(res.facility_type === "API" || res.facility_type === "CDMO") && (
            <>{t("detail")}</>
          )}
        </button>
      ),
    },
    {
      title: "Formulation Capacity",
      dataIndex: "FormulationCapacity",
      key: "FormulationCapacity",
      className: type === "API" && "hide",
      render: (e, res) => (
        <button
          onClick={(e) => openFormulationCapcity(res.id)}
          className="link-color"
          style={{
            background: "none",
            border: "none",
            padding: 0,
            cursor: "pointer",
            color: "#022f4",
          }}
        >
          {(res.facility_type === "Formulation" ||
            res.facility_type === "CDMO") && <>{t("detail")}</>}
        </button>
      ),
    },
    {
      title: "Certification",
      key: "facility_regulator",
      dataIndex: "facility_regulator",
      render: (e, res) => (
        <Space size={[12, 16]}>
          {res.facility_regulator.map(function (cValue, idx) {
            return (
              <>
                <div className="countrynm" key={cValue.id}>
                  <Tooltip
                    color={colors}
                    title={
                      <span style={{ color: "#000000" }}>
                        {cValue.certification}{" "}
                      </span>
                    }
                  >
                    <img
                      width="33"
                      height="33"
                      src={
                        cValue.certification_icon
                          ? `${config.BUCKET_URL}/certificate-files/${cValue.certification_icon}`
                          : dummyCert
                      }
                      alt=""
                      data-tooltip-id="my-cValue"
                      data-tooltip-content={cValue.certification}
                    />
                  </Tooltip>
                </div>
              </>
            );
          })}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "Action",
      dataIndex: "Action", // this is the value that is parsed from the DB / server side
      render: (e, res) => (
        <Space size={[12, 16]}>
          {authUserData()?.user_permission.includes(
            "facility.view_facility"
          ) && (
            <Link
              className="action"
              to={{
                pathname: "/manage-facility/view-facility/" + btoa(res.id)+"/shortlisted-company",
              }}
              target="_blank"
            >
              <Tooltip
                color={colors}
                title={
                  <span style={{ color: "#000000" }}>
                    {" "}
                    View your listed manufacturing facility with given details.{" "}
                  </span>
                }
              >
                <EyeOutlined />
              </Tooltip>
            </Link>
          )}
          {res.liked ? (
            <>
              <Tooltip
                color={colors}
                title={
                  <span style={{ color: "#000000" }}>{"Shortlisted"}</span>
                }
              >
                <CheckCircleOutlined
                  style={{ color: res.liked && "#337ab7" }}
                  onClick={(e) => toggleChecked(res.id, !res.liked)}
                />
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip
                color={colors}
                title={<span style={{ color: "#000000" }}>{"Shortlist"}</span>}
              >
                <CheckCircleOutlined
                  onClick={(e) => toggleChecked(res.id, !res.liked)}
                />
              </Tooltip>
            </>
          )}
          <SendOutlined
            onClick={(e) => showModalc(res.company, res.facility_email)}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        className="table_grid"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={facilityList}
        scroll={{ x: 970 }}
        loading={loading}
        pagination={{
          defaultCurrent: state.page,
          defaultPageSize: state.pagesize,
          total: totalFacilityCount,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />

      <Modal
        title="API"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        width={900}
        className="APImodel"
      >
        <ProductList
          facility_id={facilityId}
          manufacturingCapacity={manufacturingCapacity}
          totalqty={totalqty}
          hideAction={true}
        />
      </Modal>

      <Modal
        title="Formulation"
        open={isModalOpenb}
        footer={null}
        onCancel={handleCancelb}
        width={600}
        className="APImodel"
      >
        <FormulationList facility_id={facilityId} hideAction={true} />
      </Modal>
      <Modal
        title="Send Inquiry"
        open={isModalOpenc}
        footer={null}
        onCancel={handleCancelc}
        width={480}
        className="APImodel"
      >
        <SendInquiry
          to_company={to_company}
          to_email={to_email}
          sendInquiry={SendInquiy}
        />
      </Modal>
    </>
  );
};

ShortlistedCompanyGrid.propTypes = {
  type: PropTypes.string.isRequired, // Adjust the PropTypes based on the actual type
};

export default ShortlistedCompanyGrid;
