import React, { useRef, useState } from "react";
import { Row, Col, Form, Checkbox, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { patchRequest } from "../../axios";
import ButtonField from "../../common/htmlComponents/ButtonField";
import { authUserData } from "../../utils/Helper";
import config from "../../Config";
import { ErrorNotificationMsg } from "../../utils/Notifications";

const CompanyConsentModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formRef = useRef();

  const initialState = {
    company_inquiry_consent: false,
    company_data_store_consent: false,
  };
  const [state, setState] = useState(initialState);
  const [btnLoading, setBtnloading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const auth_data = JSON.parse(localStorage.getItem("auth_data"));

  const handleSubmit = async () => {
    try {
      setBtnloading(true);
      await patchRequest(
        "user/update-my-company-chekbox/" + authUserData()?.user_id,
        state
      ).then((apiResponse) => {
        if (apiResponse.data.success) {
          setState(apiResponse.data.data);
          var obj = {
            ...auth_data,
            company_inquiry_consent:
              apiResponse.data.data.company_inquiry_consent,
            company_data_store_consent:
              apiResponse.data.data.company_data_store_consent,
          };
          localStorage.setItem("auth_data", JSON.stringify(obj));
          setBtnloading(false);
          setIsModalOpen(false);
          window.location.reload(true);
        } else {
          ErrorNotificationMsg(t("err_getConsent"));
        }
      });
    } catch (error) {
      setBtnloading(false);
      ErrorNotificationMsg(error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    navigate("/business-lounge");
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        width={900}
        className="APImodel"
        wrapClassName="modal-wrapper"
      >
        <Form onFinish={handleSubmit} autoComplete="off" ref={formRef}>
          <Row gutter={[15]} className="sendinquiry">
            <Col xs={24} sm={24} lg={24} align="start">
              <Col xs={24}>{t("companyDetail4")}</Col>
              <br />
              <Col xs={24}>
                {t("companyDetail1")}
                <Form.Item
                  name="company_inquiry_consent"
                  className="checkwrap"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(t("val_inquiry_consent")),
                    },
                  ]}
                >
                  <Checkbox
                    checked={state.company_inquiry_consent}
                    onChange={(e) =>
                      setState({
                        ...state,
                        company_inquiry_consent: e.target.checked,
                      })
                    }
                  >
                    {t("inquiry_consent")}
                    <span className="required-star">*</span>
                  </Checkbox>
                </Form.Item>
                {t("companyDetail2")}
                <Form.Item
                  name="company_data_store_consent"
                  className="checkwrap"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(t("val_data_store_consent")),
                    },
                  ]}
                >
                  <Checkbox
                    checked={state.company_data_store_consent}
                    onChange={(e) =>
                      setState({
                        ...state,
                        company_data_store_consent: e.target.checked,
                      })
                    }
                  >
                    {t("data_store_consent")}
                    <span className="required-star">*</span>
                  </Checkbox>
                </Form.Item>
                {t("for_additional")}
                <a
                  // href="https://dev3.pharmalinkage.com/privacy-policy.html"
                  href={`${config.WEBSITE_URL}/privacy-policy.html`}
                  className="button-link"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {t("privacy_policy")}.
                </a>
              </Col>
            </Col>
          </Row>
          <br />

          <Row gutter={[15]} className="bottomButtons">
            <Col xs={24} align="end" className="text-right">
              <ButtonField
                type="primary"
                htmlType="submit"
                loading={btnLoading}
                label={t("agree")}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CompanyConsentModal;
