import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Space, Spin, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { postRequest } from "../../../../../../axios";
import { loadingIcon } from "../../../../../../utils/Helper";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../../../../../utils/Notifications";
import config from "../../../../../../Config";

const FormulationBulkUpload = ({
  facility_id,
  newFormulationAdded,
  setNewFormulationAdded,
  setCurrentTab,
}) => {
  const { t } = useTranslation();

  const [uploadFile, setUploadFile] = useState("");
  const [fileName, setFileName] = useState(null);
  const [apiloading, setapiLoading] = useState(false);

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("formulation_list", uploadFile);
      setapiLoading(true);

      const apiResponse = await postRequest(
        "facility/upload-formulation/" + facility_id,
        formData
      );
      if (apiResponse.data.status) {
        SuccessNotificationMsg(t("msg_uploadSuccess"));
        setNewFormulationAdded(!newFormulationAdded);
        setCurrentTab((prevTab) => ({
          ...prevTab,
          // child1: false,
          child1: false, // Set child2 to false or adjust based on your requirement
        }));
      } else {
        ErrorNotificationMsg(t("err_uploadSuccess"));
      }
      setapiLoading(false);
    } catch (error) {
      setapiLoading(false);
      ErrorNotificationMsg(t("err_uploadSuccess"));
    }
  };

  const uploadProps = {
    accept: ".csv",
    showUploadList: false,
    beforeUpload: (file) => {
      setUploadFile(file);
      setFileName(file.name);
      return false;
    },
    onChange: handleUpload,
  };

  return (
    <div className="uploadwrap">
      <div className="uploadinside">
        <h5>
          {t("upload_excel_option")} (
          <a
            // href="https://pharmalinkage-dev-files.s3.ap-south-1.amazonaws.com/sample-files/Formulation_Sample.csv"
            href={`${config.BUCKET_URL}/sample-files/Formulation_Sample.csv`}
            className="link-color"
          >
            {t("download_sample_format")}
          </a>
          )
        </h5>
        <Upload {...uploadProps}>
          <Button type="primary" size="small" icon={<UploadOutlined />}>
            {t("upload")}
          </Button>
          <div className="condition">
            {" "}
            <p style={{ color: "GrayText" }}>{t("upload_fileSize")} (.csv)</p>
          </div>
        </Upload>
        <Space size={[12, 16]}>
          {/* <span className="link-color">{fileName}</span> */}
          <Spin spinning={apiloading} indicator={loadingIcon} />
        </Space>
      </div>
    </div>
  );
};

FormulationBulkUpload.propTypes = {
  facility_id: PropTypes.string.isRequired,
  newFormulationAdded: PropTypes.bool.isRequired,
  setNewFormulationAdded: PropTypes.func.isRequired,
  setCurrentTab: PropTypes.func,
};

export default FormulationBulkUpload;
