import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Table, Popconfirm, Space, Form, Row, Col } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  deleteProductAPI,
  getProductById,
  getProductCount,
} from "../redux/AddProductSlice";
import { SuccessNotificationMsg } from "../../../../../../utils/Notifications";
import { getRequest } from "../../../../../../axios";
import { getProgressPrec } from "../../../../../profile/progressSlice";
import { authUserData } from "../../../../../../utils/Helper";

const ProductList = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formRef = useRef();
  const group = authUserData()?.group;
  const { setCurrentTab } = props;

  const [productList, setProductList] = useState([]);
  const [count, setCount] = useState();
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialState = {
    page: 1,
    ordering: "id",
    pagesize: 5,
    search: "",
    total: 0,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    try {
      setLoading(true);
      const getAllProductsAsync = async (data) => {
        await getRequest(
          `facility/list-create-api-product/${props.facility_id}`,
          data
        ).then((response) => {
          setProductList(response.data.results);
          setCount(response.data.count);

          dispatch(getProductCount(response.data.count));
          if (group === "Company Admin") {
            dispatch(getProgressPrec());
          }
          // if (response?.data?.results.length === 0) {
          //   setCurrentTab((prevTab) => ({
          //     ...prevTab,
          //     child4: true,
          //   }));
          // } else {
          //   setCurrentTab((prevTab) => ({
          //     ...prevTab,
          //     child4: false,
          //   }));
          // }
        });
      };
      getAllProductsAsync(state);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [
    props.newProductAdded,
    props.facility_id,
    deleted,
    state,
    dispatch,
    group,
  ]);

  const handleTableChange = async (pagination, filters, sorter, ordering) => {
    const orderBy = GetSortingEnumValue(sorter.columnKey, sorter.order);

    setState({
      ...state,
      page: pagination.current,
      pagesize: pagination.pageSize,
      ordering: orderBy,
    });
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = "id";

    if (sortField === "product_name" && sortOrder === "ascend") {
      sortingEnumKey = "product_name";
    }
    if (sortField === "product_name" && sortOrder === "descend") {
      sortingEnumKey = "-product_name";
    }

    if (sortField === "cas_no" && sortOrder === "ascend") {
      sortingEnumKey = "cas_no";
    }
    if (sortField === "cas_no" && sortOrder === "descend") {
      sortingEnumKey = "-cas_no";
    }
    return sortingEnumKey;
  };

  const confirmDelete = async (id) => {
    await deleteProductAPI(id);
    SuccessNotificationMsg(t("msg_productDeleted"));
    setDeleted(!deleted);
  };

  const handleEditButtonClick = async (id) => {
    dispatch(getProductById(id));
    props.setRadioValue("addProduct");
    props.setBulkProductUploadComponent(false);
  };

  const columns = [
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      sorter: true,
    },
    {
      title: "CAS No",
      dataIndex: "cas_no",
      key: "cas_no",
      sorter: true,
    },
    {
      title: "Therapeutic Segment",
      dataIndex: "therapeutic_list",
      key: "therapeutic_list",
      render: (therapeutic_list) =>
        therapeutic_list &&
        therapeutic_list.map((value) => value.therapeutic_segment).join(", "),
    },
    {
      title: "Information on Regulatory Approvals",
      dataIndex: "dmf_cep_list",
      key: "dmf_cep_list",
      render: (dmf_cep_list) =>
        dmf_cep_list && dmf_cep_list.map((value) => value.dmf_cep).join(", "),
    },
    {
      title: "Action",
      className: props.hideAction && "hide",
      render: (e, record) => (
        <Space size={[12, 16]}>
          <Space size="middle">
            <EditOutlined onClick={() => handleEditButtonClick(record.id)} />

            <Popconfirm
              className="action"
              title={t("pop_title")}
              okText={t("pop_ok")}
              cancelText={t("pop_cancel")}
              onConfirm={() => confirmDelete(record.id)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Form ref={formRef} autoComplete="off">
        {props.manufacturingCapacity && props.totalqty && (
          <Row gutter={[15]} className="modelhead">
            <Col xs={24}>
              <label>
                {t("pl_manufacturing_capacity_kl")}:{" "}
                {props.manufacturingCapacity}{" "}
              </label>
            </Col>
            <Col xs={24}>
              <label>
                {t("total_quantity")}: {props.totalqty}
              </label>
            </Col>
          </Row>
        )}
        <Table
          className="table_grid"
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={productList}
          scroll={{ x: 500 }}
          loading={loading}
          pagination={{
            defaultCurrent: state.page,
            defaultPageSize: state.pagesize,
            total: count,
            showSizeChanger: true,
          }}
          onChange={handleTableChange}
        />
      </Form>
    </>
  );
};

ProductList.propTypes = {
  facility_id: PropTypes.any, // Adjust the type as per your needs
  newProductAdded: PropTypes.bool,
  setRadioValue: PropTypes.func,
  setBulkProductUploadComponent: PropTypes.func,
  hideAction: PropTypes.bool,
  manufacturingCapacity: PropTypes.number,
  totalqty: PropTypes.number,
  setCurrentTab: PropTypes.func,
};

export default ProductList;
