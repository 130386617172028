import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Checkbox } from "antd";
import { Trans, useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { postRequest } from "../axios";
import { useNavigate } from "react-router-dom";
import InputField from "../common/htmlComponents/InputField";
import TextAreaField from "../common/htmlComponents/TextAreaField";
import ButtonField from "../common/htmlComponents/ButtonField";
import EmailField from "../common/htmlComponents/EmailField";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../utils/Notifications";
import config from "../Config";
import { authUserData } from "../utils/Helper";
const RequestForPriceModal = ({ id, subscription_name, setIsModalOpenc }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const navigate = useNavigate();
  // concierge: id,
  // name: authUserData()?.first_name,
  // email: authUserData()?.email,
  // contact_no: authUserData()?.phone,
  // company: authUserData()?.company_id,
  // message: "",
  // inquiry_consent: false,
  // data_store_consent: false,
  // subscription_name,
  const initialState = {
    concierge: id,
    company: authUserData()?.company_id,
    name: "",
    email: "",
    contact_no: "",
    // company: "",
    message: "",
    inquiry_consent: false,
    data_store_consent: false,
    terms_and_conditions: false,
    subscribe_newsletter: false,
    subscription_name,
  };
  const [state, setState] = useState(initialState);
  const [btnLoading, setBtnloading] = useState(false);

  useEffect(() => {
    formRef.current.setFieldsValue(initialState);
    setState({ ...state, subscription_name });
  }, [subscription_name]);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  let phoneValidLength = state.contact_no;

  const validatePhoneNumber = () => {
    if (
      (phoneValidLength && phoneValidLength.length < 8) ||
      phoneValidLength === ""
    ) {
      return Promise.reject(new Error("Please enter valid phone."));
    }
    return Promise.resolve();
  };

  const handleSubmit = async () => {
    try {
      setBtnloading(true);
      await postRequest("concierge/request-for-price/", state).then(
        // await postRequest(
        //   "websitenewsletter/website-request-for-prise/",
        //   state
        // ).then(
        // await postRequest("concierge/request-for-price-website/", state).then(
        (apiResponse) => {
          if (apiResponse.status) {
            SuccessNotificationMsg(t("price_requested"));
            formRef.current.setFieldsValue(initialState);
            setState(initialState);
            setIsModalOpenc(false);
            // navigate("/");
          }
        }
      );
    } catch (error) {
      setBtnloading(false);
      ErrorNotificationMsg(error);
    }
  };

  return (
    <>
      <Form
        onFinish={handleSubmit}
        autoComplete="off"
        ref={formRef}
        initialValues={initialState}
      >
        <Row gutter={[15]} className="sendinquiry">
          <Col xs={24} sm={16} lg={10}>
            <InputField
              label={t("concierge_name")}
              placeholder={t("ph_concierge_name")}
              name="subscription_name"
              value={state.subscription_name}
              readOnly
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <InputField
              label={t("pl_name")}
              placeholder={t("pl_name")}
              name="name"
              rules={t("val_name")}
              handleInputChange={handleInputChange}
              value={state.name}
              max={30}
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <EmailField
              name="email"
              label={t("pl_personalEmail")}
              placeholder={t("pl_personalEmail")}
              rules={t("val_email")}
              handleInputChange={handleInputChange}
              value={state.email}
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <Form.Item
              name="contact_no"
              label={t("whatsappNo")}
              rules={[
                {
                  required: true,
                  validator: validatePhoneNumber,
                  message: <Trans> {t("val_phone")}</Trans>,
                },
              ]}
            >
              <PhoneInput
                country={"in"}
                value={state.contact_no}
                onChange={(phone) => setState({ ...state, contact_no: phone })}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={8} lg={8}>
            <InputField
              label={t("pl_company")}
              placeholder={t("pl_company")}
              name="company"
              rules={t("val_companyName")}
              handleInputChange={handleInputChange}
              value={state.company}
              max={30}
            />
          </Col> */}
          <Col xs={24}>
            <TextAreaField
              label={t("pl_msg")}
              placeholder={t("pl_message")}
              name="message"
              rules={t("val_msg")}
              handleInputChange={handleInputChange}
              value={state.message}
              max={1000}
            />
          </Col>
          <Col xs={24} sm={24} lg={24} align="start">
            <Col xs={24}>
              {t("companyDetail4")}
              <br />
              <br />
              {t("companyDetail1")}
              <br />
              <Form.Item
                name="inquiry_consent"
                className="checkwrap"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(t("val_inquiry_consent")),
                  },
                ]}
              >
                <Checkbox
                  checked={state.inquiry_consent}
                  onChange={(e) =>
                    setState({ ...state, inquiry_consent: e.target.checked })
                  }
                >
                  {t("inquiry_consent")}
                  <span className="required-star">*</span>
                </Checkbox>
              </Form.Item>
              {t("companyDetail2")}
              <Form.Item
                name="data_store_consent"
                className="checkwrap"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(t("val_data_store_consent")),
                  },
                ]}
              >
                <Checkbox
                  checked={state.data_store_consent}
                  onChange={(e) =>
                    setState({ ...state, data_store_consent: e.target.checked })
                  }
                >
                  {t("data_store_consent")}
                  <span className="required-star">*</span>
                </Checkbox>
              </Form.Item>
              {t("learn_more")} {t("please_read1")} &nbsp;
              <a
                // href="https://dev3.pharmalinkage.com/terms-conditions.html"
                href={`${config.WEBSITE_URL}/terms-conditions.html`}
                className="button-link"
                target={"_blank"}
                rel="noreferrer"
              >
                {t("terms_and_conditions")}
              </a>
              &nbsp;
              {t("and")} &nbsp;
              <a
                // href="https://dev3.pharmalinkage.com/privacy-policy.html"
                href={`${config.WEBSITE_URL}/privacy-policy.html`}
                className="button-link"
                target={"_blank"}
                rel="noreferrer"
              >
                {t("privacy_policy")}.<span className="required-star">*</span>
              </a>{" "}
              &nbsp;
              {t("please_read2")}
              <Form.Item
                name="terms_and_conditions"
                className="checkwrap"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(t("val_terms_and_conditions")),
                  },
                ]}
              >
                <Checkbox
                  checked={state.terms_and_conditions}
                  onChange={(e) =>
                    setState({
                      ...state,
                      terms_and_conditions: e.target.checked,
                    })
                  }
                >
                  {t("i_accept_the")}
                  &nbsp;
                  <a
                    // href="https://dev3.pharmalinkage.com/terms-conditions.html"
                    href={`${config.WEBSITE_URL}/terms-conditions.html`}
                    className="button-link"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {t("terms_and_conditions")}
                  </a>
                  &nbsp;
                  {t("and")} &nbsp;
                  <a
                    // href="https://dev3.pharmalinkage.com/privacy-policy.html"
                    href={`${config.WEBSITE_URL}/privacy-policy.html`}
                    className="button-link"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {t("privacy_policy")}.
                    <span className="required-star">*</span>
                  </a>{" "}
                  <span className="required-star">*</span>
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="subscribe_newsletter"
                className="checkwrap"
                valuePropName="checked"
              >
                <Checkbox
                  checked={state.subscribe_newsletter}
                  onChange={(e) =>
                    setState({
                      ...state,
                      subscribe_newsletter: e.target.checked,
                    })
                  }
                >
                  {t("i_recieve_newsletter")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <br />

        <Row gutter={[15]} className="bottomButtons">
          <Col xs={24} align="end" className="text-right">
            <ButtonField
              type="primary"
              htmlType="submit"
              loading={btnLoading}
              label={t("submit")}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
};

RequestForPriceModal.propTypes = {
  id: PropTypes.number.isRequired,
  subscription_name: PropTypes.string.isRequired,
  setIsModalOpenc: PropTypes.func.isRequired,
};

export default RequestForPriceModal;
