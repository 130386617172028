import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, SendOutlined } from "@ant-design/icons";
import { confirmAlert } from "react-confirm-alert";
import { debounce } from "lodash";
import { Row, Col, Input, Table, Popconfirm, Space, Select } from "antd";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../styles/Main.scss";
import "antd/dist/antd.css";
import { deleteRequest, getRequest, patchRequest } from "../../axios";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../utils/Notifications";

const { Search } = Input;
const { Option } = Select;

const RequestedConcierge = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [requestedConcierge, setRequestedConcierge] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [deleted, setDeleted] = useState(false);

  const [state, setState] = useState({
    page: 1,
    ordering: "id",
    pagesize: 10,
    search: "",
    total: 0,
  });

  useEffect(() => {
    const getAllRequestedConcierge = async (data) => {
      setLoading(true);
      await getRequest("concierge/request-for-price/", data)
        .then((response) => {
          setRequestedConcierge(response.data.results);
          settotalCount(response.data.count);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          ErrorNotificationMsg(error);
        });
    };
    try {
      getAllRequestedConcierge(state);
    } catch (error) {
      ErrorNotificationMsg(error);
    }
  }, [deleted, state]);

  const handleTableChange = async (pagination, filters, sorter, ordering) => {
    const orderBy = GetSortingEnumValue(sorter.columnKey, sorter.order);
    setState({
      ...state,
      page: pagination.current,
      pagesize: pagination.pageSize,
      ordering: orderBy,
    });
  };

  const GetSortingEnumValue = (sortField, sortOrder) => {
    let sortingEnumKey = "id";

    if (sortField === "name" && sortOrder === "ascend") {
      sortingEnumKey = "name";
    }
    if (sortField === "name" && sortOrder === "descend") {
      sortingEnumKey = "-name";
    }

    if (sortField === "concierge_name" && sortOrder === "ascend") {
      sortingEnumKey = "concierge__concierge_type";
    }
    if (sortField === "concierge_name" && sortOrder === "descend") {
      sortingEnumKey = "-concierge__concierge_type";
    }

    if (sortField === "company_name" && sortOrder === "ascend") {
      sortingEnumKey = "company__company_name";
    }
    if (sortField === "company_name" && sortOrder === "descend") {
      sortingEnumKey = "-company__company_name";
    }

    if (sortField === "facility_location" && sortOrder === "ascend") {
      sortingEnumKey = "company__city";
    }
    if (sortField === "facility_location" && sortOrder === "descend") {
      sortingEnumKey = "-company__city";
    }

    if (sortField === "country" && sortOrder === "ascend") {
      sortingEnumKey = "company__country__name";
    }
    if (sortField === "country" && sortOrder === "descend") {
      sortingEnumKey = "-company__country__name";
    }

    if (sortField === "email" && sortOrder === "ascend") {
      sortingEnumKey = "email";
    }
    if (sortField === "email" && sortOrder === "descend") {
      sortingEnumKey = "-email";
    }

    if (sortField === "contact_no" && sortOrder === "ascend") {
      sortingEnumKey = "contact_no";
    }
    if (sortField === "contact_no" && sortOrder === "descend") {
      sortingEnumKey = "-contact_no";
    }

    if (sortField === "duration" && sortOrder === "ascend") {
      sortingEnumKey = "concierge__duration";
    }
    if (sortField === "duration" && sortOrder === "descend") {
      sortingEnumKey = "-concierge__duration";
    }

    if (sortField === "date_of_request" && sortOrder === "ascend") {
      sortingEnumKey = "date_of_request";
    }
    if (sortField === "date_of_request" && sortOrder === "descend") {
      sortingEnumKey = "-date_of_request";
    }
    return sortingEnumKey;
  };

  const searchCallbackDelayed = (event) => {
    const { value } = event.target;
    debounceLoadData(value, onSearch);
  };

  const onSearch = (value) => {
    setState({
      ...state,
      search: value,
    });
  };

  const debounceLoadData = debounce((value, onSearch) => {
    onSearch(value);
  }, 1000);
  const confirmDelete = async (id) => {
    try {
      setLoading(true);
      await deleteRequest("concierge/request-for-price-delete/" + id).then(
        (response) => {
          SuccessNotificationMsg(t("msg_requestDeleted"));
          setDeleted(!deleted);
        }
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const func1 = () => {
    navigate("/requested-concierge-service");
  };
  const func2 = () => {
    setDeleted(!deleted);
  };

  const handleStatus = async (request_id, status) => {
    setLoading(true);
    confirmAlert({
      title: t("confirmAlert_title"),
      message: t("confirmAlert_message"),
      buttons: [
        {
          label: t("confirmAlert_ok"),
          onClick: () => updateStatus(request_id, status),
        },
        {
          label: t("confirmAlert_cancel"),
          onClick: () => {
            func1();
            func2();
            setLoading(false);
          },
        },
      ],
    });
  };

  const updateStatus = async (id, value) => {
    await patchRequest(`concierge/request-for-price-update/${id}`, {
      status: value,
    })
      .then((response) => {
        setDeleted(!deleted);
        SuccessNotificationMsg(t("msg_requestUpdated"));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Concierge Type",
      dataIndex: "concierge_name",
      key: "concierge_name",
      sorter: true,
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      sorter: true,
    },
    {
      title: "Company Location",
      dataIndex: "facility_location",
      key: "facility_location",
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Contact No",
      dataIndex: "contact_no",
      key: "contact_no",
      sorter: true,
    },
    {
      title: "Date Of Request",
      dataIndex: "date_of_request",
      key: "date_of_request",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (e, record) => (
        <Select
          className="gridselect"
          onChange={(e) => handleStatus(record.id, e)}
          value={e ? e : record.status}
          defaultValue={e}
        >
          <Option value="responded">
            <div className="active">Responded</div>
          </Option>
          <Option value="pending">
            <div className="pending">Pending</div>
          </Option>
        </Select>
      ),
    },
    {
      title: "Action",
      render: (e, record) => (
        <Space size={[12, 16]}>
          <Space size="middle">
            <a href={`mailto:${record.email}`}>
              <SendOutlined style={{ color: "black" }} />
            </a>
            <Popconfirm
              className="action"
              title={t("pop_title")}
              okText={t("pop_ok")}
              cancelText={t("pop_cancel")}
              onConfirm={() => confirmDelete(record.id)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="heading">
        <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 32 }} align="middle">
          <Col flex="auto" align="center">
            <div className="searchwrp">
              <Search placeholder={"search"} onChange={searchCallbackDelayed} />
            </div>
          </Col>
        </Row>
      </div>
      <Table
        className="table_grid"
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={requestedConcierge}
        scroll={{ x: 970 }}
        loading={loading}
        pagination={{
          defaultCurrent: state.page,
          defaultPageSize: state.pagesize,
          total: totalCount,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default RequestedConcierge;
