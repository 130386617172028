import { notification } from "antd";

export const NotificationMessage = (message, http_status_code, status) => {
  if (http_status_code === 200 || http_status_code === 201) {
    return notification.success({
      message: message,
      placement: "topRight",
      duration: 3.5,
    });
  } else if (http_status_code === 400 || http_status_code === 401) {
    return notification.error({
      message: message,
      placement: "topRight",
      duration: 3.5,
    });
  }
  // else {
  //     return notification.error({
  //         message: message,
  //         placement: "topRight",
  //         duration: 3.5,
  //     });
  // }
};

export const SuccessNotificationMsg = (message, placement) => {
  return notification.success({
    message: message,
    // description: desc,
    placement: placement ? placement : "topRight",
    duration: 3.5,
  });
};

export const ErrorNotificationMsg = (message, desc) => {
  return notification.error({
    message: message,
    description: desc,
    placement: "topRight",
    duration: 3.5,
  });
};

export const WarningNotificationMsg = (message) => {
  return notification.warning({
    message: message,
    placement: "topRight",
    duration: 3.5,
  });
};

export const ShowErrorMsg = (error) => {
  if (error?.response?.data) {
    for (let [key, value] of Object.entries(error.response.data)) {
      return notification.error({
        message: value,
        placement: "topRight",
        duration: 3.5,
        key: key,
      });
    }
  }
};
