import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Input, Space, Divider } from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import ButtonField from "../../../../../common/htmlComponents/ButtonField";
import {
  addReactionCapability,
  getAllReactionCapability,
} from "./redux/AddFacilityAPISlice";
import { SuccessNotificationMsg } from "../../../../../utils/Notifications";
import {
  getProgressPrec,
  reloadAfterUpdate,
} from "../../../../profile/progressSlice";
import { authUserData } from "../../../../../utils/Helper";

const ReactionCapability = (props) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const dispatch = useDispatch();
  const group = authUserData()?.group;
  const btnLoading = useSelector((state) => state.facilityAPI.btnLoading);
  const type = useSelector((state) => state.facilityAPI.type);

  const initialState = {
    reaction_capability: [
      {
        reaction_capability: "",
      },
    ],
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (type === "add_reaction_capability/fulfilled") {
      SuccessNotificationMsg(t("msg_add_reaction_capability"));
      dispatch(getAllReactionCapability());
    }

    formRef.current.setFieldsValue({
      reaction_capability: [
        {
          reaction_capability: "",
        },
      ],
    });

    addCapability();
  }, [type, t, dispatch]);

  const addCapability = () => {
    let newCapabilityArr = [
      ...state.reaction_capability,
      {
        reaction_capability: "",
      },
    ];
    setState({
      ...state,
      reaction_capability: newCapabilityArr ? newCapabilityArr : "",
    });
    setWeightSizeFieldValue(newCapabilityArr);
  };

  const handleCloneInputChange = (field, e, index) => {
    let newCapabilityArr = state.reaction_capability;
    newCapabilityArr[index][field] = e.target.value ? e.target.value : "";
    setState({ ...state, reaction_capability: newCapabilityArr });
  };

  const removeCapability = async (lastIndex) => {
    let newCapabilityArr = state.reaction_capability.filter(
      (item, index) => index !== lastIndex
    );
    setState({ ...state, reaction_capability: newCapabilityArr });
    setWeightSizeFieldValue(newCapabilityArr);
  };

  const setWeightSizeFieldValue = (data) => {
    let stateArr = [];
    let fieldObject = {};
    data.map((objectValue, objectKey) => {
      stateArr.push(objectValue);
      fieldObject["reaction_capability" + objectKey] =
        objectValue.reaction_capability;
      return null;
    });
    formRef.current.setFieldsValue(fieldObject);
  };

  const handleSubmit = () => {
    dispatch(addReactionCapability({ ...state }));
    dispatch(reloadAfterUpdate(true));
    if (group === "Company Admin") {
      dispatch(getProgressPrec());
    }
    setState(initialState);
    props.handleCancel(initialState);
  };

  return (
    <div>
      <Form ref={formRef} onFinish={handleSubmit} autoComplete="off">
        {state.reaction_capability.map((x, index) => {
          return (
            <>
              <Row key={index} className="customcol" gutter={[15]}>
                <Col xs={24} sm={20}>
                  <Form.Item name={`reaction_capability` + index}>
                    <Input
                      placeholder={t("pl_decomposition_reaction")}
                      onChange={(value) =>
                        handleCloneInputChange(
                          "reaction_capability",
                          value,
                          index
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                  <div className="addwrp">
                    <Space>
                      {index !== 0 ? (
                        <CloseCircleOutlined
                          className="add"
                          onClick={() => removeCapability(index)}
                        />
                      ) : (
                        ""
                      )}
                      {index === 0 && (
                        <PlusCircleOutlined
                          className="add"
                          onClick={(e) => addCapability(e)}
                        />
                      )}
                    </Space>
                  </div>
                </Col>
              </Row>
            </>
          );
        })}
        <Divider />
        <Row gutter={[15]} className="bottomButtons">
          <Col xs={24} align="end" className="text-right">
            <ButtonField
              type="primary"
              htmlType="submit"
              loading={btnLoading}
              label={t("submit")}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

ReactionCapability.propTypes = {
  handleCancel: PropTypes.func, // Add this line
};

export default ReactionCapability;
