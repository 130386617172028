import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm, Spin, Upload, Row, Col } from "antd";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { deleteRequest, postRequest } from "../../../../../axios";
import profileImg from "../../../../../images/profile-noimg.png";
import {
  getFormulationPhotogalleryById,
  getAPIPhotogalleryById,
} from "./PhotoGallerySlice";
import ButtonField from "../../../../../common/htmlComponents/ButtonField";
import { loadingIcon } from "../../../../../utils/Helper";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../../../../../utils/Notifications";

const PhotoGallery = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const APIlist = useSelector((state) => state.photoGallery.photoGallery);
  const photoGallery = APIlist.results;
  const formulationList = useSelector(
    (state) => state.photoGallery.formulationPhotoGallery
  );
  const formulationPhotoGallery = formulationList.results;

  const [loading, setLoading] = useState(false);

  let id, list;
  if (props.facility_type_id) {
    id = props.facility_type_id;
    list = photoGallery;
  }
  if (props.formulationMaster) {
    id = props.formulationMaster;
    list = formulationPhotoGallery;
  }

  const handalupdate = async (file) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("gallery_image", file.file);
      let apiResponse;
      if (props.facility_type_id) {
        apiResponse = await postRequest(
          "facility/list-create-api-photo-gallery/" + id,
          formData
        );
      }
      if (props.formulationMaster) {
        apiResponse = await postRequest(
          "facility/list-create-formulation-photo-gallery/" + id,
          formData
        );
      }
      if (apiResponse.data.success) {
        setLoading(false);
        SuccessNotificationMsg(t("msg_uploadSuccess"));
        if (props.facility_type_id) {
          dispatch(getAPIPhotogalleryById(props.facility_type_id));
        }
        if (props.formulationMaster) {
          dispatch(getFormulationPhotogalleryById(props.formulationMaster));
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      ErrorNotificationMsg(t("err_uploadSuccess"));
    }
  };

  const onRemove = async (id) => {
    const apiResponse = await deleteRequest(
      `facility/update-delete-api-photo-gallery/` + id
    );
    if (apiResponse.data.success) {
      SuccessNotificationMsg(t("msg_deleteImageSuccess"));
      if (props.facility_type_id) {
        dispatch(getAPIPhotogalleryById(props.facility_type_id));
      }
      if (props.formulationMaster) {
        dispatch(getFormulationPhotogalleryById(props.formulationMaster));
      }
    }
  };

  return (
    <div className="photogallery">
      <h3>{t("photoGalleryCondition")}</h3>
      <Row gutter={[15]}>
        {!!list &&
          list.map((document, index) => {
            return (
              <>
                <React.Fragment key={index}>
                  <Col xs={12} sm={6} md={4}>
                    <div className="picturewrap">
                      <img
                        src={
                          document.gallery_image
                            ? document.gallery_image
                            : profileImg
                        }
                        alt="outlet gallery"
                      />
                      <div className="btnwrp">
                        <Popconfirm
                          className="action"
                          title={t("pop_photoTitle")}
                          okText={t("pop_ok")}
                          cancelText={t("pop_cancel")}
                          onConfirm={() => onRemove(document.id)}
                          placement="left"
                        >
                          <ButtonField
                            type="link"
                            htmlType="button"
                            size="small"
                            icon={<DeleteOutlined />}
                          />
                        </Popconfirm>
                      </div>
                    </div>
                  </Col>
                </React.Fragment>
              </>
            );
          })}
        <Col xs={12} sm={6} md={4}>
          <Upload
            onRemove={onRemove}
            accept=".jpg,.png"
            // className="image-upload-grid"
            className={
              !!list && list.length < 5
                ? "image-upload-grid"
                : "image-upload-grid-last"
            }
            customRequest={handalupdate}
            showUploadList={false}
          >
            {!!list && list.length < 5 && <PlusCircleOutlined />}

            <Spin spinning={loading} indicator={loadingIcon} />
          </Upload>
        </Col>
      </Row>
    </div>
  );
};

PhotoGallery.propTypes = {
  facility_type_id: PropTypes.number, // Example: Assuming it's a number
  formulationMaster: PropTypes.number, // Example: Assuming it's a string
};

export default PhotoGallery;
