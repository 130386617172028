import React from "react";
import PropTypes from "prop-types";
import { Input, Form } from "antd";
import { Trans, useTranslation } from "react-i18next";

const { TextArea } = Input;

const TextAreaField = (props) => {
  const { t } = useTranslation();

  let fieldRequired = false;

  if (props.rules) {
    fieldRequired = true;
  }
  return (
    <>
      <Form.Item
        name={props.name}
        label={props.label}
        rules={[
          {
            required: fieldRequired,
            message: (
              <Trans>
                {props.rules ? props.rules : props.nonRequiredRules}
              </Trans>
            ),
          },
          { min: 2, message: <Trans>{t("minlength_msg")}</Trans> },
          {
            max: props.max,
            message: (
              <>
                <Trans>{t("maxlength_msg")}</Trans> {props.max}
                {t("characters")}
              </>
            ),
          },
        ]}
      >
        <TextArea
          rows={props.rows}
          name={props.name}
          placeholder={props.placeholder}
          onChange={props.handleInputChange}
          className={props.className}
          value={props.value ? props.value : null}
          readOnly={props.readOnly}
        />
      </Form.Item>
    </>
  );
};

TextAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.string,
  nonRequiredRules: PropTypes.string,
  max: PropTypes.number,
  prefix: PropTypes.node,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  handleInputChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
};

export default TextAreaField;
