import React, { useState, useEffect } from "react";
import Logo from "../images/logo.png";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Spin, Space, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import "../styles/Main.scss";
import "antd/dist/antd.css";
import { getRequest, postRequest } from "../axios";
import { ErrorNotificationMsg } from "../utils/Notifications";
import ButtonField from "../common/htmlComponents/ButtonField";
import { useNavigate } from "react-router-dom";
import RequestForPriceModal from "./RequestForPriceModal";
import { authUserData } from "../utils/Helper";
const RequestForPriceList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const CountryName = authUserData()?.country_name;
  const [loading, setLoading] = useState(false);

  const [sellGloballyList, setSellGloballyList] = useState([]);
  const [businessLoungeList, setBusinessLoungeList] = useState([]);
  const [bespokeList, setBespokeList] = useState([]);

  const [isModalOpenc, setIsModalOpenc] = useState(false);
  const [to_conciergeId, setto_conciergeId] = useState("");
  const [to_conciergeName, setto_conciergeName] = useState("");

  useEffect(() => {
    const getConciergeList = async () => {
      try {
        setLoading(true);
        await getRequest(
          // "concierge/list/?conciarge_type=REQUEST FOR PRICE"
          `concierge/list/?country=${CountryName}&pagesize=100`
        ).then((apiResponse) => {
          if (apiResponse.status) {
            const selGloballyArray = apiResponse.data.results.filter(function (
              el
            ) {
              return el.package_type === "SELL GLOBALLY";
            });
            const BusinessLoungeArray = apiResponse.data.results.filter(
              function (el) {
                return el.package_type === "BUSINESS LOUNGE";
              }
            );
            const bespokeArray = apiResponse.data.results.filter(function (el) {
              return el.package_type === "BESPOKE SERVICE";
            });
            setSellGloballyList(selGloballyArray);
            setBusinessLoungeList(BusinessLoungeArray);
            setBespokeList(bespokeArray);
            setLoading(false);
          } else {
            ErrorNotificationMsg(t("err_getConciergeList"));
          }
        });
      } catch (error) {
        setLoading(false);
        ErrorNotificationMsg(error);
      }
    };
    getConciergeList();
  }, [t]);
  const handleLogOut = () => {
    localStorage.clear();
    // sessionStorage.clear();
    sessionStorage.removeItem("isLoggedIn");

    navigate("/");
    window.location.reload(true);
  };
  const handleCancelc = () => {
    setIsModalOpenc(false);
  };

  const showModalc = async (to_conciergeId, to_conciergeName) => {
    setto_conciergeId(to_conciergeId);
    setto_conciergeName(to_conciergeName);
    setIsModalOpenc(true);
  };
  const Checkout = async (id) => {
    try {
      await postRequest("subscription/create/", {
        company: authUserData()?.company_id,
        currency: "INR",
        concierge: id,
      }).then((apiResponse) => {
        if (apiResponse.status) {
          window.open(apiResponse.data.data.check_out_url, "_blank");
        } else {
          ErrorNotificationMsg(t("err_getConciergeList"));
        }
      });
    } catch (error) {
      ErrorNotificationMsg(error);
    }
  };
  return (
    <>
      <style>
        {`
          body {
            background-color: ${loading ? "#022f46" : "initial"};
          }`}
      </style>

      {loading && (
        <div className="spinner-container">
          <Spin spinning={loading} size="Large" />
        </div>
      )}
      {!loading && (
        <>
          <Spin spinning={loading}>
            <div className="authPage">
              <div className="logorow headerlogin">
                {" "}
                <a href="/login">
                  {" "}
                  <img src={Logo} alt=""></img>
                </a>
                <div className="loginhead">
                  <label>{authUserData()?.company_name}</label>
                  <ButtonField
                    type="primary"
                    // onClick={() => navigate("/login")}
                    onClick={handleLogOut}
                    label={t("Log out")}
                  />
                </div>
              </div>
              <div className="container">
                <div className="concierge conciergeService">
                  {/* <div className="normaltxt">{t("concierge_service_lbl1")}</div> */}

                  {/* <div className="sectionheading">{t("sell_globally_service")}</div> */}
                  <div className="conciergeServiceTitle">
                    Concierge Services
                  </div>

                  <Row gutter={[15]} className="conciergerow">
                    {!!sellGloballyList &&
                      sellGloballyList.map((document) => {
                        return (
                          <>
                            <Col xs={24} sm={12} md={8}>
                              <div className="conciergebox">
                                <div className="conciergeshadow"></div>
                                <div className="planbox">
                                  <div className="plantitle">
                                    <h3>{document.name}</h3>
                                    <h6>Valid for: {document.duration} Days</h6>
                                    {document.concierge_type === "SET PRICE" ? (
                                      document?.discount !== 0 ? (
                                        <>
                                          <Space size={[16, 16]}>
                                            <h6>
                                              Actual Price:{" "}
                                              <span
                                                style={{
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                {" "}
                                                {CountryName === "India" ? (
                                                  <FontAwesomeIcon
                                                    icon={faIndianRupeeSign}
                                                  />
                                                ) : (
                                                  <FontAwesomeIcon
                                                    icon={faDollarSign}
                                                  />
                                                )}{" "}
                                                {document.actual_price}
                                              </span>{" "}
                                            </h6>
                                            <h6>
                                              Discount: {document.discount} %{" "}
                                            </h6>
                                            <h6>
                                              Sell Price:{" "}
                                              {CountryName === "India" ? (
                                                <FontAwesomeIcon
                                                  icon={faIndianRupeeSign}
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  icon={faDollarSign}
                                                />
                                              )}{" "}
                                              {document.sell_price}{" "}
                                            </h6>{" "}
                                          </Space>
                                        </>
                                      ) : (
                                        <>
                                          <Space size={[16, 16]}>
                                            <h6>
                                              Price:{" "}
                                              {CountryName === "India" ? (
                                                <FontAwesomeIcon
                                                  icon={faIndianRupeeSign}
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  icon={faDollarSign}
                                                />
                                              )}{" "}
                                              {document.actual_price}{" "}
                                            </h6>
                                          </Space>
                                        </>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div
                                    className="plancont"
                                    dangerouslySetInnerHTML={{
                                      __html: document.description,
                                    }}
                                  />
                                  <div className="btnwrp">
                                    {document.concierge_type ===
                                    "REQUEST FOR PRICE" ? (
                                      <Button
                                        type="link"
                                        onClick={() =>
                                          showModalc(document.id, document.name)
                                        }
                                      >
                                        {t("request_for_price")}
                                      </Button>
                                    ) : (
                                      <Button
                                        type="link"
                                        onClick={() => Checkout(document.id)}
                                        // loading={loadings[document.id]}
                                      >
                                        {t("buyNow")}
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </>
                        );
                      })}
                  </Row>

                  <div className="sectionheading">
                    {" "}
                    {t("business_lounge_service1")}
                  </div>
                  <Row gutter={[15]} className="conciergerow">
                    {!!businessLoungeList &&
                      businessLoungeList.map((document) => {
                        return (
                          <>
                            <Col xs={24} sm={12} md={8}>
                              <div className="conciergebox">
                                <div className="conciergeshadow"></div>
                                <div className="planbox">
                                  <div className="plantitle">
                                    {/* <h3>{document.name}  {document.duration? "/ " + (document.duration / 365) + " Year"  : ""}</h3> */}

                                    <h3>{document.name} </h3>
                                    <h6>Valid for: {document.duration} Days</h6>
                                    {document.concierge_type === "SET PRICE" ? (
                                      document.discount !== 0 ? (
                                        <>
                                          <Space size={[16, 16]}>
                                            <h6>
                                              Actual Price:{" "}
                                              <span
                                                style={{
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                {CountryName === "India" ? (
                                                  <FontAwesomeIcon
                                                    icon={faIndianRupeeSign}
                                                  />
                                                ) : (
                                                  <FontAwesomeIcon
                                                    icon={faDollarSign}
                                                  />
                                                )}{" "}
                                                {document.actual_price}
                                              </span>{" "}
                                            </h6>
                                            <h6>
                                              Discount: {document.discount} %{" "}
                                            </h6>
                                            <h6>
                                              Sell Price:{" "}
                                              {CountryName === "India" ? (
                                                <FontAwesomeIcon
                                                  icon={faIndianRupeeSign}
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  icon={faDollarSign}
                                                />
                                              )}{" "}
                                              {document.sell_price}{" "}
                                            </h6>{" "}
                                          </Space>
                                        </>
                                      ) : (
                                        <>
                                          <Space size={[16, 16]}>
                                            <h6>
                                              Price:{" "}
                                              {CountryName === "India" ? (
                                                <FontAwesomeIcon
                                                  icon={faIndianRupeeSign}
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  icon={faDollarSign}
                                                />
                                              )}{" "}
                                              {document.actual_price}{" "}
                                            </h6>
                                          </Space>
                                        </>
                                      )
                                    ) : (
                                      ""
                                    )}

                                    {/* {document.concierge_type === "SET PRICE" ? (
                                  <>
                                    <Space size={[16, 16]}>
                                      <h6>
                                        Actual Price: {document.actual_price}{" "}
                                        {CountryName === "India" ? (
                                          <FontAwesomeIcon
                                            icon={faIndianRupeeSign}
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon={faDollarSign}
                                          />
                                        )}
                                      </h6>
                                      <h6>Discount: {document.discount} % </h6>
                                      <h6>
                                        Sell Price: {document.sell_price}{" "}
                                        {CountryName === "India" ? (
                                          <FontAwesomeIcon
                                            icon={faIndianRupeeSign}
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon={faDollarSign}
                                          />
                                        )}
                                      </h6>{" "}
                                    </Space>
                                  </>
                                ) : (
                                  ""
                                )} */}
                                  </div>
                                  <div
                                    className="plancont"
                                    dangerouslySetInnerHTML={{
                                      __html: document.description,
                                    }}
                                  />
                                  <div className="btnwrp">
                                    {document.concierge_type ===
                                    "REQUEST FOR PRICE" ? (
                                      <Button
                                        type="link"
                                        onClick={() =>
                                          showModalc(document.id, document.name)
                                        }
                                      >
                                        {t("request_for_price")}
                                      </Button>
                                    ) : (
                                      <Button
                                        type="link"
                                        onClick={() => Checkout(document.id)}
                                        // loading={loadings[document.id]}
                                      >
                                        {t("buyNow")}
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </>
                        );
                      })}
                  </Row>

                  <div className="sectionheading">{t("bespoke_service")}</div>
                  <Row gutter={[15]} className="conciergerow">
                    {!!bespokeList &&
                      bespokeList.map((document) => {
                        return (
                          <>
                            <Col xs={24} sm={12} md={8}>
                              <div className="conciergebox">
                                <div className="conciergeshadow"></div>
                                <div className="planbox">
                                  <div className="plantitle">
                                    <h3>{document.name}</h3>
                                    {/* <h6>Valid for: {document.duration} Days</h6> */}
                                    {document.concierge_type === "SET PRICE" ? (
                                      document.discount !== 0 ? (
                                        <>
                                          <Space size={[16, 16]}>
                                            <h6>
                                              Actual Price:{" "}
                                              <span
                                                style={{
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                {" "}
                                                {CountryName === "India" ? (
                                                  <FontAwesomeIcon
                                                    icon={faIndianRupeeSign}
                                                  />
                                                ) : (
                                                  <FontAwesomeIcon
                                                    icon={faDollarSign}
                                                  />
                                                )}
                                                {document.actual_price}
                                              </span>{" "}
                                            </h6>
                                            <h6>
                                              Discount: {document.discount} %{" "}
                                            </h6>
                                            <h6>
                                              Sell Price:{" "}
                                              {CountryName === "India" ? (
                                                <FontAwesomeIcon
                                                  icon={faIndianRupeeSign}
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  icon={faDollarSign}
                                                />
                                              )}{" "}
                                              {document.sell_price}{" "}
                                            </h6>{" "}
                                          </Space>
                                        </>
                                      ) : (
                                        <>
                                          <Space size={[16, 16]}>
                                            <h6>
                                              Price:{" "}
                                              {CountryName === "India" ? (
                                                <FontAwesomeIcon
                                                  icon={faIndianRupeeSign}
                                                />
                                              ) : (
                                                <FontAwesomeIcon
                                                  icon={faDollarSign}
                                                />
                                              )}{" "}
                                              {document.actual_price}{" "}
                                            </h6>
                                          </Space>
                                        </>
                                      )
                                    ) : (
                                      ""
                                    )}
                                    {/* {document.concierge_type === "SET PRICE" ? (
                                  <>
                                    <Space size={[16, 16]}>
                                      <h6>
                                        Actual Price: {document.actual_price}{" "}
                                        {CountryName === "India" ? (
                                          <FontAwesomeIcon
                                            icon={faIndianRupeeSign}
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon={faDollarSign}
                                          />
                                        )}
                                      </h6>
                                      <h6>Discount: {document.discount} % </h6>
                                      <h6>
                                        Sell Price: {document.sell_price}{" "}
                                        {CountryName === "India" ? (
                                          <FontAwesomeIcon
                                            icon={faIndianRupeeSign}
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon={faDollarSign}
                                          />
                                        )}
                                      </h6>{" "}
                                    </Space>
                                  </>
                                ) : (
                                  ""
                                )} */}
                                  </div>
                                  <div
                                    className="plancont"
                                    dangerouslySetInnerHTML={{
                                      __html: document.description,
                                    }}
                                  />
                                  <div className="btnwrp">
                                    {document.concierge_type ===
                                    "REQUEST FOR PRICE" ? (
                                      <Button
                                        type="link"
                                        onClick={() =>
                                          showModalc(document.id, document.name)
                                        }
                                      >
                                        {t("request_for_price")}
                                      </Button>
                                    ) : (
                                      <Button
                                        type="link"
                                        onClick={() => Checkout(document.id)}
                                        // loading={loadings[document.id]}
                                      >
                                        {t("buyNow")}
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Modal
                              title={t("request_for_price")}
                              open={isModalOpenc}
                              footer={null}
                              onCancel={handleCancelc}
                              width={980}
                              className="APImodel"
                            >
                              <RequestForPriceModal
                                id={to_conciergeId}
                                subscription_name={to_conciergeName}
                                setIsModalOpenc={setIsModalOpenc}
                              />
                            </Modal>
                          </>
                        );
                      })}
                  </Row>
                </div>
              </div>
            </div>
          </Spin>
          <Modal
            title={t("request_for_price")}
            open={isModalOpenc}
            footer={null}
            onCancel={handleCancelc}
            width={980}
            className="APImodel"
          >
            <RequestForPriceModal
              id={to_conciergeId}
              subscription_name={to_conciergeName}
              setIsModalOpenc={setIsModalOpenc}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default RequestForPriceList;
