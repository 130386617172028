import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../../../../axios";

export const getAPIPhotogalleryById = createAsyncThunk("get_photoGallery_by_id", async (id) => {
    const response = await getRequest("facility/list-create-api-photo-gallery/" + id);
    return response.data;
});

export const getFormulationPhotogalleryById = createAsyncThunk("get_formulationPhotoGallery_by_id", async (id) => {
    const response = await getRequest("facility/list-create-formulation-photo-gallery/" + id);
    return response.data;
});

export const getrefreshPotogallery = createAsyncThunk("refresh_photoGallery", async (refresh) => {
    return refresh;
});

const initialState = {
    apiLoading: false,
    btnnLoading: false,
    error: "",
    type: "",
    photoGallery: [],
    photoGalleryAPI_count: null,
    formulationPhotoGallery: [],
    photoGalleryFormulation_count: null
}

const photoGallery = createSlice({
    name: "photoGallery",
    initialState,
    reducers: {},
    extraReducers: {

        // get api photoGallery by id

        [getAPIPhotogalleryById.pending]: (state, action) => {
            state.apiLoading = true
        },

        [getAPIPhotogalleryById.fulfilled]: (state, action) => {
            return { ...state, apiLoading: false, photoGallery: action.payload, photoGalleryAPI_count: action.payload.count };
        },

        [getAPIPhotogalleryById.rejected]: (state, action) => {
            state.apiLoading = false
            state.error = action.payload;
        },

        // get formulation photoGallery by id

        [getFormulationPhotogalleryById.pending]: (state, action) => {
            state.apiLoading = true
        },
        [getFormulationPhotogalleryById.fulfilled]: (state, action) => {
            return { ...state, apiLoading: false, formulationPhotoGallery: action.payload, photoGalleryFormulation_count: action.payload.count };
        },
        [getFormulationPhotogalleryById.rejected]: (state, action) => {
            state.apiLoading = false
            state.error = action.payload;
        },

        [getrefreshPotogallery.fulfilled]: (state) => {
            state.photoGallery = []
            state.photoGalleryAPI_count = null
            state.formulationPhotoGallery = []
            state.photoGalleryFormulation_count = null
        }

    }
});

export default photoGallery.reducer
