import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";

const ButtonField = (props) => {
  return (
    <>
      <Button
        cursor="pointer"
        htmlType={props.htmlType}
        type={props.type}
        size={props.size}
        icon={props.icon}
        onClick={props.onClick}
        className={props.className}
        disabled={props.disabled}
        loading={props.loading}
      >
        {props.label}
      </Button>
    </>
  );
};

ButtonField.propTypes = {
  htmlType: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  label: PropTypes.node,
};

export default ButtonField;
