import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest, postRequest, patchRequest } from "../../axios";
import { NotificationMessage } from "../../utils/Notifications";

// Search Company

export const getAllUsers = createAsyncThunk("get_all_users", async () => {
        const allUsers = await getRequest("company")
        return allUsers.data
});

// Request for credential

export const request_forCredentials = createAsyncThunk("request_forCredentials", async (state) => {
        const response = await postRequest("company/request-credentials-form/", state);
        return response.data
});


//Register Company

export const signUpUser = createAsyncThunk("signUpUser", async (state) => {
        const response = await postRequest("company/register/", state);
        return response.data
});

// Verify Register OTP

export const verifyRegisterOtp = createAsyncThunk("verify_registerotp", async (state) => {
    const response = await postRequest("user/verify-otp/", state);
    return response.data
});

// Verify Request Credential OTP

export const verifyRequestCredentialOtp = createAsyncThunk("verify_request_credentialotp", async (state) => {
    const response = await postRequest("company/request-credentail-verify/", state);
    return response.data
});


// Verify  OTP

export const verifyEmail = createAsyncThunk("verifyemail", async (state) => {
    const response = await postRequest("company/verify-otp/", state);
    return response.data
});

// Login User

export const loginUser = createAsyncThunk("loginuser", async (state) => {
    const response = await postRequest("gettoken/", state);

    localStorage.setItem("auth_token", response.data?.data?.access);
    localStorage.setItem(
        "auth_data",
        JSON.stringify(response.data?.data?.userData)
    );
});

//Verify Account- send otp

export const verifyAccount = createAsyncThunk("verifyAccount", async (state) => {
    const response = await patchRequest("company/send-otp/", state);
    return response.data
});

//resend otp

export const resendOTP = createAsyncThunk("resendOTP", async (state) => {
    try {
        const response = await patchRequest("company/resend-otp/", state);
        NotificationMessage(response.data.message, response.status)
    } catch (err) {
        console.log(err)
    }

});


// Forgot password

export const forgotPassword = createAsyncThunk("forgotPassword", async (state) => {
    const response = await postRequest("user/forget-password/", state);
    return response.data
});

// Reset password

export const resetPassword = createAsyncThunk("resetPassword", async (state) => {
    const response = await postRequest("user/reset-password/" + state.resetToken, state);
    return response.data
});


const initialState = {
    token: "",
    loading: false,
    btnLoading: false,
    resendLoading: false,
    requestStatus: "",
    success: false,
    error: "",
    type: "",
    userList: []
}
const users = createSlice({
    name: "users",
    initialState,
    reducers: {},

    extraReducers: {

        //search company

        [getAllUsers.pending]: (state) => {
            state.loading = true;
        },
        [getAllUsers.fulfilled]: (state, action) => {
            return { ...state, loading:false, userList: action.payload };
        },
        [getAllUsers.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        //Request for Credentials

        [request_forCredentials.pending]: (state) => {
            state.loading = true;
            state.btnLoading = true;
        },
        [request_forCredentials.fulfilled]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.success = true;
            state.type = action.type;
            state.status = action.payload.status
        },
        [request_forCredentials.rejected]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.error = action.payload;
        },

        //register

        [signUpUser.pending]: (state) => {
            state.loading = true;
            state.btnLoading = true;
        },
        [signUpUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.success = true; // registration successfull
            state.type = action.type;
        },
        [signUpUser.rejected]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.error = action.payload;
        },

        //login

        [loginUser.pending]: (state) => {
            state.loading = true;
            state.btnLoading = true;
        },
        [loginUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.success = true // login successfull
            state.btnLoading = false;
            state.type = action.type;

        },
        [loginUser.rejected]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.error = action.payload;
        },

        //verify email / account verification 

        [verifyEmail.pending]: (state) => {
            state.loading = true;
            state.btnLoading = true;
        },
        [verifyEmail.fulfilled]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.success = true;
            state.type = action.type;
        },
        [verifyEmail.rejected]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.error = action.payload;
        },

        // verifyRegisterOtp / register company signup

        [verifyRegisterOtp.pending]: (state) => {
            state.loading = true;
            state.btnLoading = true;
        },
        [verifyRegisterOtp.fulfilled]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.type = action.type;
            state.success = true;
        },
        [verifyRegisterOtp.rejected]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.error = action.payload;
        },

        // verifyRequestCredentialOtp / register company signup

        [verifyRequestCredentialOtp.pending]: (state) => {
            state.btnLoading = true;
            state.loading = true;
        },
        [verifyRequestCredentialOtp.fulfilled]: (state, action) => {
            state.btnLoading = false;
            state.loading = false;
            state.type = action.type;
            state.success = true;
        },
        [verifyRequestCredentialOtp.rejected]: (state, action) => {
            state.btnLoading = false;
            state.loading = false;
            state.error = action.payload;
        },


        //verify account

        [verifyAccount.pending]: (state) => {
            state.loading = true;
            state.btnLoading = true;
        },
        [verifyAccount.fulfilled]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.success = true;
            state.type = action.type;
        },
        [verifyAccount.rejected]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.error = action.payload;
            state.type = action.type;
        },

        //resend OTP

        [resendOTP.pending]: (state) => {
            state.loading = true;
            state.resendLoading = true;
            state.resendLoading = true;
        },
        [resendOTP.fulfilled]: (state, action) => {
            state.loading = false;
            state.resendLoading = false;
            state.success = true;
            state.type = action.type;
        },
        [resendOTP.rejected]: (state, action) => {
            state.loading = false;
            state.resendLoading = false;
            state.error = action.payload;
        },

        //forgot password

        [forgotPassword.pending]: (state) => {
            state.loading = true;
            state.btnLoading = true;
        },
        [forgotPassword.fulfilled]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.success = true;
            state.type = action.type;
        },
        [forgotPassword.rejected]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.error = action.payload;
        },

        //reset password

        [resetPassword.pending]: (state) => {
            state.loading = true;
            state.btnLoading = true;
        },
        [resetPassword.fulfilled]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.success = true;
            state.type = action.type;
        },
        [resetPassword.rejected]: (state, action) => {
            state.loading = false;
            state.btnLoading = false;
            state.error = action.payload;
        },

    },
});

export default users.reducer