const APP_ENV = process.env.REACT_APP_APP_ENV; // DEV, TEST, PROD
const APP_EXTENSION = ".com";
const MAINTENANCE_MODE = false;

const config = {
  ENV: APP_ENV,
  IS_MAINTENANCE_MODE: MAINTENANCE_MODE,
  EXTENSION: APP_EXTENSION,

  APP_URL: process.env.REACT_APP_APP_URL,
  API_URL: process.env.REACT_APP_API_URL,
  BUCKET_URL: process.env.REACT_APP_BUCKET_URL,
  WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL,
};

export const packageConfigure = {
  APP_URL: config.APP_URL,
  APP_ENV: config.ENV,
  Extension: config.EXTENSION,
};

export default config;
