import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest } from "../../../axios";

export const facility_regulator = createAsyncThunk(
  "get_all_facility_regulator",
  async () => {
    const companyListResponse = await getRequest("certification/list/");

    return companyListResponse.data;
  }
);

const initialState = {
  error: "",
  apiLoading: false,
  btnLoading: false,
  facilityById: {},
  presentation_url: {},
  file_obj: [],
  all_facility_regulator: [],
  company_list: [],
};
const facility = createSlice({
  name: "facility",
  initialState,
  reducers: {
    addFacility(state, action) {
      state.facility = action.payload;
    },
    updateFacility(state, action) {
      state.facility = action.payload;
    },
  },
  extraReducers: {
    // facility_regulator

    [facility_regulator.pending]: (state) => {
      state.btnLoading = true;
    },
    [facility_regulator.fulfilled]: (state, action) => {
      state.btnLoading = false;
      state.all_facility_regulator = action.payload;
    },
    [facility_regulator.rejected]: (state, action) => {
      state.btnLoading = false;
      state.error = action.payload;
    },
  },
});

export const { addFacility, updateFacility } = facility.actions;

export default facility.reducer;
