import React from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import { Trans, useTranslation } from "react-i18next";

const NumberField = (props) => {
  const { t } = useTranslation();

  let fieldRequired = false;
  let pattern = "";

  if (props.rules) {
    fieldRequired = true;
  }

  if (props.pattern) {
    pattern = new RegExp(props.pattern);
  }

  return (
    <>
      <Form.Item
        name={props.name}
        label={props.label}
        rules={[
          {
            required: fieldRequired,
            pattern: pattern,
            message: (
              <Trans>
                {props.rules ? props.rules : props.nonRequiredRules}
              </Trans>
            ),
          },
          {
            max: props.max,
            message: (
              <>
                <Trans>{t("maxlength_msg")}</Trans> {props.max}
                {t("characters")}
              </>
            ),
          },
          {
            validator: props.validatePhoneNumber,
          },
        ]}
      >
        <Input
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          onChange={props.handleInputChange}
          className={props.className}
          value={props.value ? props.value : null}
        />
      </Form.Item>
    </>
  );
};

NumberField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.string,
  nonRequiredRules: PropTypes.string,
  pattern: PropTypes.string,
  max: PropTypes.number,
  type: PropTypes.string,
  prefix: PropTypes.node,
  placeholder: PropTypes.string,
  handleInputChange: PropTypes.func,
  validatePhoneNumber: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default NumberField;
