import React, { useRef, useState } from "react";
import { Row, Col, Form, Select } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { patchRequest } from "../axios";
import Logo from "../images/logo.png";
import InputField from "../common/htmlComponents/InputField";
import ButtonField from "../common/htmlComponents/ButtonField";
import {
  ErrorNotificationMsg,
  SuccessNotificationMsg,
} from "../utils/Notifications";

const Unsubscribe = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formRef = useRef();
  const { email } = useParams();
  const UserEmail = atob(email);

  const initialState = {
    unsubscribed_reason: null,
  };
  const [state, setState] = useState(initialState);
  const [btnLoading, setBtnloading] = useState(false);
  const [inputMessage, setInputMessage] = useState(false);

  const handleSelectChange = (message, value) => {
    if (value === "Other") {
      setInputMessage(true);
      setState({ ...state, unsubscribed_reason: null });
      formRef.current.setFieldsValue({ ...state, unsubscribed_reason: null });
    } else {
      setState({ ...state, unsubscribed_reason: value });
      setInputMessage(false);
    }
  };

  const handleInputChange = (e) => {
    setState({ ...state, unsubscribed_reason: e.target.value });
  };

  const handleNavigate = async () => {
    await new Promise((resolve) => setTimeout(resolve, 3000));
    navigate("/");
  };

  const handleSubmit = async () => {
    try {
      setBtnloading(true);
      await patchRequest(
        "user/unsubscribe-newsletter/" + UserEmail + "/",
        state
      ).then((apiResponse) => {
        if (apiResponse.status) {
          SuccessNotificationMsg(
            t("You are unsubscribed from our newsletter!!!")
          );
          setState(initialState);
          setBtnloading(false);
          setTimeout(handleNavigate, 1000);
        } else {
          setBtnloading(false);
        }
      });
    } catch (error) {
      setBtnloading(false);
      ErrorNotificationMsg(error);
    }
  };

  return (
    <>
      <div className="authPage">
        <div className="logorow headerlogin">
          {" "}
          <a href="/login">
            {" "}
            <img src={Logo} alt=""></img>
          </a>
          <div className="loginhead">
            <label>{t("clickUp")}</label>
            <ButtonField
              type="primary"
              onClick={() => navigate("/login")}
              label={t("login")}
            />
          </div>
        </div>
        <div className="container">
          <div className="login_wraper">
            <Row gutter={[15]}>
              <div className="content_wraper ">
                <div className="createticket">
                  <br />
                  <div className="ticketheading">
                    <div className="description">
                      <h3>{t("unsubscribe")}</h3>
                    </div>
                  </div>
                  <Form
                    onFinish={handleSubmit}
                    autoComplete="off"
                    ref={formRef}
                    initialValues={initialState}
                  >
                    <Row gutter={[15]}>
                      <Col xs={24} align="start">
                        <h3> {t("unsubsubscribe_title")}</h3>
                      </Col>

                      <Col xs={18}>
                        {inputMessage ? (
                          <InputField
                            label={t("other_reason")}
                            placeholder={t("pl_reason")}
                            rules={t("val_reason")}
                            name="unsubscribed_reason"
                            handleInputChange={handleInputChange}
                          />
                        ) : (
                          <div className="subadminselect small">
                            <Form.Item
                              name="unsubscribed_reason"
                              // label={t("reason")}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <Trans>{t("Please select reason")}</Trans>
                                  ),
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                showArrow
                                allowClear
                                placeholder={t("Please select reason")}
                                onChange={(value) =>
                                  handleSelectChange(
                                    "unsubscribed_reason",
                                    value
                                  )
                                }
                              >
                                <Select.Option value="Your Emails are not relevant to me">
                                  Your Emails are not relevant to me
                                </Select.Option>
                                <Select.Option value="Your Emails are too frequent">
                                  Your Emails are too frequent
                                </Select.Option>
                                <Select.Option value="I don’t remember signing up for this">
                                  I don’t remember signing up for this
                                </Select.Option>
                                <Select.Option value="I no longer want to receive the Emails">
                                  I no longer want to receive the Emails
                                </Select.Option>
                                <Select.Option value="The service required from us is completed ">
                                  The service required from us is completed
                                </Select.Option>
                                <Select.Option value="Other">
                                  Other
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row gutter={[15]} className="bottomButtons">
                      <Col xs={24} align="end" className="text-right">
                        <ButtonField
                          type="primary"
                          htmlType="submit"
                          loading={btnLoading}
                          label={t("unsubscribe")}
                        />
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Unsubscribe;
