import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const resetPaging = createAsyncThunk("resetPaging", async (value) => {
    return value;
  }
  );

  const initialState = {
    resetPage: false
  };

  const BLPage = createSlice({
    name: "BLPage",
    initialState,
    reducers: {
    },
    extraReducers: {
      [resetPaging.fulfilled]: (state, action) => {
        return { ...state, resetPage: action.payload };
      }  
    },
  });

  export default BLPage.reducer;