import React from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
import { Trans, useTranslation } from "react-i18next";

const InputField = (props) => {
  const { t } = useTranslation();

  let fieldRequired = false;
  let pattern = "";

  if (props.rules) {
    fieldRequired = true;
  }

  if (props.pattern) {
    //eslint-disable-next-line
    pattern = new RegExp(props.pattern);
  }

  return (
    <>
      <Form.Item
        name={props.name}
        type={props.type}
        label={props.label}
        rules={[
          {
            required: fieldRequired,
            pattern: pattern,
            message: (
              <Trans>
                {props.rules ? props.rules : props.nonRequiredRules}
              </Trans>
            ),
          },
          { min: 2, message: <Trans>{t("minlength_msg")}</Trans> },
          {
            max: props.max,
            message: (
              <>
                <Trans>{t("maxlength_msg")}</Trans> {props.max}
                {t("characters")}
              </>
            ),
          },
        ]}
      >
        <Input
          name={props.name}
          placeholder={props.placeholder}
          onChange={props.handleInputChange}
          className={props.className}
          prefix={props.prefix}
          maxLength={props.maxLength}
          minLength={props.minLength}
          disabled={props.disabled}
          readOnly={props.readOnly}
          value={props.value ? props.value : null}
        />
      </Form.Item>

      {(props.companyMsg || props.locationMsg) && (
        <label style={{ display: "flex", float: "right" }}>
          <>
            {(props.companyMsg || props.locationMsg) === "available" ? (
              ""
            ) : (
              <b style={{ color: "red" }}>Company name already exsits</b>
            )}
          </>
        </label>
      )}
    </>
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string, // Adjust the type if needed
  label: PropTypes.string,
  rules: PropTypes.string,
  nonRequiredRules: PropTypes.string,
  pattern: PropTypes.string,
  max: PropTypes.number,
  placeholder: PropTypes.string,
  handleInputChange: PropTypes.func,
  className: PropTypes.string,
  prefix: PropTypes.node,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.any,
  companyMsg: PropTypes.string,
  locationMsg: PropTypes.string,
};

export default InputField;
